import React from "react";
import Breadcrumb from "./Breadcrumb";
import CreatePropertyModule from "./CreatePropertyModule";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class CreateProperty extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { SaveStatus, userData, linkedPropertiesLoaded } = this.props;
    const SectionTitle = "Create a New Property Guide";

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <Breadcrumb
              {...this.props}
              PreviousPageTitle={"Dashboard"}
              PreviousPagePath={"/"}
              CurrentSectionTitle={SectionTitle}
            />
            <h1>{SectionTitle}</h1>

            <CreatePropertyModule
              {...this.props}
              SaveStatus={SaveStatus}
              SetupFlow={false}
              ItemType={"property"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-full"}
              ItemLabel={"New Property"}
              ItemHeading={"Property Title"}
              InputLabelA={"Property guide name"}
              InputLabelB={"Unique weblink"}
              ValueA={""}
              FalseText={"Not entered"}
              PayloadA={"PropertyTitle"}
              PayloadB={"PropertyID"}
              userId={userData._id}
              linkedPropertiesLoaded={linkedPropertiesLoaded}
            />
          </div>
        </div>
      </>
    );
  }
}
