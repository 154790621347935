import React, { useEffect } from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import {
  workflowApiRoot //, monthlySubscriptionPriceId
} from "../constants.js";
// import { createPaymentAndSubscription } from "../redux/actions/userActions";
import { setSaveStatus, autoLogin } from "../redux/actions/userActions";
import CardSection from "./CardSection";

//ReactGA.initialize(googleAnalyticsId);

function SetupPayment(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const stripe = useStripe();
  const elements = useElements();
  const { userData, ItemLabel, SaveStatus, InputLabelA } = props;

  // const cardBrandCapitalized = "hello"; //ValueA.charAt(0).toUpperCase() + ValueA.slice(1);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    //   props.setSaveStatus(`${ItemLabel}-fetching`);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    if (error) {
      //    console.log("[error]", error);
      props.setSaveStatus(`${ItemLabel}-error`);
    } else {
      //   console.log("[PaymentMethod]", paymentMethod);

      const vanityPropertyId = window.location.pathname.split("/")[2];

      const userInfo = {
        customerId: userData.StripeCustomerID,
        subscriptionTitle: "Single Property Monthly Plan",
        subscriptionValue: 6.99,
        subscriptionTrialActive: true,
        //   subscriptionTrialEnd: "",
        planId: "", // "plan_HHBODNy0apcTZL",
        paymentMethodId: paymentMethod.id,
        paymentMethodBrand: paymentMethod.card.brand,
        paymentMethodlastFour: paymentMethod.card.last4,
        paymentMethodExpMonth: paymentMethod.card.exp_month,
        paymentMethodExpYear: paymentMethod.card.exp_year
      };
      //   props.createPaymentAndSubscription(userInfo, ItemLabel);

      props.setSaveStatus(`${ItemLabel}-fetching`);
      fetch(`${workflowApiRoot}create-payment-and-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`
        },
        body: JSON.stringify(userInfo)
      })
        .then((response) => response.json())
        .then((results) => {
          //    console.log(results.response);
          if (
            !results.response.stripePaymentSuccess ||
            !results.response.stripeSubscriptionSuccess
          ) {
            props.setSaveStatus(`${ItemLabel}-error`);
            cardElement.clear();
          }
          if (
            results.response.stripePaymentSuccess &&
            results.response.stripeSubscriptionSuccess
          ) {
            props.setSaveStatus(`${ItemLabel}-success`);
            props.autoLogin(ItemLabel);
            props.history.push(`/property/${vanityPropertyId}/setup-complete`);
            cardElement.clear();
          }
        });
    }
  };

  return (
    <>
      <div className="canvas-with-header-and-footer">
        <div className="canvas-with-header-and-footer-inner-container">
          <h1>Enter payment details</h1>
          <div className="infopage-subheading">
            Your card will <strong> not </strong>be charged now. Your FREE trial
            will end on 1st March 2021. You can cancel at any time with one
            click through your online account. If you choose to continue with
            the service, then your card wil be charged £6.99 on 1st March 2021
            and on the 1st of each month thereafter.
          </div>

          <div className="infoitem-container">
            <div className="infoitem-form-container">
              <form className="infoitem-form" onSubmit={handleSubmit}>
                <div className="infoitem-card-section-container">
                  <label className="infoitem-form-label"> {InputLabelA} </label>
                  <div className="infoitem-card-section-container-inner">
                    <CardSection />
                  </div>
                </div>
                <div className="infoitem-form-inner-container-left">
                  <input
                    type="submit"
                    disabled={!stripe}
                    className="create-new-button"
                    value={
                      SaveStatus === `${ItemLabel}-fetching`
                        ? "Saving..."
                        : "Complete setup"
                    }
                  />
                </div>

                {SaveStatus === `${ItemLabel}-error` && (
                  <div className="infoitem-form-error-message">
                    Card details were not saved. Please try again.
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  // createPaymentAndSubscription: (userInfo, ItemLabel) =>
  //   dispatch(createPaymentAndSubscription(userInfo, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
  autoLogin: (ItemLabel) => dispatch(autoLogin(ItemLabel))
});

export default connect(null, mapDispatchToProps)(SetupPayment);
