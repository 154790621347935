import React from "react";
import { connect } from "react-redux";
import { setSaveStatus } from "../redux/actions/userActions";
import { workflowApiRoot, apiSecretToken } from "../constants.js";
import "../styles/LoginForm.css";
//import logo from "../images/airguide-logo-header.png";
import GuideyLogo from "../icons/GuideyLogo";
import Loading from "./Loading";

class ResetPassword extends React.Component {
  state = {
    reset_email: "",
    otp_token: "",
    password: "",
    loading: true,
  };

  componentDidMount() {
    const ItemLabel = "reset-password";
    this.props.setSaveStatus(`${ItemLabel}-idle`);
    const url_string = window.location.href;
    var url = new URL(url_string);
    var otp_token = url.searchParams.get("otp_token");
    var reset_email = url.searchParams.get("reset_email");
    if (otp_token && reset_email) {
      this.setState(() => ({
        otp_token: otp_token,
        reset_email: reset_email,
        loading: false,
      }));
    }
  }

  onClickToLogin = () => {
    this.props.history.push("/");
  };

  onClickToForgotPassword = () => {
    this.props.history.push("/forgot-password");
  };

  handleOnChange = (e) => {
    e.persist();
    this.setState(() => ({
      [e.target.name]: e.target.value,
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const ItemLabel = "reset-password";
    this.props.setSaveStatus(`${ItemLabel}-fetching`);
    fetch(`${workflowApiRoot}reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiSecretToken}`,
      },
      body: JSON.stringify(this.state),
    })
      .then((response) => response.json())
      .then((results) => {
        //  console.log("results.response", results);

        if (results.status !== "success") {
          this.props.setSaveStatus(`${ItemLabel}-error`);
        }
        if (results.status === "success") {
          this.props.setSaveStatus(`${ItemLabel}-success`);
        }
      });
  };

  render() {
    const { FetchStatus, SaveStatus } = this.props;
    const ItemLabel = "reset-password";

    //   console.log(this.state);

    if (FetchStatus === "loading-data") {
      return <Loading />;
    }

    return (
      <>
        <div className="homepage-canvas">
          <div className="submission-form-wrapper">
            <div>
              <GuideyLogo width={"180"} height={"auto"} />
            </div>

            {SaveStatus !== `${ItemLabel}-success` && (
              <>
                <div className="homepage-heading">Reset your password</div>
                <div className="infoitem-form-label">
                  Enter and submit your new password
                </div>
              </>
            )}

            <div className="submission-form">
              {SaveStatus !== `${ItemLabel}-success` && (
                <form onSubmit={this.onSubmit}>
                  <input
                    type="password"
                    name="password"
                    className="login-text-input"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handleOnChange}
                  />
                  <input
                    type="submit"
                    className="login-button"
                    value={
                      SaveStatus === `${ItemLabel}-fetching`
                        ? "Submitting..."
                        : "Submit"
                    }
                  />
                </form>
              )}

              {SaveStatus === `${ItemLabel}-error` && (
                <div className="infoitem-form-error-message">
                  Sorry, this link has expired, please request a new one{" "}
                  <button
                    className="inline-error-link"
                    onClick={this.onClickToForgotPassword}
                  >
                    here
                  </button>
                </div>
              )}

              {SaveStatus === `${ItemLabel}-success` && (
                <>
                  <div className="homepage-heading">Success</div>
                  <div className="infoitem-form-label">
                    Your password has been reset. You can now use this to log
                    in.
                  </div>
                  <button
                    className="inline-edit-link"
                    onClick={this.onClickToLogin}
                  >
                    Go to log in
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
  };
};

export default connect(null, mapDispatchToProps)(ResetPassword);
