import React from "react";

function BusinessIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={612}
      height={612}
      viewBox="0 0 612 612"
      {...props}
    >
      <path d="M535.5 95.625H420.75v-38.25c0-21.133-17.117-38.25-38.25-38.25h-153c-21.133 0-38.25 17.136-38.25 38.25v38.25H76.5c-42.247 0-76.5 34.253-76.5 76.5v344.25c0 42.247 34.253 76.5 76.5 76.5h459c42.247 0 76.5-34.253 76.5-76.5v-344.25c0-42.247-34.253-76.5-76.5-76.5zM229.5 76.5c0-10.557 8.568-19.125 19.125-19.125h114.75c10.557 0 19.125 8.568 19.125 19.125v19.125h-153V76.5zm344.25 439.875c0 21.114-17.117 38.25-38.25 38.25h-459c-21.133 0-38.25-17.136-38.25-38.25V306h193.947c-1.587 6.158-2.697 12.488-2.697 19.125 0 42.247 34.253 76.5 76.5 76.5s76.5-34.253 76.5-76.5c0-6.637-1.109-12.986-2.696-19.125H573.75v210.375zm-306-191.25c0-7 2.027-13.483 5.298-19.125h65.886c3.289 5.642 5.316 12.125 5.316 19.125 0 21.133-17.136 38.25-38.25 38.25-21.133 0-38.25-17.117-38.25-38.25zm306-57.375H38.25v-95.625c0-21.133 17.117-38.25 38.25-38.25h459c21.133 0 38.25 17.117 38.25 38.25v95.625z" />
    </svg>
  );
}

export default BusinessIcon;
