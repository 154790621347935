import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import "./globalstyles.css";
import App from "./app.js";
//import AppClass from "./appFUNC.js";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/index";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripePublicKey, googleAnalytics4Id } from "./constants.js";
//import smoothscroll from "smoothscroll-polyfill";

const store = createStore(rootReducer, applyMiddleware(thunk));
const stripePromise = loadStripe(stripePublicKey);
ReactGA.initialize(googleAnalytics4Id);
//smoothscroll.polyfill();

ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Provider>,
  document.getElementById("root")
);
