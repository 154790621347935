import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import Breadcrumb from "./Breadcrumb";
import InfoItemEmail from "./InfoItemEmail";
import InfoItemPassword from "./InfoItemPassword";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

//ReactGA.initialize(googleAnalyticsId);

export default class DashboardLoginInfo extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
   // ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { SaveStatus, userData } = this.props;

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <Breadcrumb
              {...this.props}
              PreviousPageTitle={"Dashboard"}
              PreviousPagePath={"/"}
              CurrentSectionTitle={"Login and Security"}
            />
            <h1> Login and Security </h1>
            <div className="infopage-subheading">Update your login details</div>

            <div className="infoitem-container">
              <div className="section-break-wrapper">
                <div className="section-break"></div>
              </div>
            </div>

            <InfoItemEmail
              SaveStatus={SaveStatus}
              userId={userData._id}
              ItemLabel={"Email address"}
              ItemHeading={"Email address"}
              ItemDescription={"This is the email address you use to login"}
              InputLabelA={"Email address"}
              ValueA={userData.authentication.email.email}
              PayloadA={"new-email"}
              IsChecked={userData.authentication.email.email ? true : false}
              FalseText={"Not entered"}
            />

            <InfoItemPassword
              SaveStatus={SaveStatus}
              userId={userData._id}
              ItemLabel={"Password"}
              ItemHeading={"Password"}
              ItemDescription={"This is the password you use to login"}
              InputLabelA={"Current password"}
              ValueA={""}
              PayloadA={"current-password"}
              InputLabelB={"New password"}
              ValueB={""}
              PayloadB={"new-password"}
              IsChecked={true}
            />
          </div>
        </div>
      </>
    );
  }
}
