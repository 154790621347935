import airportPin from "../pins/airport.png";
import atmPin from "../pins/atm.png";
import barPin from "../pins/bar.png";
import beachPin from "../pins/beach.png";
import busStopPin from "../pins/bus.png";
import cafePin from "../pins/cafe.png";
import carRentalPin from "../pins/car_rental.png";
import defaultPin from "../pins/default.png";
import educationPin from "../pins/education.png";
import evChargepointPin from "../pins/ev_chargepoint.png";
import golfCoursePin from "../pins/golf_course.png";
import healthPin from "../pins/health.png";
import homePin from "../pins/home.png";
import hotelPin from "../pins/hotel.png";
import leisurePin from "../pins/leisure.png";
import manualTradePin from "../pins/trade_manual.png";
import parkPin from "../pins/park.png";
import parkingPin from "../pins/parking.png";
import petrolStationPin from "../pins/petrol_station.png";
import portPin from "../pins/port.png";
import postboxPin from "../pins/postbox.png";
import professionalTradePin from "../pins/trade_professional.png";
import restaurantPin from "../pins/restaurant.png";
import shopPin from "../pins/shop.png";
import swimmingPoolPin from "../pins/swimming_pool.png";
import taxiPin from "../pins/taxi.png";
import touristAttractionPin from "../pins/tourist_attraction.png";
import trainStationPin from "../pins/train_station.png";

const MapPins = {
  home: homePin,
  default: defaultPin,
  airport: airportPin,
  atm: atmPin,
  bar: barPin,
  beach: beachPin,
  bus_stop: busStopPin,
  cafe: cafePin,
  car_rental: carRentalPin,
  education: educationPin,
  ev_chargepoint: evChargepointPin,
  golf_course: golfCoursePin,
  health: healthPin,
  hotel: hotelPin,
  leisure: leisurePin,
  manual_trade: manualTradePin,
  park: parkPin,
  parking: parkingPin,
  petrol_station: petrolStationPin,
  point_of_interest: touristAttractionPin,
  port: portPin,
  postbox: postboxPin,
  professional_trade: professionalTradePin,
  restaurant: restaurantPin,
  shop: shopPin,
  swimming_pool: swimmingPoolPin,
  taxi: taxiPin,
  tourist_attraction: touristAttractionPin,
  train_station: trainStationPin
};

export default MapPins;
