import { dataApiRoot } from "../constants.js";
import { refreshConversations } from "./refreshConversations";
import { updateMessageStatus } from "./updateMessageStatus";

export const updateConversationStatus = (
  dispatch,
  conversationMessageId,
  payload,
  userId,
  messageDataFiltered
) => {
  fetch(`${dataApiRoot}conversations/${conversationMessageId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`
    },
    body: JSON.stringify({ Status: payload })
  }).then(function (response) {
    //  console.log(response.status);
    if (response.status !== 204) {
    }
    if (response.status === 204) {
      updateMessageStatus(messageDataFiltered, payload);
      refreshConversations(dispatch, userId);
    }
  });
};
