/**
 * Use the CSS tab above to style your Element's container.
 * Default styles are taken from the StripeElement styles in "CardSection.css"
 * The below styles override these
 *
 */
import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "../styles/CardSection.css";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "#333333",
      color: "#333333",
      fontFamily: '"SF Pro Text", Sans-Serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
        fontSize: "16px"
      }
    },
    "::placeholder": {
      color: "#cccccc",
      invalid: {
        color: "#ed495a",
        iconColor: "#ed495a"
      }
    }
  }
};

function CardSection() {
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
}

export default CardSection;
