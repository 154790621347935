import React from "react";
import { createConversationMessage } from "../data/createConversationMessage";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import "../styles/ConversationResponseSubmitButton.css";

export default function ConversationResponseSubmitButton(props) {
  const {
    buttonId
    // buttonLabelStatic,
    // buttonLabelLoading
    //translateText,
    //liftState
    // onSubmit
  } = props;

  var pathName = window.location.pathname;
  var pathParams = pathName.substring(pathName.lastIndexOf("/") + 1);
  const conversationId = pathParams ? pathParams : "";

  const conversationData = useSelector(
    (state) => state.userReducer.user.conversationData,
    shallowEqual
  );

  const conversationsDataFiltered = conversationData
    ? conversationData.filter((item) => item._id === conversationId)[0]
    : null;

  const propertyId = conversationsDataFiltered
    ? conversationsDataFiltered.LinkedProperty
    : "";

  const messageSubmitting = useSelector(
    (state) => state.conversationsReducer.loadingStatus,
    shallowEqual
  );

  const dispatch = useDispatch();

  const commsChannelId = propertyId;

  const conversationFileUploads = useSelector(
    (state) => state.conversationsReducer.conversationFileUploads,
    shallowEqual
  );

  // console.log("conversationFileUploads in ConversationResponseSubmitButton", conversationFileUploads)

  const onSubmit = () => {
    const conversationResponseText = sessionStorage.getItem(
      "conversationMessageText"
    )
      ? sessionStorage.getItem("conversationMessageText")
      : "";

    const messageResponsePayload = {
      DetailText: conversationResponseText,
      conversationId: conversationId
    };
    //console.log("messageResponsePayload", messageResponsePayload);
    // console.log("conversationFileUploads", conversationFileUploads);
    messageResponsePayload.DetailFiles = conversationFileUploads;
    // console.log("messageResponsePayload", messageResponsePayload);

    createConversationMessage(
      dispatch,
      messageResponsePayload,
      "conversation-response-submit-button",
      propertyId,
      commsChannelId
    );
  };

  return (
    <button className="message-submit-mini-button-round" onClick={onSubmit}>
      <div className="message-submit-mini-button-round-content-wrapper">
        <div className="message-submit-mini-button-round-text">
          {messageSubmitting !== `${buttonId}-loading` && "Send"}
          {messageSubmitting === `${buttonId}-loading` && "..."}
        </div>
      </div>
    </button>
  );
}
