import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import Breadcrumb from "./Breadcrumb";
import PreviewButton from "./PreviewButton";
import InfoItemImage from "./InfoItemImage";
import InfoItemImageSelector from "./InfoItemImageSelector";
import InfoItemOneBox from "./InfoItemOneBox";
import InfoItemTickBox from "./InfoItemTickBox";
import InfoItemLanguageSingle from "./InfoItemLanguageSingle";
import InfoItemLanguageMulti from "./InfoItemLanguageMulti";
import InfoItemMap from "./InfoItemMap";
import InfoItemThemeColoursLoader from "./InfoItemThemeColoursLoader";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

//ReactGA.initialize(googleAnalyticsId);

export default class PropertyDetailsBasics extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  render() {
    const {
      SaveStatus,
      propertyData,
      managerData,
      companyData,
      userData,
      systemCategories,
      systemContent,
      FileLoadStatus
    } = this.props;
    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];

    // Use Detail label to lookup custom content
    const propertyDataFiltered = propertyData.filter((Item) => {
      return Item.PropertyID === vanityPropertyId;
    });
    const propertyDetails = propertyDataFiltered[0];

    const PreviousPageTitle = propertyDetails.PropertyTitle
      ? propertyDetails.PropertyTitle
      : "Unamed Place";

    const SectionTitle = "Basic Setup";

    const propertyManagerId = propertyDetails.LinkedPropertyMgr;

    const managerDataFiltered = managerData.filter((Item) => {
      return Item._id === propertyManagerId;
    });

    //  const propertyManagerCoHostId = propertyDetails.PropertyManagerCoHost;

    // const managerDataFilteredCoHost = managerData.filter((item) => {
    //    return item._id === propertyManagerCoHostId;
    //  });

    //  console.log("managerDataFilteredCoHost", managerDataFilteredCoHost)
    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="breadcrumb-header-and-preview-button-container">
              <div className="breadcrumb-and-header-container">
                <Breadcrumb
                  {...this.props}
                  PreviousPageTitle={PreviousPageTitle}
                  PreviousPagePath={`/property/${vanityPropertyId}`}
                  CurrentSectionTitle={SectionTitle}
                  propertyId={propertyDetails._id}
                />
                <h1>{SectionTitle}</h1>
              </div>

              <PreviewButton
                Style={"solid"}
                Heading={"Preview guide"}
                PropertyVanityId={propertyDetails.PropertyID}
              />
            </div>

            <InfoItemOneBox
              SaveStatus={SaveStatus}
              propertyDetails={propertyDetails}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-full"}
              ItemLabel={"property-title"}
              ItemHeading={"Property Title"}
              ItemDescription={
                "This is the property name that will be displayed to guests"
              }
              InputLabelA={"Property name"}
              ValueA={propertyDetails.PropertyTitle}
              IsChecked={propertyDetails.PropertyTitle ? true : false}
              IsRequired={false}
              FalseText={"Not entered"}
              PayloadA={"PropertyTitle"}
            />

            <InfoItemImage
              SaveStatus={SaveStatus}
              userId={userData._id}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              FileFolder={"property-content"}
              ItemId={propertyDetails._id} // Defines which id to insert into filename
              ItemLabel={"property-cover-image"}
              ItemHeading={"Cover Image"}
              ItemDescription={"For best results pick a landscape image"}
              InputLabelA={""}
              ValueA={propertyDetails.PropertyCoverImage}
              IsChecked={propertyDetails.PropertyCoverImage ? true : false}
              IsRequired={false}
              FalseText={"Not selected"}
              PayloadA={"PropertyCoverImage"} // Defines which database label to update
              FileLoadStatus={FileLoadStatus}
            />

            <InfoItemMap
              {...this.props}
              SaveStatus={SaveStatus}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              ItemLabel={"property-pin-location"}
              ItemHeading={"Property Pin Location"}
              ItemDescription={
                "The location entered here controls where your property appears on the map"
              }
              InputLabelA={"Property address"}
              ValueA={propertyDetails.GeoLocationFormattedAddress}
              IsChecked={
                propertyDetails.GeoLocationFormattedAddress ? true : false
              }
              IsRequired={false}
              FalseText={"Not selected"}
              PayloadA={"PropertyLocation"} // Defines which database label to update
              propertyDetails={propertyDetails}
            />

            {propertyDetails.GeoLocationFormattedAddress && (
              <InfoItemOneBox
                propertyDetails={propertyDetails}
                SaveStatus={SaveStatus}
                ContainerClassName={"infoitem-form-inner-container-full"}
                propertyId={propertyDetails._id}
                ItemType={"property"} // Defines which dispatch to invoke
                ItemLabel={"Property display address"}
                ItemDescription={
                  "This is the address that is displayed to your guests. Editing it won't change the map pin location of your property."
                }
                InputLabelA={"Property display address"}
                ValueA={propertyDetails.PropertyDisplayAddress}
                IsChecked={
                  propertyDetails.PropertyDisplayAddress ? true : false
                }
                IsRequired={false}
                ItemHeading={"Property Display Address"}
                FalseText={"Not selected"}
                PayloadA={"PropertyDisplayAddress"}
              />
            )}

            {propertyDetails.WhatThreeWordsLocation && (
              <InfoItemTickBox
                propertyDetails={propertyDetails}
                SaveStatus={SaveStatus}
                ContainerClassName={"infoitem-form-inner-container-full"}
                propertyId={propertyDetails._id}
                ItemType={"property"} // Defines which dispatch to invoke
                ItemLabel={"what3words-location"}
                ItemDescriptionOne={`Your property's what3words location is ///${propertyDetails.WhatThreeWordsLocation}`}
                ItemDescriptionTwo={
                  "You can choose to either show or hide this from guests."
                }
                InputLabelA={""}
                ValueA={
                  propertyDetails.ShowWhatThreeWords
                    ? `///${propertyDetails.WhatThreeWordsLocation}`
                    : "Not currently being displayed to guests"
                }
                IsChecked={propertyDetails.ShowWhatThreeWords}
                IsRequired={false}
                ItemHeading={"what3words Location Display"}
                TickBoxText={"Show what3words location to guests"}
                FalseText={`///${propertyDetails.WhatThreeWordsLocation} not currently being displayed to guests`}
                PayloadA={"ShowWhatThreeWords"}
              />
            )}

            <InfoItemLanguageSingle
              propertyDetails={propertyDetails}
              SaveStatus={SaveStatus}
              ContainerClassName={"infoitem-form-inner-container-full"}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              ItemLabel={"default-language"}
              ItemDescription={
                "This is the language that guests will see when they load your airguide™ for the first time."
              }
              InputLabelA={""}
              ValueA={
                propertyDetails.DefaultLanguage
                  ? propertyDetails.DefaultLanguage
                  : "en"
              }
              IsChecked={true}
              IsRequired={false}
              ItemHeading={"Default Language"}
              FalseText={""}
              PayloadA={"DefaultLanguage"}
            />

            <InfoItemLanguageMulti
              propertyDetails={propertyDetails}
              SaveStatus={SaveStatus}
              ContainerClassName={"infoitem-form-inner-container-full"}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              ItemLabel={"language-translations"}
              ItemDescription={
                "These are the language options that guests will see within your airguide™ - when a guest selects a language, your content will be automatically translated."
              }
              InputLabelA={""}
              ValueA={
                propertyDetails.LanguageTranslations &&
                propertyDetails.LanguageTranslations.length > 0
                  ? propertyDetails.LanguageTranslations
                  : []
              }
              IsChecked={
                propertyDetails.LanguageTranslations &&
                propertyDetails.LanguageTranslations.length > 0
                  ? true
                  : false
              }
              IsRequired={false}
              ItemHeading={"Language Translations"}
              FalseText={"No language translation options selected"}
              PayloadA={"LanguageTranslations"}
            />

            <InfoItemImageSelector
              {...this.props}
              propertyData={propertyData}
              managerData={managerData}
              companyData={companyData}
              userData={userData}
              SaveStatus={SaveStatus}
              systemCategories={systemCategories}
              systemContent={systemContent}
              ItemList={managerData}
              propertyDetails={propertyDetails}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              DataType={"managers"} // Defines the data source so correct db labels are used to find data
              ItemHeading={"Host / Property Manager"}
              ItemLabel={"property-manager"}
              ItemDescription={"Select a manager for this property"}
              InputLabelA={""}
              ValueA={
                managerDataFiltered[0]
                  ? managerDataFiltered[0].PropertyMgrFirstname
                  : ""
              }
              DefaultImage={
                managerDataFiltered[0]
                  ? managerDataFiltered[0].PropertyMgrCoverImage
                  : undefined
              }
              DefaultId={
                managerDataFiltered[0] ? managerDataFiltered[0]._id : ""
              }
              IsChecked={managerDataFiltered[0] ? true : false}
              IsRequired={false}
              TrueText={
                managerDataFiltered[0] &&
                managerDataFiltered[0].PropertyMgrFirstname &&
                managerDataFiltered[0].PropertyMgrLastname
                  ? managerDataFiltered[0].PropertyMgrFirstname +
                    " " +
                    managerDataFiltered[0].PropertyMgrLastname
                  : managerDataFiltered[0] &&
                    managerDataFiltered[0].PropertyMgrFirstname &&
                    !managerDataFiltered[0].PropertyMgrLastname
                  ? managerDataFiltered[0].PropertyMgrFirstname
                  : undefined
              }
              FalseText={"Not selected"}
              PayloadA={"LinkedPropertyMgr"} // Defines which database label to update
            />

            {
              //<InfoItemImageSelector
              //        {...this.props}
              //       SaveStatus={SaveStatus}
              // systemCategories={systemCategories}
              //  systemContent={systemContent}
              //      ItemList={managerData}
              //     propertyDetails={propertyDetails}
              //      propertyId={propertyDetails._id}
              //      ItemType={"property"} // Defines which dispatch to invoke
              //      DataType={"managers"} // Defines the data source so correct db labels are used to find data
              //      ItemHeading={"Co-Host"}
              //      ItemLabel={"property-manager-cohost"}
              //      ItemDescription={"Select a team member as a Co-Host"}
              //     InputLabelA={""}
              //      ValueA={
              //       managerDataFilteredCoHost && managerDataFilteredCoHost[0]
              //         ? managerDataFilteredCoHost[0].PropertyMgrFirstname
              //         : ""
              //     }
              //    DefaultImage={
              //      managerDataFilteredCoHost && managerDataFilteredCoHost[0]
              //        ? managerDataFilteredCoHost[0].PropertyMgrCoverImage
              //        : undefined
              //    }
              //    DefaultId={
              //       managerDataFilteredCoHost && managerDataFilteredCoHost[0]
              //         ? managerDataFilteredCoHost[0]._id
              //         : ""
              //      }
              //     IsChecked={managerDataFilteredCoHost && managerDataFilteredCoHost[0] ? true : false}
              //     IsRequired={false}
              //     TrueText={
              //       managerDataFilteredCoHost[0] &&
              //       managerDataFilteredCoHost[0].PropertyMgrFirstname &&
              //      managerDataFilteredCoHost[0].PropertyMgrLastname
              //        ? managerDataFilteredCoHost[0].PropertyMgrFirstname +
              //         " " +
              //         managerDataFilteredCoHost[0].PropertyMgrLastname
              //       : managerDataFilteredCoHost[0] &&
              //         managerDataFilteredCoHost[0].PropertyMgrFirstname &&
              //        !managerDataFilteredCoHost[0].PropertyMgrLastname
              //      ? managerDataFilteredCoHost[0].PropertyMgrFirstname
              //      : undefined
              //   }
              //  FalseText={"Not selected"}
              //   PayloadA={"LinkedPropertyMgrCoHost"} // Defines which database label to update
              // />
            }

            <InfoItemThemeColoursLoader
              SaveStatus={SaveStatus}
              propertyDetails={propertyDetails}
              companyData={companyData}
              propertyId={propertyDetails._id}
              ItemType={"theme"} // Defines which dispatch to invoke
              ItemLabel={"theme-colours-loader"}
              ItemHeading={"Theme Colours"}
              ItemDescription={
                "Select management company profile or enter manually."
              }
              InputLabelA={"Icons and buttons"}
              ValueA={
                propertyDetails.PropertyHexPrimary ||
                propertyDetails.CompanyProfile
              }
              IsChecked={
                propertyDetails.PropertyHexPrimary ||
                propertyDetails.CompanyProfile
                  ? true
                  : false
              }
              IsRequired={false}
              FalseText={"Not selected"}
              PayloadA={"PropertyHexPrimary"}
              InputLabelB={"Menu and banners"}
              ValueB={propertyDetails.PropertyHexSecondary}
              PayloadB={"PropertyHexSecondary"}
            />
          </div>
        </div>
      </>
    );
  }
}
