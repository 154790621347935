import { dataApiRoot } from "../constants.js";
import { refreshConversations } from "./refreshConversations";

export const markMessageRead = (dispatch, conversationMessageId, userId) => {
  fetch(`${dataApiRoot}conversationmessages/${conversationMessageId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`
    },
    body: JSON.stringify({ ReadByRecipient: true })
  }).then(function (response) {
    //  console.log(response.status);
    if (response.status !== 204) {
    }
    if (response.status === 204) {
      refreshConversations(dispatch, userId);
    }
  });
};
