import React from "react";
import { connect } from "react-redux";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItem.css";
//import InfoItemMultiMapAuto from "./InfoItemMultiMapAuto";
import InfoItemMultiMapManual from "./InfoItemMultiMapManual";
//import InfoItemCheckMark from "./InfoItemCheckMark";
//import { googleMapsApiKey } from "../constants.js";
//import Script from "react-load-script";
//import { googleMapsApiKey } from "../constants.js";
//import MapPins from "../pins/MapPins.js";

class InfoItemMultiMap extends React.Component {
  render() {
    const {
      SaveStatus,
      ItemLabel,
      //ItemHeading,
      // ItemDescription,
      // ValueA,
      //  IsChecked,
      //  IsRequired,
      propertyDetails,
      propertyId,
      //  IsNewCustomItem,
      //  FalseText,
      ItemData,
      onStateChange,
      GeoLocationLat,
      GeoLocationLng,
      WhatThreeWordsLocation,
      isComponentShared,
      SharedContentProfileId,
      sharedLocalAreaContentProfileData
    } = this.props;

    return (
      <>
        <div className="infoitem-form-inner-container-textarea">
          {
            // {(!GeoLocationLat || !GeoLocationLng) && (
            //  <InfoItemMultiMapAuto
            //    key={`${ItemLabel}-auto`}
            //    propertyId={propertyId}
            //ItemLabel={"new-location-pin"}
            //    ItemLabel={`${ItemLabel}-auto`}
            //    SaveStatus={SaveStatus}
            //    propertyDetails={propertyDetails}
            //   InputLabelA={"Set place location marker"}
            //   ItemData={ItemData}
            //   GeoLocationLat={GeoLocationLat}
            //   GeoLocationLng={GeoLocationLng}
            //   onStateChange={onStateChange}
            // />
            // )}
            //  }
          }

          {
            //  {GeoLocationLat && GeoLocationLng && (
          }
          <InfoItemMultiMapManual
            propertyId={propertyId}
            isComponentShared={isComponentShared}
            SharedContentProfileId={SharedContentProfileId}
            ItemLabel={`${ItemLabel}-manual`}
            SaveStatus={SaveStatus}
            propertyDetails={propertyDetails}
            InputLabelA={"Set place location marker"}
            ItemData={ItemData}
            onStateChange={onStateChange}
            GeoLocationLat={
              GeoLocationLat
                ? GeoLocationLat
                : isComponentShared
                ? sharedLocalAreaContentProfileData.GeoLocationLat
                : propertyDetails.GeoLocationLat
            }
            GeoLocationLng={
              GeoLocationLng
                ? GeoLocationLng
                : isComponentShared
                ? sharedLocalAreaContentProfileData.GeoLocationLng
                : propertyDetails.GeoLocationLng
            }
            WhatThreeWordsLocation={WhatThreeWordsLocation}
          />

          {
            //  )}
          }

          {
            //    <div className="infoitem-section-break-wrapper">
            //    <div className="infoitem-section-break"></div>
            //   </div>
          }
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemMultiMap);
