import React from "react";
import { connect } from "react-redux";
import { createLocalContentProfile } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItem.css";
import { googleMapsApiKey, workflowApiRoot } from "../constants.js";
import Script from "react-load-script";

class CreateLocalProfileModule extends React.Component {
  state = {
    payload: {},
    photoReference: "",
    error: false,
    placeLoaded: true
  };

  handleScriptLoad = () => {
    this.createMap();
  };

  createMap = () => {
    // Declare Options For Autocomplete
    const options = {
      types: ["(regions)"]
    }; // To disable any eslint 'google not defined' errors

    // Initialize Google Autocomplete
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields([
      "place_id",
      "address_components",
      "formatted_address",
      "geometry"
    ]);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;

    var Label = e.target.value;
    Label = Label.replace(/\s+/g, "-").toLowerCase();

    this.setState(() => ({
      payload: { ...payloadA, Label }
    }));
  };

  handlePlaceSelect = () => {
    this.setState({ placeLoaded: false });
    const { UserId } = this.props;

    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    function extractFromAddress(components, type) {
      for (var i = 0; i < components.length; i++)
        for (var j = 0; j < components[i].types.length; j++)
          if (components[i].types[j] === type) return components[i].long_name;
      return "";
    }

    var town = extractFromAddress(address, "locality");
    var postalTown = extractFromAddress(address, "postal_town");
    var county = extractFromAddress(address, "administrative_area_level_2");
    var state = extractFromAddress(address, "administrative_area_level_1");
    var country = extractFromAddress(address, "country");

    fetch(`${workflowApiRoot}get-place-details/initializeuser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`
      },
      body: JSON.stringify({ place_id: `${addressObject.place_id}` })
    })
      .then((response) => response.json())
      //  .then((json) => console.log(json.response.photoReference));
      .then((json) => {
        this.setState({
          photoReference: json.response.photoReference
        });

        const Payload = {
          CoverImage: null,
          LinkedUser: UserId,
          LinkedProperty: null,
          LocalContentProfile: true,
          PropertyContentProfile: false,
          GeoLocationTownCity: postalTown ? postalTown : town,
          GeoLocationCounty: county,
          GeoLocationState: state,
          GeoLocationCountry: country,
          GeoLocationFormattedAddress: addressObject.formatted_address,
          GeoLocationLat: addressObject.geometry.location.lat(),
          GeoLocationLng: addressObject.geometry.location.lng(),
          GooglePlaceId: addressObject.place_id,
          GooglePhotoReference: this.state.photoReference
        };

        // Check if address is valid
        if (address) {
          var payloadA = { ...this.state.payload };
          this.setState(() => ({
            payload: { ...payloadA, ...Payload },
            placeLoaded: true
          }));
        }
      });
  };

  onSubmit = (e) => {
    const { ItemLabel, UserId } = this.props;
    e.preventDefault();
    if (this.state.payload.Label && this.state.payload.LinkedUser) {
      this.props.createLocalContentProfile(
        this.state.payload,
        ItemLabel,
        UserId
      );
    }
    if (!this.state.payload.Label || !this.state.payload.LinkedUser) {
      this.setState({ error: true });
    }
  };

  onRedirect = () => {
    this.props.history.push({
      pathname: `/local-area-profile/${this.state.payload.Label}`
      //    state: { ManagerVanityID: this.state.payload.ManagerVanityID }
    });
  };

  render() {
    const { SaveStatus, ItemLabel, AttachProfilesLoaded } = this.props;
    // console.log(this.state.payload.Label);
    if (AttachProfilesLoaded === "create-local-profile-success") {
      this.onRedirect();
    }

    return (
      <>
        <div className="infoitem-form-inner-container-full">
          <label className="infoitem-form-label">
            {" "}
            Profile name (this is to help you identify the profile, it is not
            displayed to guests)
          </label>
          <input
            type="text"
            name={"Title"}
            className="infoitem-text-input"
            placeholder={"Enter profile name"}
            value={this.state.payload.name}
            onChange={this.onChange}
            required
          />

          <label className="infoitem-form-label">
            Nearest town or city (this will be used to help find local places)
          </label>
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
            onLoad={this.handleScriptLoad}
          />
          <input
            className="infoitem-text-input"
            id="autocomplete"
            placeholder="Enter nearest town or city and select from list"
            defaultValue=""
            required
          />
        </div>

        <div className="infoitem-form-inner-container-left">
          <button
            onClick={this.state.placeLoaded ? this.onSubmit : null}
            className="infoitem-save-button"
          >
            {SaveStatus === `${ItemLabel}-fetching`
              ? "Creating..."
              : !this.state.placeLoaded
              ? "loading..."
              : "Create profile"}
          </button>
        </div>

        {this.state.error && (
          <div className="infoitem-form-error-message">
            Please enter all input boxes and try again.
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createLocalContentProfile: (payload, ItemLabel, UserId) =>
    dispatch(createLocalContentProfile(payload, ItemLabel, UserId)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(CreateLocalProfileModule);
