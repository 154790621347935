import React from "react";
import { connect } from "react-redux";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItem.css";
import InfoItemMultiPopup from "./InfoItemMultiPopup";
import InfoItemPlacesAutoTwo from "./InfoItemPlacesAutoTwo";
import InfoItemPlacesSearch from "./InfoItemPlacesSearch";
import InfoItemCheckMark from "./InfoItemCheckMark";

class InfoItemPlacesTwo extends React.Component {
  state = {
    payload: {},
    editing: false,
    resetContent: false // was true
  };

  // resetContentChange = (value) => {
  //   this.setState({ resetContent: value });
  // };

  onEdit = (e) => {
    e.preventDefault();
    this.setState({
      //  resetContent: true,
      //   resetContentKey: true,
      editing: true
    });
    document
      .getElementById(`${this.props.CategoryLabel}-container`)
      .scrollIntoView({ behavior: "smooth" });
  };

  onCancel = (e) => {
    e.preventDefault();
    this.setState({
      //  resetContent: false,
      //  resetContentKey: false,
      editing: false
    });
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      ItemHeading,
      ItemDescription,
      IsRequired,
      FalseText,
      propertyDetails,
      propertyId,
      userId,
      customContent,
      CategoryLabel,
      SharedContentProfileId,
      isComponentShared,
      sharedLocalAreaContentProfileData,
      linkedProperties,
      systemContent,
      sharedContent
    } = this.props;

    // Filter custom content by real property id
    var allCustomContent = [];
    if (!isComponentShared && customContent && customContent[0]) {
      allCustomContent = customContent.map((element) => {
        return {
          ...element,
          LinkedProperty: element.LinkedProperty.filter(
            (subElement) => subElement === propertyId
          )
        };
      });
    }
    if (isComponentShared && customContent && customContent[0]) {
      allCustomContent = customContent.filter((item) => {
        return item.LinkedSharedContentProfile === SharedContentProfileId;
      });
    }

    var allCustomPlaceContent = null;
    if (!isComponentShared && allCustomContent && allCustomContent[0]) {
      allCustomPlaceContent = allCustomContent.filter((item) => {
        return (
          item.LinkedProperty[0] === propertyId && item.ContentType === "place"
        );
      });
    }
    if (isComponentShared && allCustomContent && allCustomContent[0]) {
      allCustomPlaceContent = allCustomContent.filter((item) => {
        return (
          item.LinkedSharedContentProfile === SharedContentProfileId &&
          item.ContentType === "place"
        );
      });
    }

    var customPlaceContent = null;
    if (allCustomPlaceContent) {
      customPlaceContent = allCustomPlaceContent.filter((Item) => {
        return Item.CategoryLabel === CategoryLabel;
      });
    }

    // const saveStatusLastSeven = SaveStatus.substr(SaveStatus.length - 8);
    //  console.log("SaveStatus", saveStatusLastSeven);
    return (
      <>
        <div
          className="infoitem-container"
          id={`${CategoryLabel}-container`}
          style={{ scrollMarginTop: "var(--headerHeight" }}
        >
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={
                  customPlaceContent === null || customPlaceContent.length === 0
                    ? false
                    : true
                }
                IsRequired={IsRequired}
                IsCustomContent={true}
                ItemHeading={ItemHeading}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={this.state.editing ? this.onCancel : this.onEdit}
            >
              {this.state.editing ? "Close" : "Edit"}
            </button>
          </div>

          <div className="infoitem-value">
            {SaveStatus === `${ItemLabel}-editing`
              ? `${ItemDescription}`
              : SaveStatus === `${ItemLabel}-fetching`
              ? "Saving..."
              : customPlaceContent !== null
              ? `${customPlaceContent.length} places added`
              : FalseText}
          </div>

          <div className={this.state.editing ? "show" : "hide"}></div>

          <div className={this.state.editing ? "show" : "hide"}>
            <div className="infoitem-form-container">
              <div className="infoitem-form">
                {customPlaceContent &&
                  customPlaceContent.length > 0 &&
                  this.state.editing && (
                    <InfoItemPlacesAutoTwo
                      propertyId={propertyId}
                      //  vanityPropertyId={vanityPropertyId}
                      userId={userId}
                      //  allCustomPlaceContent={allCustomPlaceContent}
                      customPlaceContent={customPlaceContent}
                      ItemLabel={ItemLabel}
                      SaveStatus={SaveStatus}
                      propertyDetails={propertyDetails}
                      // InputLabelA={"Search for a place"}
                      CategoryLabel={CategoryLabel}
                      // key={this.state.resetContentKey} // key allows state of child component to be reset
                      //  resetContent={this.state.resetContent} // Controls if Google Places script loads
                      //   resetContentChange={this.resetContentChange}
                      SharedContentProfileId={SharedContentProfileId}
                      isComponentShared={isComponentShared}
                      sharedLocalAreaContentProfileData={
                        sharedLocalAreaContentProfileData
                      }
                      // linkedProperties={linkedProperties}
                      systemContent={systemContent}
                      customContent={customContent}
                      sharedContent={sharedContent}
                    />
                  )}

                <InfoItemPlacesSearch
                  propertyId={propertyId}
                  // vanityPropertyId={vanityPropertyId}
                  userId={userId}
                  allCustomPlaceContent={allCustomPlaceContent}
                  customPlaceContent={customPlaceContent}
                  ItemLabel={ItemLabel}
                  //  SaveStatus={SaveStatus}
                  propertyDetails={propertyDetails}
                  InputLabelA={"Search for a place"}
                  CategoryLabel={CategoryLabel}
                  // key={this.state.resetContentKey} // key allows state of child component to be reset
                  // resetContent={this.state.resetContent} // Controls if Google Places script loads
                  // resetContentChange={this.resetContentChange}
                  SharedContentProfileId={SharedContentProfileId}
                  isComponentShared={isComponentShared}
                  sharedLocalAreaContentProfileData={
                    sharedLocalAreaContentProfileData
                  }
                  linkedProperties={linkedProperties}
                  systemContent={systemContent}
                  //  customContent={customContent}
                  //  sharedContent={sharedContent}
                />

                <div className="infoitem-form-inner-container-full">
                  <div style={{ height: "15px" }}></div>
                  <div className="infoitem-form-label">
                    Not in our database?
                  </div>
                  <InfoItemMultiPopup
                    ButtonText={"Add custom place"}
                    ItemType={"add"}
                    ItemData={null}
                    SharedContentProfileId={SharedContentProfileId}
                    PropertyId={propertyId}
                    SaveStatus={SaveStatus}
                    userId={userId}
                    propertyData={propertyDetails}
                    systemContent={systemContent}
                    customContent={customContent}
                    sharedContent={sharedContent}
                    isComponentShared={isComponentShared}
                    CategoryLabel={CategoryLabel}
                    sharedLocalAreaContentProfileData={
                      sharedLocalAreaContentProfileData
                    }
                    customPlaceContent={customPlaceContent}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemPlacesTwo);
