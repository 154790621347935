import { storageRootUrl, storageApiKey } from "../constants.js";
import { addFilesToMessage } from "../data/addFilesToMessage";

export const uploadMessageFiles = (
  linkedUserId,
  messageId,
  detailFiles,
  dispatch,
  buttonId
) => {
  // console.log("uploadMessageFiles", linkedUserId, messageId, detailFiles);
  // console.log("detailFiles in uploadMessageFiles", detailFiles)
  Promise.all(
    detailFiles.map((file) =>
      fetch(
        `${storageRootUrl}/airguide/user/${linkedUserId}/message-content/${messageId}/${file.fileName}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream",
            AccessKey: `${storageApiKey}`,
            Accept: "application/octet-stream"
          },
          body: file.fileData
        }
      )
    )
  ).then((data) => {
    // console.log("uploadMessageFiles response data", data);
    if (data) {
      addFilesToMessage(
        linkedUserId,
        messageId,
        detailFiles,
        dispatch,
        buttonId
      );
    }
    if (!data) {
      console.log("data > uploadMessageFiles.js error");
    }
  });
};
