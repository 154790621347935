import React from "react";
import "../styles/CTAButtons.css";

export default function CTAButtonIconLeft(props) {
  const { onClick, Icon, Heading, Style } = props;
  // const [loading, setLoading] = useState(false);

  return (
    <>
      <div className={`cta-button-icon-left-${Style}`} onClick={onClick}>
        <div className="cta-button-icon-left-icon-and-label-wrapper">
          <div className={`cta-button-icon-left-wrapper-inner-${Style}`}>
            <Icon className={`cta-button-icon-left-icon-${Style}`} />
          </div>

          <div>{Heading}</div>
        </div>
      </div>
    </>
  );
}
