export async function pushConversationMessage(data) {
  const res = await fetch("/api/channels-event", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
  //console.log("res", res);
  if (!res.ok) {
    console.error("failed to push data");
  }
}
