import React from "react";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import { updateUser } from "../redux/actions/userActions";
import { updateCompany } from "../redux/actions/userActions";
import { updateManager } from "../redux/actions/userActions";
import { updateProperty } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/LoadingButton.css";

class LoadingButton extends React.Component {
  onSubmit = (e) => {
    const {
      ItemLabel,
      ItemType,
      Payload,
      userId,
      companyId,
      managerId,
      propertyId
    } = this.props;

    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-fetching`);
    if (ItemType === "company") {
      this.props.updateCompany(Payload, companyId, ItemLabel, userId);
    }
    if (ItemType === "manager") {
      this.props.updateManager(
        this.state.payload,
        managerId,
        ItemLabel,
        userId
      );
    }
    if (ItemType === "property") {
      this.props.updateProperty(Payload, propertyId, ItemLabel);
    }
    if (ItemType === "user") {
      this.props.updateUser(this.state.payload, userId, ItemLabel);
    }
    if (ItemType === "default-manager") {
      this.props.updateManager(
        this.state.payload,
        managerId,
        ItemLabel,
        userId
      );
      this.props.updateUser(
        { UserCoverImage: this.state.payload.PropertyMgrCoverImage },
        userId,
        ItemLabel
      );
    }
  };

  render() {
    const { Loading, StaticText, LoadingText } = this.props;
    return (
      <button className="button-with-loading" onClick={this.onSubmit}>
        <div
          className={
            Loading
              ? "button-with-loading-text-loading"
              : "button-with-loading-text"
          }
        >
          {Loading ? LoadingText : StaticText}
        </div>
        {Loading && (
          <ReactLoading
            type={"spin"}
            color={"white"}
            height={"20%"}
            width={"20%"}
          />
        )}
      </button>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userInfo, userId, ItemLabel) =>
    dispatch(updateUser(userInfo, userId, ItemLabel)),
  updateManager: (managerInfo, managerId, ItemLabel) =>
    dispatch(updateManager(managerInfo, managerId, ItemLabel)),
  updateCompany: (companyInfo, companyId, ItemLabel) =>
    dispatch(updateCompany(companyInfo, companyId, ItemLabel)),
  updateProperty: (propertyInfo, propertyId, ItemLabel) =>
    dispatch(updateProperty(propertyInfo, propertyId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(LoadingButton);
