import React, { useState } from "react";
import "../styles/ToggleSliderButton.css";
import Toggle from "react-toggle";

export default function ToggleSliderButton(props) {
  const { activeStatus } = props;
  // console.log("activeStatus", activeStatus);
  const [active, setActive] = useState(activeStatus);

  const onToggleFunction = (e) => {
    setActive(e.target.checked);
    props.callbackFunction(e.target.checked);
  };

  return (
    <>
      <div className="toggle-slider-container">
        <span>
          <Toggle
            checked={active}
            icons={false}
            onChange={onToggleFunction}
          />
        </span>
        <span className="toggle-slider-label">
          {active ? "Active" : "Inactive"}
        </span>
      </div>
    </>
  );
}
