import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Loading from "./Loading";
import ConversationListItem from "./ConversationListItem";
//import SettingsTickBox from "./SettingsTickBox";
import ToggleSliderButton from "./ToggleSliderButton";
import Breadcrumb from "./Breadcrumb";
import { refreshConversations } from "../data/refreshConversations";
import { updateUser } from "../redux/actions/userActions";
import "../styles/DashboardMessaging.css";

export default function DashboardMessaging(props) {
  // Declare State & Constants
  const dispatch = useDispatch();
  var pathName = window.location.pathname;
  var pathParams = pathName.substring(pathName.lastIndexOf("/") + 1);

  const userData = useSelector(
    (state) => state.userReducer.user.userData,
    shallowEqual
  );
  const userId = userData._id;

  const conversationData = useSelector(
    (state) => state.userReducer.user.conversationData,
    shallowEqual
  );
  //console.log("conversationData in Dashboard Messaging", conversationData);
  const ConversationDataLoaded = useSelector(
    (state) => state.userReducer.conversationDataLoaded,
    shallowEqual
  );

  // Initial Mount
  useEffect(() => {
    document.body.style.setProperty("--tidio-display", "none");
    if (!conversationData) {
      refreshConversations(dispatch, userId);
    }
    return () => document.body.style.setProperty("--tidio-display", "block");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initial Mount
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // pathParams Update
  useEffect(() => {
    if (pathParams === "messaging-settings") {
      setDisplaySettings(true);
    }
    if (pathParams === "messaging") {
      setDisplaySettings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathParams]);

  // Local Functions
  const updateMessagingActiveStatus = (state) => {
    const payload = { MessagingEnabled: state };
    const ItemLabel = "user-messaging-active-status";
    dispatch(updateUser(payload, userId, ItemLabel));
  };

  const [displaySettings, setDisplaySettings] = useState(false);

  return (
    <>
      <div className="canvas-with-header-and-footer">
        <div className="canvas-with-header-and-footer-inner-container">
          <Breadcrumb
            {...props}
            PreviousPageTitle={displaySettings ? "Messaging" : "Dashboard"}
            PreviousPagePath={displaySettings ? "/messaging" : "/"}
            CurrentSectionTitle={"Messaging"}
          />
          <h1>{displaySettings ? "Messaging Settings" : "Messaging"}</h1>

          <ToggleSliderButton
            activeStatus={userData.MessagingEnabled}
            backgroundColour={"var(--brandColour1)"}
            callbackFunction={updateMessagingActiveStatus}
          />

          <div style={{ height: "20px" }}></div>

          {ConversationDataLoaded && conversationData && (
            <>
              {conversationData.map((item) => (
                <div className="conversation-list-container">
                  <div key={item._id}>
                    <ConversationListItem
                      key={item._id}
                      Image={"na"}
                      Heading={item.Title}
                      PropertyId={item._id}
                      Status={item.status}
                      Title={item.Title}
                      Target={`messaging/${item._id}`}
                      ConversationId={item._id}
                      ConversationData={item}
                      From={"messaging"}
                      Date={item["Created Date"]}
                      {...props}
                    />
                  </div>
                </div>
              ))}
            </>
          )}

          {conversationData && conversationData.length === 0 && (
            <>No messages received yet</>
          )}

          {(!conversationData || !ConversationDataLoaded) && (
            <>
              <Loading
                LoadingText={
                  //  DataLoadPercent
                  //  ? `Loading messages... ${DataLoadPercent}`
                  // :
                  "Loading messages..."
                }
                LoadingCanvas={"table-loading-canvas"}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
