import React from "react";
import { connect } from "react-redux";
import { updateUserPassword } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import InfoItemCheckMark from "./InfoItemCheckMark";
import "../styles/InfoItem.css";
// import CtaLoading from "./CtaLoading";

class InfoItemPassword extends React.Component {
  state = {
    payload: {}
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    var payloadB = { ...this.state.payload };
    payloadB[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA, ...payloadB }
    }));
  };

  onEdit = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-editing`);
  };

  onCancel = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-idle`);
  };

  onSubmit = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.updateUserPassword(this.state.payload, ItemLabel);
  };

  render() {
    const {
      SaveStatus,
      IsChecked,
      IsRequired,
      ItemLabel,
      ItemHeading,
      ItemDescription,
      InputLabelA,
      PayloadA,
      InputLabelB,
      PayloadB
    } = this.props;

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={IsChecked}
                IsRequired={IsRequired}
                IsCustomContent={true}
                ItemHeading={ItemHeading}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing` ||
                SaveStatus === `${ItemLabel}-error`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching` ||
              SaveStatus === `${ItemLabel}-error`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div className="infoitem-value">
            {SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching` ||
            SaveStatus === `${ItemLabel}-error`
              ? `${ItemDescription}`
              : "********"}
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching` ||
              SaveStatus === `${ItemLabel}-error`
                ? "show"
                : "hide"
            }
          >
            <div className="infoitem-form-container">
              <form className="infoitem-form" onSubmit={this.onSubmit}>
                <div className="infoitem-form-inner-container-left">
                  <label className="infoitem-form-label"> {InputLabelA} </label>
                  <input
                    type="password"
                    name={PayloadA}
                    className="infoitem-text-input"
                    placeholder={`Enter ${InputLabelA}`}
                    value={this.state.payload.name}
                    onChange={this.onChange}
                    required
                  />
                </div>

                <div className="infoitem-form-inner-container-left">
                  <label className="infoitem-form-label"> {InputLabelB} </label>
                  <input
                    type="password"
                    name={PayloadB}
                    className="infoitem-text-input"
                    placeholder={`Enter ${InputLabelB}`}
                    value={this.state.payload.name}
                    onChange={this.onChange}
                    required
                  />
                </div>

                <div className="infoitem-form-inner-container-left">
                  <input
                    type="submit"
                    className="infoitem-save-button"
                    value={
                      SaveStatus === `${ItemLabel}-fetching`
                        ? "Saving..."
                        : "Save"
                    }
                  />
                </div>

                {SaveStatus === `${ItemLabel}-error` && (
                  <div className="infoitem-form-error-message">
                    Current password was incorrect. Please try again.
                  </div>
                )}
              </form>
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUserPassword: (userInfo, ItemLabel) =>
    dispatch(updateUserPassword(userInfo, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemPassword);
