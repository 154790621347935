const defaultState = {
  submittingStatus: "idle",
  loadingStatus: "",
  conversationFileUploads: [],
  conversationFileUploadsLoading: false
};

const conversationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SET_CONVERSATION_FILE_UPLOADS":
      return {
        ...state,
        conversationFileUploads: [
          ...state.conversationFileUploads,
          ...action.payload
        ],
        conversationFileUploadsLoading: false
      };
    case "CLEAR_CONVERSATION_FILE_UPLOADS":
      return {
        ...state,
        conversationFileUploads: [],
        conversationFileUploadsLoading: false
      };
    case "SET_CONVERSATION_FILE_UPLOADS_LOADING":
      return {
        ...state,
        conversationFileUploadsLoading: action.payload
      };
      case "SET_LOADING_STATUS":
      return {
        ...state,
        loadingStatus: action.payload
      };
    default:
      return state;
  }
};

export default conversationsReducer;

