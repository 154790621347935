import React from "react";

function VideoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={612}
      height={612}
      viewBox="0 0 612 612"
      {...props}
    >
      <path d="M573.75 267.75L459 344.25c0-23.218-10.557-43.758-26.89-57.796 38.881-23.428 65.14-65.637 65.14-114.329 0-73.938-59.938-133.875-133.875-133.875S229.5 98.188 229.5 172.125c0 37.504 15.51 71.317 40.373 95.625h-70.151c18.322-20.33 29.778-46.971 29.778-76.5 0-63.38-51.37-114.75-114.75-114.75S0 127.87 0 191.25c0 34.578 15.625 65.216 39.818 86.254C16.199 290.528 0 315.371 0 344.25v153c0 42.247 34.253 76.5 76.5 76.5h306c42.247 0 76.5-34.253 76.5-76.5v-19.125l114.75 95.625c21.133 0 38.25-17.117 38.25-38.25V306c0-21.133-17.117-38.25-38.25-38.25zm-535.5-76.5c0-42.247 34.253-76.5 76.5-76.5s76.5 34.253 76.5 76.5-34.253 76.5-76.5 76.5-76.5-34.253-76.5-76.5zm382.5 306c0 21.114-17.117 38.25-38.25 38.25h-306c-21.133 0-38.25-17.117-38.25-38.25v-153c0-21.133 17.117-38.25 38.25-38.25h306c21.133 0 38.25 17.117 38.25 38.25v153zm-57.375-229.29c-52.938 0-95.835-42.917-95.835-95.835 0-52.938 42.917-95.835 95.835-95.835s95.835 42.897 95.835 95.835-42.898 95.835-95.835 95.835zM573.75 535.5L459 439.875V382.5L573.75 306v229.5z" />
    </svg>
  );
}

export default VideoIcon;
