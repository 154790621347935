import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import { connect } from "react-redux";
import { createProperty, setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItem.css";

//ReactGA.initialize(googleAnalyticsId);

class CreatePropertyModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: { PropertyID: this.generateRandomNumber(6) },
      showCreateId: false,
      redirect: false
    };
  }

  componentDidMount = () => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  generateRandomNumber = (length) => {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  generateWeblink = (payload) => {
    var result = payload
      .replace(/[^a-zA-Z0-9]/g, "-")
      .replace(/----/g, "-")
      .replace(/---/g, "-")
      .replace(/--/g, "-")
      .toLowerCase();
    return result;
  };

  //  onChange = (e) => {
  //    e.persist();
  //   var payloadA = { ...this.state.payload };
  //   payloadA[e.target.name] = e.target.value;
  //   this.setState(() => ({
  //     payload: { ...payloadA }
  //  }));
  // };

  onChange = (e) => {
    e.persist();
    const { ItemLabel } = this.props;
    this.props.setSaveStatus(`${ItemLabel}-idle`);
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    var payloadB = { ...this.state.payload };
    payloadB[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA, ...payloadB }
    }));
  };

  onContinue = (e) => {
    e.preventDefault();
    var payloadB = { ...this.state.payload };
    payloadB["PropertyID"] = this.generateWeblink(
      this.state.payload.PropertyTitle.trim()
    );
    payloadB["PropertyTitle"] = this.state.payload.PropertyTitle.trim();
    this.setState(() => ({
      payload: { ...payloadB },
      showCreateId: true
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { ItemLabel, userId } = this.props;
    this.props.createProperty(this.state.payload, ItemLabel, userId);

    //// this.setState(() => ({
    //   redirect: true
    //  }));
  };

  onRedirect = () => {
    const { SetupFlow } = this.props;
    const PropertyID = this.state.payload.PropertyID;
    if (SetupFlow) {
      this.props.history.push(`/property/${PropertyID}/setup-basics`);
    } else {
      //  this.props.history.push(`/property/${PropertyID}`);

      this.props.history.push({
        pathname: "/property/" + PropertyID,
        state: { newProperty: true }
      });
    }
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      ItemDescription,
      InputLabelA,
      PayloadA,
      InputLabelB,
      PayloadB,
      ContainerClassName,
      linkedPropertiesLoaded
    } = this.props;

    // console.log(this.state.payload);
    if (
      //SaveStatus === `${ItemLabel}-success`
      linkedPropertiesLoaded &&
      SaveStatus === `${ItemLabel}-success`
    ) {
      this.onRedirect();
    }

    return (
      <>
        <div className="infoitem-container">
          <div className="infopage-subheading">
            {!this.state.showCreateId && (
              <>
                Choose a name for your property guide e.g. "Seaside Escape" (You
                can change this later)
              </>
            )}

            {this.state.showCreateId && (
              <>
                <div>
                  Choose your unique weblink e.g. "seaside-escape" - this is the
                  link guests will use to access your property guide.
                </div>
                <br />
                <div>
                  Only lowercase letters, digits and the "-" dash symbol are
                  allowed.
                </div>
              </>
            )}
          </div>

          <div className="infoitem-value">{ItemDescription}</div>

          <div className="infoitem-form-container">
            {!this.state.showCreateId && (
              <form className="infoitem-form" onSubmit={this.onContinue}>
                <div className={`${ContainerClassName}`}>
                  <label className="infoitem-form-label"> {InputLabelA} </label>
                  <input
                    type="text"
                    name={PayloadA}
                    className="infoitem-text-input"
                    placeholder={`Enter ${InputLabelA}`}
                    value={this.state.payload[PayloadA]}
                    onChange={this.onChange}
                    required
                  />
                </div>
                <div className="infoitem-form-inner-container-left">
                  <input
                    type="submit"
                    className="infoitem-save-button"
                    value={
                      SaveStatus === `${ItemLabel}-fetching`
                        ? "Saving..."
                        : "Continue"
                    }
                  />
                </div>
              </form>
            )}

            {this.state.showCreateId && (
              <form className="infoitem-form" onSubmit={this.onSubmit}>
                <div className={`${ContainerClassName}`}>
                  <label className="infoitem-form-label"> {InputLabelB} </label>
                  <input
                    type="text"
                    name={PayloadB}
                    className="infoitem-text-input"
                    placeholder={`Enter ${InputLabelB}`}
                    value={this.generateWeblink(this.state.payload[PayloadB])}
                    onChange={this.onChange}
                    required
                  />
                </div>
                <div className="infoitem-form-inner-container-left">
                  <input
                    type="submit"
                    className="infoitem-save-button"
                    value={
                      SaveStatus === `${ItemLabel}-checking-id`
                        ? "Checking..."
                        : SaveStatus === `${ItemLabel}-fetching` ||
                          SaveStatus === `${ItemLabel}-success`
                        ? "Creating..."
                        : "Create property"
                    }
                  />
                </div>

                {SaveStatus === `${ItemLabel}-error` && (
                  <div className="infoitem-form-error-message-left-align">
                    Unfortunately this weblink is unavailable, please choose
                    another.
                  </div>
                )}
              </form>
            )}
          </div>
        </div>

        <div className="infoitem-section-break-wrapper">
          <div className="infoitem-section-break"></div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createProperty: (propertyInfo, ItemLabel, userId) =>
    dispatch(createProperty(propertyInfo, ItemLabel, userId)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(CreatePropertyModule);
