import React from "react";

function CameraIcon(props) {
  return (
    <svg width={612} height={612} viewBox="0 0 612 612" {...props}>
      <path d="M535.5 114.75h-57.375L459 76.5c-11.265-22.262-17.117-38.25-38.25-38.25h-229.5c-21.133 0-28.114 18.245-38.25 38.25l-19.125 38.25H76.5c-42.247 0-76.5 34.253-76.5 76.5v306c0 42.247 34.253 76.5 76.5 76.5h459c42.247 0 76.5-34.253 76.5-76.5v-306c0-42.247-34.253-76.5-76.5-76.5zm38.25 382.5c0 21.133-17.117 38.25-38.25 38.25h-459c-21.133 0-38.25-17.117-38.25-38.25v-306c0-21.114 17.117-38.25 38.25-38.25H153l19.125-38.25c13.521-22.242 17.117-38.25 38.25-38.25h191.25c21.133 0 24.729 15.988 38.25 38.25L459 153h76.5c21.133 0 38.25 17.117 38.25 38.25v306zM306 191.25c-84.494 0-153 68.506-153 153s68.506 153 153 153 153-68.506 153-153-68.506-153-153-153zM306 459c-63.38 0-114.75-51.37-114.75-114.75S242.62 229.5 306 229.5s114.75 51.37 114.75 114.75S369.38 459 306 459z" />
    </svg>
  );
}

export default CameraIcon;
