import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import CreatePropertyModule from "./CreatePropertyModule";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

//ReactGA.initialize(googleAnalyticsId);

export default class SetupProperty extends React.Component {
  // Scroll to top on page load & track page path
  componentDidMount() {
    window.scrollTo(0, 0);
  //  ReactGA.pageview(window.location.pathname + window.location.search);
  }
  componentDidUpdate = () => {
  //  ReactGA.pageview(window.location.pathname + window.location.search);
  };

  render() {
    const { SaveStatus } = this.props;
    const SectionTitle = "Create a New Place";

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="setupflow-heading">{SectionTitle}</div>
            <div className="setupflow-subheading">
              Choose a name for your place e.g. "Seaside Escape"
            </div>

            <CreatePropertyModule
              {...this.props}
              SaveStatus={SaveStatus}
              ItemType={"property"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-full"}
              ItemLabel={"New Place"}
              ItemHeading={"Place Title"}
              InputLabelA={"Place name"}
              ValueA={""}
              FalseText={"Not entered"}
              PayloadA={"PropertyTitle"}
              SetupFlow={true}
            />
          </div>
        </div>
      </>
    );
  }
}
