import React, { useState } from "react";
import ChevronRightIcon from "../icons/ChevronRightIcon";
import "../styles/ButtonBanners.css";

export default function ButtonBanner(props) {
  const { Icon, Heading, SubHeading, Target, PropertyId, Callout } = props;
  const [loading, setLoading] = useState(false);

  const handleClick = (e) => {
    setLoading(true);
    props.history.push({
      pathname: "/" + Target,
      state: { propertyId: PropertyId }
    });
  };

  return (
    <>
      {loading && <div> Loading... </div>}

      <div className="button-banner" onClick={handleClick}>
        {Callout && <div className="button-banner-callout">{Callout}</div>}
        <div className="button-banner-main-icon-wrapper">
          <Icon className={"button-banner-main-icon"} />
        </div>

        <div className="button-banner-heading-and-chevron-wrapper">
          <div className="button-banner-heading">{Heading}</div>
          <div className="button-banner-chevron-icon-wrapper">
            <ChevronRightIcon className="button-banner-chevron-icon" />
          </div>
        </div>

        <div className="button-banner-subheading">{SubHeading}</div>
      </div>
    </>
  );
}
