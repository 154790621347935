import React from "react";

function ChatIcon(props) {
  const { className } = props;
  if (className === "sticky-footer-icon-active") {
    return (
      <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M306 0C137.012 0 0 119.875 0 267.75c0 84.514 44.848 159.751 114.75 208.826V612l134.047-81.339c18.552 3.061 37.638 4.839 57.203 4.839 169.008 0 306-119.875 306-267.75S475.008 0 306 0Z"
          transform="translate(.325 .325) scale(.81593)"
        />
      </svg>
    );
  } else
    return (
      <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M306 0C137.012 0 0 119.875 0 267.75c0 84.514 44.848 159.751 114.75 208.826V612l134.047-81.339c18.552 3.061 37.638 4.839 57.203 4.839 169.008 0 306-119.875 306-267.75S475.008 0 306 0Zm0 497.25c-22.338 0-43.911-2.601-64.643-7.019l-90.041 54.123 1.205-88.701C83.5 414.133 38.25 345.513 38.25 267.75c0-126.741 119.875-229.5 267.75-229.5s267.75 102.759 267.75 229.5S453.875 497.25 306 497.25Z"
          transform="translate(.325 .325) scale(.81593)"
        />
      </svg>
    );
}

export default ChatIcon;
