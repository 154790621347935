import React from "react";
//import ReactGA from "react-ga";
import {
  // googleAnalyticsId,
  dataApiRoot,
  apiSecretToken
} from "../constants.js";
import "../styles/DisplayCanvas.css";
import "../styles/PartnersLanding.css";

//ReactGA.initialize(googleAnalyticsId);

export default class PartnersLanding extends React.Component {
  state = {
    affiliateData: null
  };
  // Scroll to top on page load & track page path
  componentDidMount = () => {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname + window.location.search);

    const affiliateTrackingLabel = window.location.pathname.split("/")[2];
    sessionStorage.setItem("affiliateTrackingLabel", affiliateTrackingLabel);

    fetch(
      `${dataApiRoot}affiliates?constraints=[{%22key%22:%22affiliatetrackinglabel%22,%22constraint_type%22:%22equals%22,%22value%22:%22${affiliateTrackingLabel}%22}]`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`
        }
      }
    )
      .then((res) => res.json())
      .then((data) => {
        //  console.log(data.response.results[0]);
        this.setState({ affiliateData: data.response.results[0] });
      });

    fetch(
      `${dataApiRoot}systemcontent?constraints=[{%22key%22:%22detaillabel%22,%22constraint_type%22:%22equals%22,%22value%22:%22affiliate-landing%22}]`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`
        }
      }
    )
      .then((res) => res.json())
      .then((data) => {
        //  console.log(data.response.results[0]);
        this.setState({ affiliateSystemData: data.response.results[0] });
      });
  };

  // componentDidUpdate = () => {
  //  ReactGA.pageview(window.location.pathname + window.location.search);
  //};

  onButtonClick = () => {
    //  this.props.history.push(`/try-free`);
    const affiliateCampaignLabel = sessionStorage.getItem(
      "affiliateTrackingLabel"
    );
    window.location.href = `https://try.airguide.co.uk/start?utm_source=affiliate-landing-page&utm_medium=affiliate&utm_campaign=${affiliateCampaignLabel}`;
  };

  onLinkClick = () => {
    const affiliateCampaignLabel = sessionStorage.getItem(
      "affiliateTrackingLabel"
    );
    window.location.href = `https://www.airguide.co.uk?utm_source=affiliate-landing-page&utm_medium=affiliate&utm_campaign=${affiliateCampaignLabel}`;
  };

  render(affiliateTrackingLabel) {
    const { SaveStatus } = this.props;
    const ItemLabel = "partner-link";

    if (this.state.affiliateData && this.state.affiliateSystemData) {
      return (
        <>
          <div className="canvas-with-header-and-footer">
            <div className="canvas-with-header-and-footer-inner-container">
              <div className="landing-cover-outer-container">
                <div className="landing-cover-mid-container">
                  <div className="landing-cover-inner-container">
                    <div className="landing-cover-image-and-logo-container">
                      <div className="landing-cover-image-container">
                        <img
                          className={"landing-cover-image"}
                          src={this.state.affiliateSystemData.DetailImage}
                          alt={this.state.affiliateData.AffiliateName}
                        />
                      </div>
                      <div>
                        <img
                          className={"landing-partner-logo"}
                          src={this.state.affiliateData.AffiliateLogo}
                          alt={this.state.affiliateData.AffiliateName}
                        />
                      </div>
                    </div>

                    <div className="landing-cover-content-container">
                      <h1 className="landing-h1">
                        {this.state.affiliateSystemData.DetailTitle}
                      </h1>

                      <h2 className="landing-h2">
                        {this.state.affiliateData.AffiliateName}{" "}
                        {this.state.affiliateSystemData.DetailTextDescribe}
                      </h2>

                      <div className="landing-cta-button-wrapper">
                        <input
                          type="submit"
                          className="landing-cta-button"
                          onClick={this.onButtonClick}
                          value={
                            SaveStatus === `${ItemLabel}-fetching` ||
                            SaveStatus === undefined ||
                            SaveStatus === `${ItemLabel}-success`
                              ? "Saving..."
                              : "Start FREE trial now"
                          }
                        />
                      </div>

                      <div
                        className="landing-link-button"
                        onClick={this.onLinkClick}
                      >
                        {"What is airguide? Find out more >"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else
      return (
        <>
          <div> Error, this page does not exist </div>
        </>
      );
  }
}
