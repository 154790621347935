import React from "react";

function BellIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 655.715 655.715"
      {...props}
    >
      <path d="M546.429 393.429V218.572C546.429 97.854 448.574 0 327.857 0S109.286 97.854 109.286 218.572v174.857L21.857 568.286h198.922c10.12 49.878 54.206 87.429 107.078 87.429s96.958-37.551 107.078-87.429h198.922l-87.428-174.857zM327.857 612c-28.502 0-52.522-18.294-61.55-43.714h123.121C380.38 593.706 356.359 612 327.857 612zM87.429 524.571L153 393.429V218.572c0-96.565 78.292-174.857 174.857-174.857 96.564 0 174.857 78.292 174.857 174.857v174.857l65.571 131.143H87.429z" />
    </svg>
  );
}

export default BellIcon;
