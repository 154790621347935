export default function countSessions(
  array,
  date,
  key1,
  value1,
  key2,
  value2,
  key3,
  value3
) {
  const arrayFilter = array.filter((item) => {
    return (
      item.dateLookup === date &&
      item[key1] === value1 &&
      item[key2] === value2 &&
      item[key3] === value3
    );
  });

  const filterSessions = arrayFilter.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => t.TrackingSessionId === value.TrackingSessionId // && t.name === value.name
      )
  );

  var count = filterSessions.length;

  return count;
}

//console.log(count);
