import React from "react";

export default class FormattedDaT extends React.Component {
  render() {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    //  var days = [
    //    "Sunday",
    //    "Monday",
    //   "Tuesday",
    //   "Wednesday",
    //   "Thursday",
    //   "Friday",
    //   "Saturday"
    // ];
    var d = new Date(this.props.DateInput);
    // var day = days[d.getDay()];

    //  var hr = d.getHours();
    //   if (hr < 1) {
    //     hr = "00";
    //   }
    //   var min = d.getMinutes();
    //  if (min < 10) {
    //    min = "0" + min;
    //  }
    // var ampm = "am";
    // if (hr === 12) {
    //    hr -= 0;
    //   ampm = "pm";
    //  }
    //  if (hr > 12) {
    //   hr -= 12;
    //   ampm = "pm";
    //  }

    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    var formattedDaT =
      // day + " "
      // +
      date + " " + month + " " + year;
    // +
    //   ", " +
    //  hr +
    //  ":" +
    //  min +
    //  ampm
    return <>{formattedDaT}</>;
  }
}
