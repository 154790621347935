import React from "react";

function ProfileIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={612}
      height={612}
      viewBox="0 0 612 612"
      {...props}
    >
      <path d="M343.312 312.732c24.48-33.029 39.188-75.353 39.188-121.482C382.5 85.623 305.445 0 210.375 0S38.25 85.623 38.25 191.25c0 46.129 14.707 88.453 39.187 121.482C32.493 328.28 0 370.509 0 420.75v76.5C0 560.63 51.37 612 114.75 612H306c63.38 0 114.75-51.37 114.75-114.75v-76.5c0-50.241-32.493-92.47-77.438-108.018zM76.5 191.25c0-84.494 59.938-153 133.875-153s133.875 68.506 133.875 153-59.938 153-133.875 153S76.5 275.744 76.5 191.25zm306 296.438c0 47.525-42.037 86.062-93.885 86.062h-156.48c-51.848 0-93.885-38.537-93.885-86.062v-57.375c0-40.66 30.849-74.53 72.216-83.538 28.19 22.415 62.615 35.726 99.909 35.726s71.719-13.311 99.909-35.726c41.367 9.008 72.216 42.878 72.216 83.538v57.375zM439.875 153h153c10.557 0 19.125-8.568 19.125-19.125s-8.568-19.125-19.125-19.125h-153c-10.557 0-19.125 8.568-19.125 19.125S429.318 153 439.875 153zm153 76.5h-153c-10.557 0-19.125 8.568-19.125 19.125s8.568 19.125 19.125 19.125h153c10.557 0 19.125-8.568 19.125-19.125s-8.568-19.125-19.125-19.125zm0 114.75h-114.75c-10.557 0-19.125 8.568-19.125 19.125s8.568 19.125 19.125 19.125h114.75c10.557 0 19.125-8.568 19.125-19.125s-8.568-19.125-19.125-19.125zm0 114.75h-114.75C467.568 459 459 467.568 459 478.125s8.568 19.125 19.125 19.125h114.75c10.557 0 19.125-8.568 19.125-19.125S603.432 459 592.875 459z" />
    </svg>
  );
}

export default ProfileIcon;
