import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import Breadcrumb from "./Breadcrumb";
//import InfoItemOneBox from "./InfoItemOneBox";
import InfoItemTel from "./InfoItemTel";
import InfoItemTwoBox from "./InfoItemTwoBox";
import InfoItemImage from "./InfoItemImage";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

//ReactGA.initialize(googleAnalyticsId);

export default class DashboardPersonalInfo extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
    //  ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { SaveStatus, userData } = this.props;

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <Breadcrumb
              {...this.props}
              PreviousPageTitle={"Dashboard"}
              PreviousPagePath={"/"}
              CurrentSectionTitle={"Personal Information"}
            />
            <h1> Personal Information </h1>
            <div className="infopage-subheading">
              {" "}
              Provide your personal contact details so we can reach you. These
              details will not be shared with your guests.
            </div>

            <div className="infoitem-container">
              <div className="section-break-wrapper">
                <div className="section-break"></div>
              </div>
            </div>

            <InfoItemTwoBox
              SaveStatus={SaveStatus}
              userId={userData._id}
              ItemType={"user"}
              ItemLabel={"Name"}
              ItemHeading={"Name"}
              ItemDescription={"This is the name of the account holder"}
              InputLabelA={"First name"}
              ValueA={userData.UserFirstname}
              PayloadA={"UserFirstname"}
              InputLabelB={"Last name"}
              ValueB={userData.UserLastname}
              PayloadB={"UserLastname"}
              IsChecked={
                userData.UserFirstname || userData.UserLastname ? true : false
              }
              IsRequired={true}
              FalseText={"Not entered"}
            />

            <InfoItemTel
              SaveStatus={SaveStatus}
              userId={userData._id}
              itemType={"user"}
              ContainerClassName={"infoitem-form-inner-container-left"}
              itemLabel={"phone-number"}
              ItemHeading={"Phone number"}
              ItemDescription={
                "This is the number we will use if we need to contact you"
              }
              InputLabelA={"Phone number"}
              ValueA={userData.UserTel}
              PayloadA={"UserTel"}
              IsChecked={userData.UserTel ? true : false}
              IsRequired={true}
              FalseText={"Not entered"}
            />

            <InfoItemImage
              SaveStatus={SaveStatus}
              userId={userData._id}
              ItemType={"user"} // Defines which dispatch to invoke
              ItemHeading={"Photo"}
              ItemLabel={"user-cover-image"}
              FileFolder={"user-content"}
              ItemId={userData._id} // Defines which id to insert into filename
              ItemDescription={
                "This is your dashboard profile image (it is not displayed to your guests)"
              }
              InputLabelA={""}
              ValueA={userData.UserCoverImage}
              PayloadA={"UserCoverImage"} // Defines which database label to update
              IsChecked={userData.UserCoverImage ? true : false}
              IsRequired={false}
              FalseText={"Not uploaded"}
            />
          </div>
        </div>
      </>
    );
  }
}
