import React from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { setSaveStatus, autoLogin } from "../redux/actions/userActions";
import { workflowApiRoot } from "../constants.js";
import InfoItemCheckMark from "./InfoItemCheckMark";
import CardSection from "./CardSection";
import amexLogo from "../images/amex-logo.png";
import mastercardLogo from "../images/mastercard-logo.png";
import visaLogo from "../images/visa-logo.png";
import creditCardBadge from "../images/credit-card-badge.png";
import stripeBadge from "../images/stripe-secure-badge.png";

function DashboardCheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const {
    ItemLabel,
    IsChecked,
    SaveStatus,
    ItemHeading,
    ValueA,
    InputLabelA,
    ValueB,
    userData,
    stripeData
  } = props;

  const cardOnFileFailed = userData.CardOnFileFailed;
  const subscriptionData = stripeData.subscriptionData;
  const paymentPastDue =
    subscriptionData["_api_c2_body.status"] === "past_due" ||
    subscriptionData["_api_c2_body.status"] === "incomplete"
      ? true
      : false;
  const showCardFailedMessage =
    cardOnFileFailed && paymentPastDue ? true : false;
  const cardBrandCapitalized = ValueA.charAt(0).toUpperCase() + ValueA.slice(1);
  //console.log("SubscriptionData", subscriptionData);

  const onEdit = (e) => {
    e.preventDefault();
    const cardElement = elements.getElement(CardElement);
    cardElement.clear();
    props.setSaveStatus(`${ItemLabel}-editing`);
  };

  const onCancel = (e) => {
    e.preventDefault();
    props.setSaveStatus(`${ItemLabel}-idle`);
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    props.setSaveStatus(`${ItemLabel}-fetching`);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    if (error) {
      //    console.log("[error]", error);
      props.setSaveStatus(`${ItemLabel}-error`);
      cardElement.clear();
    } else {
      //   console.log("[PaymentMethod]", paymentMethod);

      const ItemLabel = "payment-method";
      const userInfo = {
        customerId: props.userData.StripeCustomerID,
        paymentMethodId: paymentMethod.id,
        paymentMethodBrand: paymentMethod.card.brand,
        paymentMethodlastFour: paymentMethod.card.last4,
        paymentMethodExpMonth: paymentMethod.card.exp_month,
        paymentMethodExpYear: paymentMethod.card.exp_year
      };

      fetch(`${workflowApiRoot}update-payment-method/initializeuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`
        },
        body: JSON.stringify(userInfo)
      })
        .then((response) => response.json())
        .then((results) => {
          //  console.log(results);
          if (!results.response.stripePaymentSuccess) {
            props.setSaveStatus("payment-details-error");
            cardElement.clear();
          }
          if (results.response.stripePaymentSuccess) {
            props.autoLogin(ItemLabel);
          }
        });
    }
  };

  return (
    <>
      <div className="infoitem-container">
        <div className="infoitem-heading-and-button-container">
          <div className="infoitem-heading">
            <InfoItemCheckMark
              IsChecked={showCardFailedMessage ? false : IsChecked}
              IsCustomContent={true}
              ItemHeading={ItemHeading}
              IsRequired={true}
            />
          </div>

          {!showCardFailedMessage && (
            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing` ||
                SaveStatus === `${ItemLabel}-error`
                  ? onCancel
                  : onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching` ||
              SaveStatus === `${ItemLabel}-error`
                ? "Cancel"
                : "Edit"}
            </button>
          )}
        </div>

        {(SaveStatus !== `${ItemLabel}-editing` ||
          SaveStatus !== `${ItemLabel}-fetching` ||
          SaveStatus !== `${ItemLabel}-error`) && (
          <>
            <div className="subscription-product-container">
              <div className="icon-and-text-inline-container">
                {userData.StripeCardID && (
                  <img
                    className="card-logo"
                    src={
                      userData.StripeCardBrand === "visa"
                        ? visaLogo
                        : userData.StripeCardBrand === "amex"
                        ? amexLogo
                        : userData.StripeCardBrand === "mastercard"
                        ? mastercardLogo
                        : null
                    }
                    alt="Card Logo"
                  ></img>
                )}
                <div className="icon-and-text-inline-text">
                  <>
                    <>
                      {cardBrandCapitalized} {ValueB}
                    </>
                    {showCardFailedMessage && (
                      <>
                        <br />
                        <br />
                        <strong style={{ color: "var(--UIcolour5" }}>
                          The latest payment with this card failed. Please add a
                          new card below to avoid your account being cancelled.
                        </strong>
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className={
            SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching` ||
            SaveStatus === `${ItemLabel}-error` ||
            showCardFailedMessage
              ? "show"
              : "hide"
          }
        >
          <div className="infoitem-form-container">
            <form className="infoitem-form" onSubmit={handleSubmit}>
              <div className="infoitem-card-section-container">
                <label className="infoitem-form-label"> {InputLabelA} </label>
                <div className="infoitem-card-section-container-inner">
                  <CardSection />
                </div>
              </div>
              <div className="infoitem-form-inner-container-left">
                <input
                  type="submit"
                  disabled={!stripe}
                  className="infoitem-save-button"
                  value={
                    SaveStatus === `${ItemLabel}-fetching`
                      ? "Saving..."
                      : "Save"
                  }
                />
              </div>

              {SaveStatus === `${ItemLabel}-error` && (
                <div className="infoitem-form-error-message">
                  Card details were not saved. Please try again.
                </div>
              )}
            </form>

            <div className="payment-badge-container">
              <a href="https://stripe.com/">
                <img
                  className="stripe-badge"
                  src={stripeBadge}
                  alt="Secure Payments Powered by Stripe"
                ></img>
              </a>

              <img
                className="card-badge-container"
                src={creditCardBadge}
                alt="We accept Visa, Mastercard and American Express"
              ></img>
            </div>
          </div>
        </div>

        <div className="infoitem-section-break-wrapper">
          <div className="infoitem-section-break"></div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
  autoLogin: (ItemLabel) => dispatch(autoLogin(ItemLabel))
});

export default connect(null, mapDispatchToProps)(DashboardCheckoutForm);
