import React from "react";
import PropertyDetailsBasics from "./PropertyDetailsBasics";
import Loading from "./Loading";
import { connect } from "react-redux";
import { getCustomContent } from "../redux/actions/userActions";
import { setCustomContentLoaded } from "../redux/actions/userActions";
import { getLinkedProperties } from "../redux/actions/userActions";
import { setLinkedPropertiesLoaded } from "../redux/actions/userActions";

class PropertyDetailsBasicsLoader extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { PropertyId, CustomContentPropertyId } = this.props;

    // Condition if property content not already loaded (from link)
    if (
      this.props.location.state &&
      this.props.location.state.propertyId !== CustomContentPropertyId
    ) {
      const propertyId = this.props.location.state.propertyId;
      this.props.setCustomContentLoaded(false, CustomContentPropertyId);
      this.props.getCustomContent(propertyId);
      this.props.setLinkedPropertiesLoaded(true);
    }

    // Condition if property content is already loaded (from link)
    if (
      this.props.location.state &&
      this.props.location.state.propertyId === CustomContentPropertyId
    ) {
      this.props.setLinkedPropertiesLoaded(true);
      this.props.setCustomContentLoaded(true, CustomContentPropertyId);
    }

    // Condition if page loaded in browser
    if (!this.props.location.state && PropertyId) {
      this.props.setCustomContentLoaded(false, CustomContentPropertyId);
      this.props.getCustomContent(PropertyId);
      this.props.setLinkedPropertiesLoaded(true);
    }
  };

  render() {
    const {
      SaveStatus,
      userData,
      companyData,
      managerData,
      propertyData,
      systemContent,
      systemCategories,
      customContent,
      CustomContentLoaded,
      linkedPropertiesLoaded,
      PropertyId,
      FileLoadStatus
    } = this.props;

    const propertyId =
      this.props.location.state && this.props.location.state.propertyId
        ? this.props.location.state.propertyId
        : PropertyId;

    if (
      !linkedPropertiesLoaded ||
      !CustomContentLoaded ||
      !PropertyId ||
      !propertyData
    ) {
      return <Loading />;
    }

    if (
      linkedPropertiesLoaded &&
      CustomContentLoaded &&
      PropertyId &&
      propertyData
    ) {
      return (
        <PropertyDetailsBasics
          {...this.props}
          userData={userData}
          companyData={companyData}
          managerData={managerData}
          propertyData={propertyData}
          systemContent={systemContent}
          systemCategories={systemCategories}
          customContent={customContent}
          SaveStatus={SaveStatus}
          PropertyId={propertyId}
          FileLoadStatus={FileLoadStatus}
        />
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomContent: (propertyId, ItemLabel) =>
      dispatch(getCustomContent(propertyId, ItemLabel)),
    setCustomContentLoaded: (payload, propertyId) =>
      dispatch(setCustomContentLoaded(payload, propertyId)),
    setLinkedPropertiesLoaded: (payload) =>
      dispatch(setLinkedPropertiesLoaded(payload)),
    getLinkedProperties: (ItemLabel, userId) =>
      dispatch(getLinkedProperties(ItemLabel, userId))
  };
};

export default connect(null, mapDispatchToProps)(PropertyDetailsBasicsLoader);
