import React from "react";

function CopyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={612}
      height={612}
      viewBox="0 0 612 612"
      {...props}
    >
      <path d="M420.75 535.5c0 21.114-17.117 38.25-38.25 38.25h-306c-21.133 0-38.25-17.117-38.25-38.25v-306c0-21.133 17.117-38.25 38.25-38.25h38.25V153H76.5C34.253 153 0 187.253 0 229.5v306C0 577.747 34.253 612 76.5 612h306c42.247 0 76.5-34.253 76.5-76.5v-38.25h-38.25v38.25zM535.5 0h-306C187.253 0 153 34.253 153 76.5v306c0 42.247 34.253 76.5 76.5 76.5h306c42.247 0 76.5-34.253 76.5-76.5v-306C612 34.253 577.747 0 535.5 0zm38.25 382.5c0 21.114-17.117 38.25-38.25 38.25h-306c-21.133 0-38.25-17.136-38.25-38.25v-306c0-21.133 17.117-38.25 38.25-38.25h306c21.133 0 38.25 17.117 38.25 38.25v306z" />
    </svg>
  );
}

export default CopyIcon;
