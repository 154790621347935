import React from "react";

function ToolTipIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-1{fill:#f0f0f0;stroke:#10b9e8;stroke-width:12px;stroke-linecap:round;stroke-linejoin:round}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Capa_1" data-name="Capa 1">
          <g id="prefix__info">
            <circle className="prefix__cls-1" cx={128} cy={128} r={124} />
            <path className="prefix__cls-1" d="M104 108h24v72M104 180h48" />
            <circle
              cx={128}
              cy={84}
              r={4}
              strokeMiterlimit={10}
              fill="#f0f0f0"
              stroke="#10b9e8"
              strokeWidth={12}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ToolTipIcon;
