import React from "react";
import { connect } from "react-redux";
import { fetchUser } from "../redux/actions/userActions";
import { workflowApiRoot, apiSecretToken } from "../constants.js";
import { appConfig } from "../data/appConfig";
import "../styles/CancellationForm.css";

class CancellationForm extends React.Component {
  state = {
    userId: this.props.userData._id,
    name: "",
    email: "",
    tel: "",
    request: "pause-1-month",
    reason: "no-longer-manage",
    message: "",
    status: "idle",
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.state.status) {
      window.scrollTo(0, 0);
    }
  }

  onClickBack = () => {
    this.props.history.push("/subscription");
  };

  handleOnChange = (e) => {
    e.persist();
    this.setState(() => ({
      [e.target.name]: e.target.value,
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const passedState = this.state;
    fetch(`${workflowApiRoot}pause-or-cancel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiSecretToken}`,
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState(() => ({
            status: "success",
            userId: passedState._id,
            name: passedState.name,
            email: passedState.name,
            tel: passedState.tel,
            request: passedState.request,
            reason: passedState.reason,
            message: passedState.message,
          }));
        }
      });
  };

  render() {
    return (
      <>
        {this.state.status === "idle" && (
          <>
            <p>
              To edit or cancel your subscription please fill in the form below
              and click submit. Please note that we require 48 hours notice to
              edit or cancel your subscription, for further details please check
              our{" "}
              <a
                href={`${appConfig.siteUrl}/terms-of-service`}
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of service
              </a>
              .
            </p>

            <div className="cancellation-form">
              <form onSubmit={this.onSubmit}>
                <label className="infoitem-form-label">Name</label>
                <input
                  required
                  type="text"
                  name="name"
                  className="infoitem-text-input"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={this.handleOnChange}
                />
                <br />
                <label className="infoitem-form-label">Email address</label>
                <input
                  required
                  type="text"
                  name="email"
                  className="infoitem-text-input"
                  placeholder="Email address"
                  value={this.state.email}
                  onChange={this.handleOnChange}
                />
                <br />
                <label className="infoitem-form-label">Phone number</label>
                <input
                  required
                  type="text"
                  name="tel"
                  className="infoitem-text-input"
                  placeholder="Phone number"
                  value={this.state.tel}
                  onChange={this.handleOnChange}
                />
                <br />
                <label className="infoitem-form-label">
                  What would you like to do? (Please select option below)
                </label>

                <select
                  required
                  name="request"
                  onChange={this.handleOnChange}
                  value={this.state.request}
                  defaultValue={""}
                  className="cancellation-form-select"
                >
                  <option value="pause-1-month">Pause for 1 Month</option>
                  <option value="pause-3-months">Pause for 3 Months</option>
                  <option value="cancel">Cancel</option>
                </select>
                <br />
                <label className="infoitem-form-label">
                  Why are you choosing to do this? (Please select option below)
                </label>

                <select
                  required
                  name="reason"
                  onChange={this.handleOnChange}
                  value={this.state.reason}
                  defaultValue={""}
                  className="cancellation-form-select"
                >
                  <option value="no-longer-manage">
                    I'm no longer managing the property(s)
                  </option>
                  <option value="no-longer-own">
                    I no longer own the property(s)
                  </option>
                  <option value="no-longer-renting">
                    I'm no longer renting out the property(s)
                  </option>
                  <option value="product-issue">
                    I've had issues using the product/service
                  </option>
                  <option value="product-value">
                    I'm not getting value from the product/service
                  </option>
                  <option value="product-switch">
                    I'm switching to a competing product/service
                  </option>
                </select>
                <br />
                <label className="infoitem-form-label">
                  Any other comments
                </label>
                <input
                  type="textarea"
                  name="message"
                  className="cancellation-form-textarea"
                  placeholder="Any other comments"
                  value={this.state.message}
                  onChange={this.handleOnChange}
                />

                <input
                  type="submit"
                  className="login-button"
                  value={"Submit"}
                />
              </form>
            </div>
          </>
        )}
        {this.state.status === "success" && (
          <>
            <p>
              Thanks for your request, we will process your request as soon as
              possible. <strong>Please check your email inbox</strong>, we will
              confirm by email when this has been completed.{" "}
            </p>
            <p>
              For further information please check our{" "}
              <a
                href={`${appConfig.siteUrl}/terms-of-service`}
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of service
              </a>
            </p>
            {this.state.request === "cancel" &&
              (this.state.reason === "no-longer-manage" ||
                this.state.reason === "no-longer-own" ||
                this.state.reason === "no-longer-renting") && (
                <>
                  <p>
                    If our product has served you well, we'd really appreciate a
                    review on Capterra, you'll receive a free $20 Amazon voucher
                    for your trouble!
                  </p>
                  <p>
                    <strong>
                      <a
                        href={
                          "https://reviews.capterra.com/new/242066/711e9a25-040e-49c9-a500-6fcefab68cd9?lang=en"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to earn a FREE $20 Amazon Voucher
                      </a>
                    </strong>
                  </p>
                </>
              )}
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (userInfo) => dispatch(fetchUser(userInfo)),
  };
};

export default connect(null, mapDispatchToProps)(CancellationForm);
