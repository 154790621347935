import React from "react";
import ReactQuill from "react-quill";
//import "react-quill/dist/quill.snow.css";
import "../styles/InfoItemMultiTextEditor.css";

function InfoItemMultiTextEditor(props) {
  const modules = {
    toolbar: [
      // [{ size: ["normal", "large", "small"] }],
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ header: [2, false] }],
      [
        "bold",
        "italic",
        "underline" //, "strike", "blockquote"
      ],

      // [{ font: [] }],
      // [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        "link" //"video" // "image"
      ],
      [
        {
          color: [
            "var(--UIcolour1)",
            "var(--UIcolour2)",
            "var(--UIcolour3)",
            "var(--UIcolour4)",
            "var(--UIcolour5)",
            "var(--brandColour3)",
            "var(--UIcolour0)"
          ]
        }
      ]
      // [{ background: ["red", "#785412"] }]
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font"
  ];

  const { htmlText, placeHolder, itemIndex } = props;
  const handleProcedureContentChange = (content, delta, source, editor) => {
    props.onHtmlTextChange(content, itemIndex);
    // console.log("content", content)
    //let has_attribues = delta.ops[1].attributes || "";
    //console.log(has_attribues);
    //const cursorPosition = e.quill.getSelection().index;
    // this.quill.insertText(cursorPosition, "★");
    //this.quill.setSelection(cursorPosition + 1);
  };

  return (
    <>
      {
        //console.log(code)
      }
      <div className="infoitem-text-editor-container">
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          placeholder={placeHolder}
          value={htmlText}
          onChange={handleProcedureContentChange}
        />
      </div>
    </>
  );
}

export default InfoItemMultiTextEditor;
