import React from "react";
import "../styles/DisplayCanvas.css";
import "../styles/ButtonBanners.css";
import ImageBanner from "./ImageBanner";
// import TextBanner from "./TextBanner";
// import ChevronRightIcon from "../icons/ChevronRightIcon";

export default class DashboardProperties extends React.Component {
  componentDidMount = () => {
    //  document.body.style.setProperty("--tidio-display", "block");
  };
  onViewAllClick = () => {
    this.props.history.push("/properties");
  };

  onCreatePlaceClick = () => {
    this.props.history.push("/create-property");
  };

  render() {
    const { userData, propertyData, systemContent } = this.props;

    const propertyDataFiltered = propertyData
      ? propertyData.filter((Item) => {
          return Item.PropertyID !== userData.SharedContentProfileId;
        })
      : null;

    // Temp filter
    // const propertyDataFiltered = propertyData
    //      ? propertyData.filter((Item) => {
    //          return Item.PropertyTitle !== "Shared Content";
    //        })
    //      : null;

    // Extract placeholder property details from SystemContent
    const propertyDetailsPlaceholderFiltered = systemContent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "property-details-placeholder";
      }
    );
    const propertyDetailsPlaceholder = propertyDetailsPlaceholderFiltered[0];

    return (
      <>
        <div className="button-banner-section-outer-container">
          <div className="button-banner-section-container">
            {propertyDataFiltered.length !== 0 && (
              <h3>Recently Modified Properties</h3>
            )}

            <div className="button-banners-container">
              {!propertyDataFiltered[0] && (
                <div className="button-banner-section-description">
                  No properties setup yet
                </div>
              )}

              {propertyDataFiltered[0] && (
                <div className="button-banner-container-1">
                  <ImageBanner
                    Image={
                      propertyDataFiltered[0].PropertyCoverImage
                        ? propertyDataFiltered[0].PropertyCoverImage
                        : propertyDetailsPlaceholder.DetailImage
                    }
                    Heading={
                      propertyDataFiltered[0].PropertyTitle
                        ? propertyDataFiltered[0].PropertyTitle
                        : propertyDetailsPlaceholder.DetailTitle
                    }
                    Status={
                      userData.SubscriptionActiveUser &&
                      userData.SubscriptionActiveSystem &&
                      propertyDataFiltered[0].PropertyActiveStatus &&
                      propertyDataFiltered[0].PublishedStatus
                        ? true
                        : false
                    }
                    Target={`property/${propertyDataFiltered[0].PropertyID}`}
                    From={"dashboard"}
                    PropertyId={propertyDataFiltered[0]._id}
                    {...this.props}
                  />
                </div>
              )}

              {propertyDataFiltered[1] && (
                <div className="button-banner-container-2">
                  <ImageBanner
                    Image={
                      propertyDataFiltered[1].PropertyCoverImage
                        ? propertyDataFiltered[1].PropertyCoverImage
                        : propertyDetailsPlaceholder.DetailImage
                    }
                    Heading={
                      propertyDataFiltered[1].PropertyTitle
                        ? propertyDataFiltered[1].PropertyTitle
                        : propertyDetailsPlaceholder.DetailTitle
                    }
                    Status={
                      userData.SubscriptionActiveUser &&
                      userData.SubscriptionActiveSystem &&
                      propertyDataFiltered[1].PropertyActiveStatus &&
                      propertyDataFiltered[1].PublishedStatus
                        ? true
                        : false
                    }
                    Target={`property/${propertyDataFiltered[1].PropertyID}`}
                    From={"dashboard"}
                    PropertyId={propertyDataFiltered[1]._id}
                    {...this.props}
                  />
                </div>
              )}

              {propertyDataFiltered[2] && (
                <div className="button-banner-container-3">
                  <ImageBanner
                    Image={
                      propertyDataFiltered[2].PropertyCoverImage
                        ? propertyDataFiltered[2].PropertyCoverImage
                        : propertyDetailsPlaceholder.DetailImage
                    }
                    Heading={
                      propertyDataFiltered[2].PropertyTitle
                        ? propertyDataFiltered[2].PropertyTitle
                        : propertyDetailsPlaceholder.DetailTitle
                    }
                    Status={
                      userData.SubscriptionActiveUser &&
                      userData.SubscriptionActiveSystem &&
                      propertyDataFiltered[2].PropertyActiveStatus &&
                      propertyDataFiltered[2].PublishedStatus
                        ? true
                        : false
                    }
                    Target={`property/${propertyDataFiltered[2].PropertyID}`}
                    From={"dashboard"}
                    PropertyId={propertyDataFiltered[2]._id}
                    {...this.props}
                  />
                </div>
              )}

              {propertyDataFiltered[3] && (
                <div className="button-banner-container-4">
                  <ImageBanner
                    Image={
                      propertyDataFiltered[3].PropertyCoverImage
                        ? propertyDataFiltered[3].PropertyCoverImage
                        : propertyDetailsPlaceholder.DetailImage
                    }
                    Heading={
                      propertyDataFiltered[3].PropertyTitle
                        ? propertyDataFiltered[3].PropertyTitle
                        : propertyDetailsPlaceholder.DetailTitle
                    }
                    Status={
                      userData.SubscriptionActiveUser &&
                      userData.SubscriptionActiveSystem &&
                      propertyDataFiltered[3].PropertyActiveStatus &&
                      propertyDataFiltered[3].PublishedStatus
                        ? true
                        : false
                    }
                    Target={`property/${propertyDataFiltered[3].PropertyID}`}
                    From={"dashboard"}
                    PropertyId={propertyDataFiltered[3]._id}
                    {...this.props}
                  />
                </div>
              )}

              {
                //     <div
                //      className={
                //        propertyDataFiltered.length === 1
                //          ? "button-banner-container-2"
                //          : propertyDataFiltered.length === 2
                //          ? "button-banner-container-3"
                //          : propertyDataFiltered.length === 3
                //         ? "button-banner-container-4"
                //         : propertyDataFiltered.length >= 4
                //          ? "button-banner-container-5"
                //          : ""
                //       }
                //     >
                //       <TextBanner
                //          Icon={ChevronRightIcon}
                //          Heading={"Multiple properties?"}
                //          Description={"Save time by creating shared content"}
                //         ButtonText={"Start here"}
                //         Target={"shared-content/property"}
                //         {...this.props}
                //       />
                //      </div>
              }
            </div>

            {propertyDataFiltered.length > 4 && (
              <button
                className="create-new-button-inverted"
                onClick={this.onViewAllClick}
              >
                View all ({propertyDataFiltered.length})
              </button>
            )}

            <button
              className="create-new-button"
              onClick={this.onCreatePlaceClick}
            >
              Create new property
            </button>
          </div>
        </div>
      </>
    );
  }
}
