import React from "react";
import { connect } from "react-redux";
import { workflowApiRoot } from "../constants.js";
import { getLinkedPropertyManagers } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItem.css";

class DeleteManagerButton extends React.Component {
  state = {
    payload: {},
    cancelIsActive: false
  };

  onDeleteManager = () => {
    this.setState(() => ({
      cancelIsActive: true
    }));
  };

  onClickNoCancel = () => {
    this.setState(() => ({
      cancelIsActive: false
    }));
  };

  onSubmit = (e) => {
    const { ItemLabel, userId, managerId } = this.props;
    const Payload = { managerId: managerId };

    e.preventDefault();

    this.props.setSaveStatus(`${ItemLabel}-fetching`);
    fetch(`${workflowApiRoot}delete-manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`
      },
      body: JSON.stringify(Payload)
    })
      .then((response) => response.json())
      .then((results) => {
        //   console.log("Delete manager results", results);
        if (results.status !== "success") {
          this.props.setSaveStatus(`${ItemLabel}-error`);
        }
        if (results.status === "success") {
          this.props.getLinkedPropertyManagers(ItemLabel, userId);
          this.props.history.push("/manager");
        }
      });
  };

  render() {
    const { SaveStatus, ItemLabel } = this.props;

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-form-container">
            {!this.state.cancelIsActive && (
              <button
                onClick={this.onDeleteManager}
                className="cancel-button-inverted"
              >
                Delete manager
              </button>
            )}

            {this.state.cancelIsActive && (
              <>
                <div style={{ height: "15px" }}> </div>
                <div className="infoitem-form-label">
                  Are you sure you want to delete this property manager? This
                  action <strong>cannot be undone </strong>and all the data for
                  this property manager will be lost.
                </div>
                <div className="cancel-options-buttons-container">
                  <button
                    onClick={this.onClickNoCancel}
                    className="keep-button-inverted"
                  >
                    Keep manager
                  </button>
                  <button onClick={this.onSubmit} className="cancel-button">
                    {SaveStatus === `${ItemLabel}-fetching`
                      ? "Deleting..."
                      : "Delete manager"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getLinkedPropertyManagers: (ItemLabel, userId) =>
    dispatch(getLinkedPropertyManagers(ItemLabel, userId)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(DeleteManagerButton);
