import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import "../styles/DashboardHeader.css";
import { LogoutButton } from "./LogoutButton";
import ChatIcon from "../icons/ChatIcon";
//import logo from "../images/airguide-logo-colour.svg";
import GuideyLogo from "../icons/GuideyLogo";

export default function DashboardHeader(props) {
  const { systemContent } = props;

  const userData = useSelector(
    (state) => state.userReducer.user.userData,
    shallowEqual
  );

  const messagingActiveStatus = userData
    ? userData.MessagingActiveStatus
    : false;

  const messageData = useSelector(
    (state) => state.userReducer.user.messageData,
    shallowEqual
  );

  const unreadMessageCount = messageData
    ? messageData.filter(function (item) {
        if (item.InboundMessage && !item.ReadByRecipient) {
          return true;
        } else {
          return false;
        }
      }).length
    : userData
    ? userData.unreadMessageCount
    : 0;

  //console.log("unreadMessageCount", unreadMessageCount)

  const logoClick = () => {
    props.history.push("/");
  };

  const profileClick = () => {
    props.history.push("/personal-information");
  };

  const messagingClick = () => {
    props.history.push("/messaging");
  };

  // Extract placeholder property details from SystemContent
  var propertyManagerPlaceholderFiltered = null;
  if (systemContent) {
    propertyManagerPlaceholderFiltered = systemContent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "property-manager-placeholder";
      }
    );
  }

  return (
    <>
      <div className="headerbar-outer">
        <div className="headerbar">
          <div className="header-logo-container" onClick={logoClick}>
            <GuideyLogo width={"120"} height={"auto"} />
          </div>

          {userData && (
            <div className="header-button-and-avatar-container">
              <LogoutButton
                StaticText={"Logout"}
                pusher={props.pusher}
                {...props}
              />
              {messagingActiveStatus && (
                <div
                  className="header-chat-icon-container"
                  onClick={messagingClick}
                >
                  <span>
                    <ChatIcon className={"header-chat-icon"} />
                  </span>
                  {unreadMessageCount !== 0 && (
                    <span className="header-chat-icon-badge">
                      {unreadMessageCount}
                    </span>
                  )}
                </div>
              )}
              <img
                onClick={profileClick}
                className="header-profile-image"
                src={
                  userData && userData.UserCoverImage
                    ? userData.UserCoverImage
                    : propertyManagerPlaceholderFiltered
                    ? propertyManagerPlaceholderFiltered[0].DetailImage
                    : null
                }
                alt="Profile"
              ></img>
            </div>
          )}
        </div>
      </div>
      {
        // <div className="inline-static-link-header-container">
        //  <div className="inline-static-link-header-grey">
        //    airguide is now Guidey™{" "}
        //     <a
        //      href="https://guideyapp.com/from-airguide-to-guidey"
        //      target="_blank"
        //      rel="noopener noreferrer"
        //   >
        //      find out more
        //   </a>
        //    .
        //  </div>
        //  </div>
      }
    </>
  );
}
