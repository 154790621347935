import React from "react";

function LockedIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={792}
      height={792}
      viewBox="0 0 792 792"
      {...props}
    >
      <path d="M396 495c-13.662 0-24.75 11.088-24.75 24.75v99c0 13.662 11.088 24.75 24.75 24.75s24.75-11.088 24.75-24.75v-99c0-13.662-11.088-24.75-24.75-24.75zm198-98.555V198C594 88.654 505.346 0 396 0S198 88.654 198 198v198.445c-30.938 41.333-49.5 92.466-49.5 148.055C148.5 681.194 259.306 792 396 792s247.5-110.806 247.5-247.5c0-55.589-18.562-106.722-49.5-148.055zM247.5 198c0-82.021 66.479-148.5 148.5-148.5S544.5 115.979 544.5 198v148.847C503.093 315.686 451.786 297 396 297c-55.787 0-107.093 18.686-148.5 49.847V198zM396 742.5c-109.346 0-198-88.654-198-198s88.654-198 198-198c105.633 0 198 92.367 198 198 0 109.346-88.654 198-198 198z" />
    </svg>
  );
}

export default LockedIcon;
