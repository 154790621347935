import React from "react";
import { connect } from "react-redux";
import { workflowApiRoot } from "../constants.js";
import { getLinkedProperties } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import { setCustomContentLoaded } from "../redux/actions/userActions";
import { deleteUploadedFile } from "../redux/actions/userActions";
import "../styles/InfoItem.css";

class DeletePropertyButton extends React.Component {
  state = {
    payload: {},
    cancelIsActive: false
  };

  onDeleteProperty = () => {
    this.setState(() => ({
      cancelIsActive: true
    }));
  };

  onClickNoCancel = () => {
    this.setState(() => ({
      cancelIsActive: false
    }));
  };

  onSubmit = (e) => {
    const { ItemLabel, userData } = this.props;
    const payLoad = {
      propertyId: this.props.propertyId,
      propertyLabel: this.props.location.pathname
    };
    const userId = userData._id;
    const fileDirectory = `user/${userId}/property-content/${this.props.propertyId}/`;

    e.preventDefault();

    this.props.setSaveStatus(`${ItemLabel}-fetching`);
    fetch(`${workflowApiRoot}delete-property`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`
      },
      body: JSON.stringify(payLoad)
    })
      .then((response) => response.json())
      .then((results) => {
        //    console.log("Delete property results", results);
        if (results.status !== "success") {
          this.props.setSaveStatus(`${ItemLabel}-error`);
        }
        if (results.status === "success") {
          this.props.getLinkedProperties(ItemLabel, userId);
          this.props.setCustomContentLoaded(false, "");
          this.props.history.push("/property-deleted");
          this.props.deleteUploadedFile(fileDirectory);
        }
      });
  };

  render() {
    const { SaveStatus, ItemLabel } = this.props;

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-form-container">
            {!this.state.cancelIsActive && (
              <button
                onClick={this.onDeleteProperty}
                className="cancel-button-inverted"
              >
                Delete property
              </button>
            )}

            {this.state.cancelIsActive && (
              <>
                <div style={{ height: "15px" }}> </div>
                <div className="infoitem-form-label">
                  Are you sure you want to delete this property? This action{" "}
                  <strong>cannot be undone </strong>and all property data will
                  be lost.
                </div>
                <div className="cancel-options-buttons-container">
                  <button
                    onClick={this.onClickNoCancel}
                    className="keep-button-inverted"
                  >
                    Keep property
                  </button>
                  <button onClick={this.onSubmit} className="cancel-button">
                    {SaveStatus === `${ItemLabel}-fetching`
                      ? "Deleting..."
                      : "Delete property"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getLinkedProperties: (ItemLabel, userId) =>
    dispatch(getLinkedProperties(ItemLabel, userId)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
  setCustomContentLoaded: (payload, propertyId) =>
    dispatch(setCustomContentLoaded(payload, propertyId)),
  deleteUploadedFile: (fileUrl) => dispatch(deleteUploadedFile(fileUrl))
});

export default connect(null, mapDispatchToProps)(DeletePropertyButton);
