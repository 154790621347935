import React from "react";
import DashboardManagerProfile from "./DashboardManagerProfile";
import Loading from "./Loading";
import { connect } from "react-redux";
import { getCustomContent } from "../redux/actions/userActions";
import { setLinkedPropertyManagersLoaded } from "../redux/actions/userActions";

class DashboardManagerProfileLoader extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (
      this.props.location.state &&
      this.props.location.state.ManagerVanityID
    ) {
      this.props.setLinkedPropertyManagersLoaded(false);
    } else {
      this.props.setLinkedPropertyManagersLoaded(true);
    }
  };

  render() {
    const {
      SaveStatus,
      userData,
      companyData,
      managerData,
      propertyData,
      LinkedPropertyManagersLoaded
    } = this.props;

    const managerVanityId =
      this.props.location.state && this.props.location.state.ManagerVanityID
        ? this.props.location.state.ManagerVanityID
        : window.location.pathname.split("/")[2];

    if (!LinkedPropertyManagersLoaded) {
      return <Loading />;
    }

    if (LinkedPropertyManagersLoaded) {
      return (
        <DashboardManagerProfile
          {...this.props}
          userData={userData}
          companyData={companyData}
          managerData={managerData}
          propertyData={propertyData}
          SaveStatus={SaveStatus}
          managerVanityId={managerVanityId}
        />
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomContent: (propertyId) => dispatch(getCustomContent(propertyId)),
    setLinkedPropertyManagersLoaded: (payload) =>
      dispatch(setLinkedPropertyManagersLoaded(payload))
  };
};

export default connect(null, mapDispatchToProps)(DashboardManagerProfileLoader);
