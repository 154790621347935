import React, { useState, useEffect, memo } from "react";
import ReactLoading from "react-loading";
import "../styles/ConversationMessage.css";
import DocumentIcon from "../icons/DocumentIcon";

export default memo(function ConversationMessageFile(props) {
  const [loading, setLoading] = useState(true);
  const { fileData } = props;
  const fileType = fileData.substr(fileData.length - 4);
  const fullFileName = fileData.substring(fileData.lastIndexOf("/") + 1);
  const fileName =
    fullFileName.length > 17 ? `..${fullFileName.slice(-17)}` : fullFileName;

  const imageTypeList = [
    ".bmp",
    ".BMP",
    ".gif",
    ".GIF",
    "jpeg",
    "JPEG",
    ".jpg",
    ".JPG",
    ".png",
    ".PNG",
    ".tif",
    ".TIF",
    "tiff",
    "TIFF",
    "webp",
    "WEBP"
  ];

  const fileIsImage = imageTypeList.includes(fileType) ? true : false;

  const videoTypeList = [
    ".mp4",
    ".MP4",
    ".mov",
    ".MOV",
    ".wmv",
    ".WMV",
    ".avi",
    ".AVI",
    "vchd",
    "VCHD",
    ".mkv",
    ".MKV",
    "webm",
    "WEBM",
    "tml5",
    "TML5",
    "eg-2",
    "EG-2"
  ];

  const fileIsVideo = videoTypeList.includes(fileType) ? true : false;

  // Initial Mount
  useEffect(() => {
    if (fileData) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData]);

  if (loading) {
    return (
      <>
        <div style={{ height: "120px", margin: "auto", padding: "auto" }}>
          <ReactLoading
            type={"spin"}
            color={"var(--UIcolour1)"}
            height={1}
            width={40}
          />
        </div>
      </>
    );
  }

  if (!loading) {
    if (fileData)
      return (
        <>
          <a
            href={fileData}
            download={fileData}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <div className="conversation-message-file-container">
              {fileIsImage && (
                <img
                  className="conversation-message-file-image"
                  src={fileData}
                  alt={fileName}
                />
              )}

              {fileIsVideo && (
                <>
                  <video
                    className="conversation-message-file-image"
                    muted
                    src={fileData}
                  />
                </>
              )}

              {!fileIsImage && !fileIsVideo && (
                <DocumentIcon className="conversation-message-file-icon" />
              )}

              <div className="conversation-message-file-name">{fileName}</div>
            </div>
          </a>
        </>
      );
    else return <></>;
  }
});
