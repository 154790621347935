import React from "react";
import { connect } from "react-redux";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItem.css";
import InfoItemCheckMark from "./InfoItemCheckMark";
import LoadingButton from "./LoadingButton";
import InfoItemThemeColoursChild from "./InfoItemThemeColoursChild";

class InfoItemThemeColoursLoader extends React.Component {
  state = {
    payload: {},
    inputKey: null,
    showMap: false
  };

  componentDidMount = () => {
    this.setState({
      radioManualChecked: this.props.propertyDetails.CompanyProfile
        ? false
        : true,
      radioCompanyChecked: this.props.propertyDetails.CompanyProfile
        ? true
        : false,
      manual: this.props.propertyDetails.CompanyProfile ? false : true
    });
    window.scrollTo(0, 0);
  };

  switchChildComponent = () => {
    this.setState({
      manual: !this.state.manual
    });
  };

  onEdit = (e) => {
    e.preventDefault();
    const { ItemLabel } = this.props;
    this.props.setSaveStatus(`${ItemLabel}-editing`);
  };

  onCancel = (e) => {
    e.preventDefault();
    const { ItemLabel } = this.props;
    this.props.setSaveStatus(`${ItemLabel}-idle`);
  };

  render() {
    const {
      SaveStatus,
      companyData,
      ItemLabel,
      propertyDetails,
      ItemDescription,
      propertyId,
      IsChecked,
      IsRequired,
      ValueA,
      ValueB,
      FalseText,
      PayloadA,
      ItemHeading,
      InputLabelA,
      InputLabelB
    } = this.props;

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={IsChecked}
                IsRequired={IsRequired}
                IsCustomContent={ValueA ? true : false}
                ItemHeading={ItemHeading}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "hide"
                : "show"
            }
          >
            {propertyDetails.CompanyProfile && (
              <>
                <label className="infoitem-form-label">
                  {companyData[0].MgmtCoBrandname}
                </label>
                <div className="infoitem-image-container">
                  <img
                    className="infoitem-image-rectangle"
                    src={companyData[0].MgmtCoLogo}
                    alt={companyData[0].MgmtCoBrandname}
                  />
                </div>
              </>
            )}

            {!propertyDetails.CompanyProfile && ValueA && ValueB && (
              <div className="colour-picker-preview-outer">
                <div className="infoitem-form-inner-container-left">
                  <label className="infoitem-form-label"> {InputLabelA} </label>
                  <div
                    className="colour-picker-preview"
                    style={{
                      background: `${ValueA}`
                    }}
                  ></div>
                  <div className="colour-picker-preview-text">{ValueA}</div>
                </div>

                <div className="infoitem-form-inner-container-right">
                  <label className="infoitem-form-label"> {InputLabelB} </label>
                  <div
                    className="colour-picker-preview"
                    style={{
                      background: `${ValueB}`
                    }}
                  ></div>
                  <div className="colour-picker-preview-text">{ValueB}</div>
                </div>
              </div>
            )}

            {!propertyDetails.CompanyProfile &&
              (!ValueA || !ValueB) &&
              FalseText}
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "show"
                : "hide"
            }
          >
            {(SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`) && (
              <div className="infoitem-value">{ItemDescription}</div>
            )}

            <div className="infoitem-form-container">
              <div
                className={
                  this.state.manual ? "infoitem-form" : "infoitem-form-column"
                }
              >
                {companyData && companyData[0] && !this.state.manual && (
                  <div className="infoitem-image-container">
                    <img
                      className="infoitem-image"
                      src={companyData[0].MgmtCoLogo}
                      alt={companyData[0].MgmtCoBrandname}
                    />
                  </div>
                )}

                {companyData && companyData[0] && (
                  <div
                    className="radios-container"
                    onChange={this.switchChildComponent}
                  >
                    {companyData.map((item) => (
                      <div key={item._id}>
                        <label htmlFor={item._id}>
                          <div className="radio-container">
                            <input
                              className="radio-input"
                              type="radio"
                              defaultChecked={this.state.radioCompanyChecked}
                              name={PayloadA}
                              id={item._id}
                              value={item._id}
                            />

                            <div className="radio-text">
                              {item.MgmtCoBrandname}
                            </div>
                          </div>
                        </label>
                      </div>
                    ))}

                    <div
                      key={"manual"}
                      //      className={"radio-image-banners-container-column"}
                    >
                      <label htmlFor={"manual"}>
                        <div className="radio-container">
                          <input
                            className="radio-input"
                            type="radio"
                            defaultChecked={this.state.radioManualChecked}
                            name={PayloadA}
                            id={"manual"}
                            value={"manual"}
                          />
                          <div className="radio-text">{"Enter manually"}</div>
                        </div>
                      </label>
                    </div>
                  </div>
                )}

                {companyData && !this.state.manual && (
                  <div className="infoitem-form-inner-container-left">
                    <LoadingButton
                      ItemLabel={ItemLabel}
                      StaticText={"Save"}
                      LoadingText={"Saving"}
                      Loading={
                        SaveStatus === `${ItemLabel}-fetching` ? true : false
                      }
                      Payload={{ CompanyProfile: true }}
                      ItemType={"property"}
                      propertyId={propertyId}
                    />
                  </div>
                )}

                {((companyData && this.state.manual) || !companyData) && (
                  <InfoItemThemeColoursChild
                    SaveStatus={SaveStatus}
                    propertyDetails={propertyDetails}
                    companyData={companyData}
                    propertyId={propertyDetails._id}
                    ItemType={"property"} // Defines which dispatch to invoke
                    ItemLabel={"theme-colours-loader"}
                    InputLabelA={"Icons and buttons"}
                    ValueA={propertyDetails.PropertyHexPrimary}
                    PayloadA={"PropertyHexPrimary"}
                    InputLabelB={"Menu and banners"}
                    ValueB={propertyDetails.PropertyHexSecondary}
                    PayloadB={"PropertyHexSecondary"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemThemeColoursLoader);
