import React from "react";
import ReactLoading from "react-loading";
import "../styles/LoadingButton.css";

export default function LoadingButtonTwo(props) {
  const { Loading, LoadingText, StaticText, ClickFunction, Style } = props;

  const Styling =
    Style === "cta"
      ? {
          buttonClass: "button-with-loading",
          textClass: "button-with-loading-text",
          textClassLoading: "button-with-loading-text-loading"
        }
      : Style === "delete"
      ? {
          buttonClass: "button-with-loading-delete",
          textClass: "button-with-loading-text-delete",
          textClassLoading: "button-with-loading-text-loading-delete"
        }
      : Style === "inverted"
      ? {
          buttonClass: "button-with-loading-inverted",
          textClass: "button-with-loading-text-inverted",
          textClassLoading: "button-with-loading-text-loading-inverted"
        }
      :{};

  return (
    <button className={Styling.buttonClass} onClick={ClickFunction}>
      <div className={Loading ? Styling.textClassLoading : Styling.textClass}>
        {Loading ? LoadingText : StaticText}
      </div>
      {Loading && (
        <ReactLoading
          type={"spin"}
          color={"white"}
          height={"20%"}
          width={"20%"}
        />
      )}
    </button>
  );
}
