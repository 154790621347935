import React from "react";
import PropertyDetailsInfoAuto from "./PropertyDetailsInfoAuto";
import Loading from "./Loading";
import { connect } from "react-redux";
import { getCustomContent } from "../redux/actions/userActions";
import { setCustomContentLoaded } from "../redux/actions/userActions";
import { getSharedContent } from "../redux/actions/userActions";
import { setSharedContentLoaded } from "../redux/actions/userActions";
import { getLinkedProperties } from "../redux/actions/userActions";
import { setLinkedPropertiesLoaded } from "../redux/actions/userActions";

class PropertyDetailsInfoAutoLoader extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    const {
      PropertyId,
      CustomContentPropertyId,
      isComponentShared,
      SharedContentLoadedId,
      SharedPropertyContentProfileId,
      ItemLabel
    } = this.props;

    if (this.props.location.state) {
      const propertyId = this.props.location.state.propertyId;
      //  console.log("Condition 1 - content not already loaded (from link)");

      if (!isComponentShared) {
        // Condition 1 - content not already loaded (from link)
        //  console.log("Condition 1 - content not already loaded (from link)");
        if (this.props.location.state.propertyId !== CustomContentPropertyId) {
          this.props.setCustomContentLoaded(false, CustomContentPropertyId);

          this.props.getCustomContent(propertyId);
          if (
            SharedPropertyContentProfileId &&
            SharedPropertyContentProfileId !== SharedContentLoadedId
          ) {
            this.props.setSharedContentLoaded(false, SharedContentLoadedId);
            this.props.getSharedContent(
              SharedPropertyContentProfileId,
              ItemLabel
            );
          }
          if (!SharedPropertyContentProfileId) {
            this.props.setSharedContentLoaded(true, "");
          }

          this.props.setLinkedPropertiesLoaded(true);
        }

        // Condition 2 - content is already loaded (from link)
        if (this.props.location.state.propertyId === CustomContentPropertyId) {
          if (
            SharedPropertyContentProfileId &&
            SharedPropertyContentProfileId !== SharedContentLoadedId
          ) {
            this.props.setSharedContentLoaded(false, SharedContentLoadedId);
            this.props.getSharedContent(
              SharedPropertyContentProfileId,
              ItemLabel
            );
          }
          if (!SharedPropertyContentProfileId) {
            this.props.setSharedContentLoaded(true, "");
          }
          this.props.setLinkedPropertiesLoaded(true);
          this.props.setCustomContentLoaded(true, CustomContentPropertyId);
        }
      }

      if (isComponentShared) {
        // Condition 1 - content not already loaded (from link)
        if (SharedPropertyContentProfileId !== SharedContentLoadedId) {
          this.props.setSharedContentLoaded(false, SharedContentLoadedId);
          this.props.getSharedContent(
            SharedPropertyContentProfileId,
            ItemLabel
          );
        }

        // Condition 2 - content is already loaded (from link)
        if (SharedPropertyContentProfileId === SharedContentLoadedId) {
          this.props.setSharedContentLoaded(true, SharedContentLoadedId);
        }
      }
    }

    // Condition 3 - page loaded in browser
    if (!this.props.location.state) {
      //  console.log("Condition 3 - page loaded in browser");
      if (!isComponentShared && PropertyId) {
        this.props.setCustomContentLoaded(false, CustomContentPropertyId);
        this.props.setSharedContentLoaded(false, SharedContentLoadedId);
        this.props.getCustomContent(PropertyId);
        this.props.setLinkedPropertiesLoaded(true);
        if (SharedPropertyContentProfileId) {
          this.props.getSharedContent(
            SharedPropertyContentProfileId,
            ItemLabel
          );
        }
        if (!SharedPropertyContentProfileId) {
          this.props.setSharedContentLoaded(true, "");
        }
      }

      if (isComponentShared && SharedPropertyContentProfileId) {
        this.props.setSharedContentLoaded(false, SharedContentLoadedId);
        this.props.getSharedContent(SharedPropertyContentProfileId, ItemLabel);
      }
    }
  };

  render() {
    const {
      SaveStatus,
      userData,
      propertyData,
      systemContent,
      systemCategories,
      customContent,
      CustomContentLoaded,
      PropertyId,
      linkedPropertiesLoaded,
      //  SharedContentProfileId,
      sharedContent,
      SharedContentLoaded,
      AttachContentLoaded,
      isComponentShared,
      SharedPropertyContentProfileId
    } = this.props;

    const pathRoot = window.location.pathname.split("/")[1];

    const uniqueContentData = customContent
      ? customContent.filter((item) => {
          return !item.LinkedSharedContentProfile;
        })
      : null;
    // console.log("uniqueData", uniqueContentData);
    //  console.log("linkedPropertiesLoaded", linkedPropertiesLoaded)
    //  console.log("CustomContentLoaded", CustomContentLoaded)
    //  console.log("PropertyId", PropertyId)
    //  console.log("propertyData", propertyData)
    //  console.log("SharedContentLoaded", SharedContentLoaded)

    if (
      (!isComponentShared &&
        (!linkedPropertiesLoaded ||
          !CustomContentLoaded ||
          !PropertyId ||
          !propertyData)) ||
      !SharedContentLoaded ||
      (isComponentShared &&
        (!SharedContentLoaded || !SharedPropertyContentProfileId))
    ) {
      return <Loading />;
    }

    if (
      (!isComponentShared &&
        linkedPropertiesLoaded &&
        CustomContentLoaded &&
        PropertyId &&
        propertyData &&
        SharedContentLoaded) ||
      (isComponentShared &&
        SharedPropertyContentProfileId &&
        SharedContentLoaded)
    ) {
      return (

        <PropertyDetailsInfoAuto
          {...this.props} // Needed for breadcrumb
          userData={userData}
          PropertyId={PropertyId}
          propertyData={propertyData}
          customContent={uniqueContentData}
          sharedContent={sharedContent}
          systemContent={systemContent}
          SaveStatus={SaveStatus}
          pathRoot={pathRoot}
          SharedContentProfileId={SharedPropertyContentProfileId}
          systemCategories={systemCategories}
          AttachContentLoaded={AttachContentLoaded}
          isComponentShared={isComponentShared}
        />
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomContent: (propertyId) => dispatch(getCustomContent(propertyId)),
    setCustomContentLoaded: (payload, propertyId) =>
      dispatch(setCustomContentLoaded(payload, propertyId)),
    getSharedContent: (propertyId) => dispatch(getSharedContent(propertyId)),
    setSharedContentLoaded: (payload, propertyId) =>
      dispatch(setSharedContentLoaded(payload, propertyId)),
    setLinkedPropertiesLoaded: (payload) =>
      dispatch(setLinkedPropertiesLoaded(payload)),
    getLinkedProperties: (ItemLabel, userId) =>
      dispatch(getLinkedProperties(ItemLabel, userId))
  };
};

export default connect(null, mapDispatchToProps)(PropertyDetailsInfoAutoLoader);
