import React from "react";
import { cdnRootUrl } from "../constants.js";
import InfoItemMultiTextEditor from "./InfoItemMultiTextEditor";
import PDFPreview from "./PDFPreview";
import "../styles/InfoItem.css";
import "../styles/CheckBox.css";
import CameraIcon from "../icons/CameraIcon";
import VideoIcon from "../icons/VideoIcon";
import BinIcon from "../icons/BinIcon";
import DocumentIcon from "../icons/DocumentIcon";

export default class InfoItemSubContentItem extends React.Component {
  constructor(props) {
    super(props);

    // Props
    const { DetailLabel, ItemUniqueId, PropertyId, itemContent } = this.props;

    // Local Variables
    const propertyId = PropertyId ? PropertyId : null;

    var ItemData = itemContent;

    // Set Filenames so "null"s can be filtered out
    const imageFilename =
      ItemData && ItemData.DetailImage
        ? ItemData.DetailImage.substring(
            ItemData.DetailImage.lastIndexOf("/") + 1
          )
        : "";

    const VideoFilename =
      ItemData && ItemData.DetailVideo
        ? ItemData.DetailVideo.substring(
            ItemData.DetailVideo.lastIndexOf("/") + 1
          )
        : "";

    const documentFilename =
      ItemData && ItemData.DetailDocument
        ? ItemData.DetailDocument.substring(
            ItemData.DetailDocument.lastIndexOf("/") + 1
          )
        : "";

    var ContentType = "sub-details";

    var actionType = "update";
    if (ItemData && ItemData.action) {
      actionType = ItemData.action;
    }

    var DetailText = "";
    if (ItemData && ItemData.DetailText) {
      DetailText = ItemData.DetailText;
    }

    var DetailImage = null;
    if (ItemData && ItemData.DetailImage && imageFilename !== "null") {
      DetailImage = ItemData.DetailImage;
    }

    var DetailVideo = null;
    if (ItemData && ItemData.DetailVideo && VideoFilename !== "null") {
      DetailVideo = ItemData.DetailVideo;
    }

    var DetailDocument = null;
    if (ItemData && ItemData.DetailDocument && documentFilename !== "null") {
      DetailDocument = ItemData.DetailDocument;
    }

    var ButtonLink = "";
    if (ItemData && ItemData.ButtonLink) {
      ButtonLink = ItemData.ButtonLink;
    }
    var ButtonLabel = "";
    if (ItemData && ItemData.ButtonLabel) {
      ButtonLabel = ItemData.ButtonLabel;
    }

    // Declare initial State
    this.state = {
      propertyId: propertyId,
      detailLabel: DetailLabel,
      itemUniqueId: ItemUniqueId,
      contentType: ContentType,
      actionType: actionType,
      detailText: DetailText,
      detailButtonLink: ButtonLink,
      detailButtonLabel: ButtonLabel,
      detailImage: DetailImage,
      detailVideo: DetailVideo,
      detailDocument: DetailDocument,
      detailTextPrevState: DetailText,
      detailButtonLinkPrevState: ButtonLink,
      detailButtonLabelPrevState: ButtonLabel,
      detailImagePrevState: DetailImage,
      detailVideoPrevState: DetailVideo,
      detailDocumentPrevState: DetailDocument,
      actionTypePrevState: "update",
      selectedImageFile: null,
      selectedVideoFile: null,
      selectedDocumentFile: null,
      emptyImageField: {},
      emptyVideoField: {},
      emptyDocumentField: {},
      imageInputKey: null,
      videoInputKey: null,
      documentInputKey: null,
      showImage: true,
      showVideo: true,
      showDocument: true,
      displaySaveButton: true,
      imagePreviewLoading: true
    };
    this.onTextChange = this.onTextChange.bind(this);
    this.onImageUploaderChange = this.onImageUploaderChange.bind(this);
    this.onVideoUploaderChange = this.onVideoUploaderChange.bind(this);
    this.onDocumentUploaderChange = this.onDocumentUploaderChange.bind(this);
  }

  // Local functions
  setFileDeleteUrl = (fileType) => {
    const { itemIndex } = this.props;
    const cdnRootString = `${cdnRootUrl}/`;
    const detailFilePrevState =
      fileType === "image"
        ? this.state.detailImagePrevState
        : fileType === "video"
        ? this.state.detailVideoPrevState
        : fileType === "document"
        ? this.state.detailDocumentPrevState
        : null;
    const cdnStorageString = detailFilePrevState
      ? `${detailFilePrevState.slice(
          detailFilePrevState.indexOf(cdnRootString) + cdnRootString.length
        )}`
      : null;

    const deleteFileString = detailFilePrevState
      ? detailFilePrevState.split("/")[0] === "https:"
        ? cdnStorageString
        : `https:${detailFilePrevState}`
      : null;

    this.props.onStateUpdate("fileDeleteUrl", deleteFileString, itemIndex);
  };

  onFileDeleteButtonClick = (fileType) => {
    const { itemIndex, onRemoveContentClick } = this.props;
    onRemoveContentClick(itemIndex);
    this.setFileDeleteUrl(fileType);
  };

  onTextChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const id = target.id;

    this.setState({
      [name]: value
    });
    this.props.onStateUpdate(id, value, this.props.itemIndex);
  }

  onHtmlTextChange = (content) => {
    this.setState({
      detailText: content
    });
    this.props.onStateUpdate("DetailText", content, this.props.itemIndex);
  };

  onImagePreviewLoad = () => {
    this.setState({
      imagePreviewLoading: false
    });
  };

  onImageUploaderChange = (e) => {
    const { isComponentShared, SharedContentProfileId } = this.props;
    const FileFolder = isComponentShared
      ? "shared-content"
      : "property-content";
    const ItemLabel = this.state.detailLabel;
    const ItemId = isComponentShared
      ? SharedContentProfileId
      : this.state.propertyId;
    let imageFile = e.target.files[0];
    const imageFilename = `${FileFolder}/${ItemId}/${ItemLabel}-image_${imageFile.name}`;
    this.setState({
      imageFilename: imageFilename
    });
    this.props.onStateUpdate("fileName", imageFilename, this.props.itemIndex);
    const reader = new FileReader();
    reader.onload = this.handleImageReaderLoaded.bind(this);
    reader.readAsArrayBuffer(imageFile);
    // console.log("file", file);
  };

  handleImageReaderLoaded = (readerEvt) => {
    const { userId } = this.props;
    let imageReaderFile = readerEvt.target.result;
    let imageFilename = this.state.imageFilename;
    var imageBlob = new Blob([imageReaderFile], { type: "image/jpeg" });
    var imageUrlCreator = window.URL || window.webkitURL;
    var imageUrl = imageUrlCreator.createObjectURL(imageBlob);
    const detailImageLink = `${cdnRootUrl}/user/${userId}/${imageFilename}`;

    this.setState({
      selectedImageFile: imageReaderFile,
      showImage: true,
      imageFilePreview: imageUrl,
      detailImage: detailImageLink
    });
    this.props.onStateUpdate("fileData", imageReaderFile, this.props.itemIndex);
    this.props.onStateUpdate(
      "DetailImage",
      detailImageLink,
      this.props.itemIndex
    );
    if (detailImageLink !== this.state.detailImagePrevState) {
      this.setFileDeleteUrl("image");
    }
  };

  onVideoUploaderChange = (e) => {
    const { isComponentShared, SharedContentProfileId } = this.props;
    const FileFolder = isComponentShared
      ? "shared-content"
      : "property-content";
    const ItemLabel = this.state.detailLabel;
    const ItemId = isComponentShared
      ? SharedContentProfileId
      : this.state.propertyId;
    let videoFile = e.target.files[0];
    const videoFilename = `${FileFolder}/${ItemId}/${ItemLabel}-video_${videoFile.name}`;
    this.setState({
      videoFilename: videoFilename
    });
    this.props.onStateUpdate("fileName", videoFilename, this.props.itemIndex);
    const reader = new FileReader();
    reader.onload = this.handleVideoReaderLoaded.bind(this);
    reader.readAsArrayBuffer(videoFile);
    // console.log("file", file);
  };

  handleVideoReaderLoaded = (readerEvt) => {
    const { userId } = this.props;
    let videoReaderFile = readerEvt.target.result;
    let videoFilename = this.state.videoFilename;
    var videoBlob = new Blob([videoReaderFile], { type: "image/jpeg" });
    var videoUrlCreator = window.URL || window.webkitURL;
    var videoUrl = videoUrlCreator.createObjectURL(videoBlob);
    const detailVideoLink = `${cdnRootUrl}/user/${userId}/${videoFilename}`;

    this.setState({
      selectedVideoFile: videoReaderFile,
      showVideo: true,
      videoFilePreview: videoUrl,
      detailVideo: detailVideoLink
    });
    this.props.onStateUpdate("fileData", videoReaderFile, this.props.itemIndex);
    this.props.onStateUpdate(
      "DetailVideo",
      detailVideoLink,
      this.props.itemIndex
    );
    if (detailVideoLink !== this.state.detailVideoPrevState) {
      this.setFileDeleteUrl("video");
    }
  };

  onDocumentUploaderChange = (e) => {
    const { isComponentShared, SharedContentProfileId } = this.props;
    const FileFolder = isComponentShared
      ? "shared-content"
      : "property-content";
    const ItemLabel = this.state.detailLabel;
    const ItemId = isComponentShared
      ? SharedContentProfileId
      : this.state.propertyId;
    let documentFile = e.target.files[0];
    const documentFilename = `${FileFolder}/${ItemId}/${ItemLabel}-document_${documentFile.name}`;
    this.setState({
      documentFilename: `${FileFolder}/${ItemId}/${ItemLabel}-document_${documentFile.name}`
    });
    this.props.onStateUpdate(
      "fileName",
      documentFilename,
      this.props.itemIndex
    );

    const reader = new FileReader();
    reader.onload = this.handleDocumentReaderLoaded.bind(this);
    reader.readAsArrayBuffer(documentFile);
    // console.log("file", file);
  };

  handleDocumentReaderLoaded = (readerEvt) => {
    const { userId } = this.props;
    let documentReaderFile = readerEvt.target.result;
    let documentFilename = this.state.documentFilename;
    var documentBlob = new Blob([documentReaderFile], {
      type: "application/pdf"
    });
    var documentUrlCreator = window.URL || window.webkitURL;
    var documentUrl = documentUrlCreator.createObjectURL(documentBlob);
    const detailDocumentLink = `${cdnRootUrl}/user/${userId}/${documentFilename}`;

    this.setState({
      selectedDocumentFile: documentReaderFile,
      showDocument: true,
      documentFilePreview: documentUrl,
      detailDocument: detailDocumentLink
    });
    this.props.onStateUpdate(
      "fileData",
      documentReaderFile,
      this.props.itemIndex
    );
    this.props.onStateUpdate(
      "DetailDocument",
      detailDocumentLink,
      this.props.itemIndex
    );

    if (detailDocumentLink !== this.state.detailDocumentPrevState) {
      this.setFileDeleteUrl("document");
    }
  };

  handleStateChange = (payload) => {
    this.setState({
      displaySaveButton: payload
    });
  };

  render() {
    const { itemContent, divKey } = this.props;
    const contentType = itemContent.type;
    var ItemData = itemContent;

    // Set Filenames so "null"s can be filtered out
    const imageFilename =
      ItemData && ItemData.DetailImage
        ? ItemData.DetailImage.substring(
            ItemData.DetailImage.lastIndexOf("/") + 1
          )
        : "";

    const VideoFilename =
      ItemData && ItemData.DetailVideo
        ? ItemData.DetailVideo.substring(
            ItemData.DetailVideo.lastIndexOf("/") + 1
          )
        : "";

    const documentFilename =
      ItemData && ItemData.DetailDocument
        ? ItemData.DetailDocument.substring(
            ItemData.DetailDocument.lastIndexOf("/") + 1
          )
        : "";

    //  var DetailText = "";
    //  if (ItemData && ItemData.DetailText) {
    //    DetailText = ItemData.DetailText;
    //  }

    var DetailImage = null;
    if (ItemData && ItemData.DetailImage && imageFilename !== "null") {
      DetailImage = ItemData.DetailImage;
    }

    var DetailVideo = null;
    if (ItemData && ItemData.DetailVideo && VideoFilename !== "null") {
      DetailVideo = ItemData.DetailVideo;
    }

    var DetailDocument = null;
    if (ItemData && ItemData.DetailDocument && documentFilename !== "null") {
      DetailDocument = ItemData.DetailDocument;
    }

    return (
      <>
        {
          // Start
        }

        <div key={divKey}>
          {
            // Start image
          }
          {(this.state.detailImage || contentType === "image") && (
            <div className="infoitem-attachments-banner-container">
              <div className="infoitem-preview-container">
                <label className="infoitem-form-label"> </label>

                <input
                  type="file"
                  key={this.state.imageInputKey || ""} // Required to clear file input value
                  id={`file-upload-image-${this.state.itemUniqueId}`}
                  name={"DetailImage"}
                  className="infoitem-image-input"
                  placeholder={"Upload image"}
                  value={this.state.emptyImageField.name} // Needs to be set programatically to empty
                  onChange={this.onImageUploaderChange}
                />

                <label
                  htmlFor={`file-upload-image-${this.state.itemUniqueId}`}
                  className="custom-file-upload"
                >
                  {this.state.selectedImageFile && this.state.showImage && (
                    <img
                      className="infoitem-image"
                      // src={`data:image/jpeg;base64,${this.state.selectedImageFile}`}
                      src={this.state.imageFilePreview}
                      alt={this.state.detailLabel}
                    />
                  )}
                  {!this.state.selectedImageFile &&
                    this.state.showImage &&
                    DetailImage && (
                      <img
                        className="infoitem-image"
                        src={DetailImage}
                        alt={this.state.detailLabel}
                      />
                    )}
                  {
                    //{this.state.showImage &&
                    //  (this.state.selectedImageFile || DetailImage) && (
                  }
                  <button
                    className="infoitem-file-delete-button"
                    onClick={() => this.onFileDeleteButtonClick("image")}
                  >
                    <BinIcon className="infoitem-file-delete-icon" />
                  </button>

                  {((!this.state.selectedImageFile && !DetailImage) ||
                    !this.state.showImage) && (
                    <div className="custom-file-upload-inner-container">
                      <CameraIcon className="custom-file-upload-icon" />

                      <div className="custom-file-upload-text">
                        Upload image
                      </div>
                    </div>
                  )}
                </label>
              </div>
            </div>
          )}
          {
            // End image
          }
          {
            // Start video
          }
          {(this.state.detailVideo || contentType === "video") && (
            <div className="infoitem-attachments-banner-container">
              <div className="infoitem-preview-container">
                <label className="infoitem-form-label"> </label>

                {this.state.selectedVideoFile && this.state.showVideo && (
                  <>
                    <input
                      type="file"
                      key={this.state.videoInputKey || ""} // Required to clear file input value
                      id={`file-upload-video-${this.state.itemUniqueId}`}
                      name={"DetailVideo"}
                      className="infoitem-image-input"
                      placeholder={"Upload video"}
                      value={this.state.emptyVideoField.name} // Needs to be set programatically to empty
                      onChange={this.onVideoUploaderChange}
                      disabled
                    />
                    <label
                      htmlFor={`file-upload-video-${this.state.itemUniqueId}`}
                      className="custom-file-upload"
                    >
                      {
                        // {this.state.showVideo &&
                        //  (this.state.selectedVideoFile ||
                        // DetailVideo) && (
                      }
                      <button
                        className="infoitem-file-delete-button"
                        onClick={() => this.onFileDeleteButtonClick("video")}
                      >
                        <BinIcon className="infoitem-file-delete-icon" />
                      </button>
                      {
                        // )}
                      }

                      <video
                        className="infoitem-preview-video"
                        src={`data:video/webm;base64,${this.state.selectedVideoFile}`}
                        type="video/mp4"
                      >
                        This browser does not support video. Try Chrome or
                        Safari instead.
                      </video>
                    </label>
                  </>
                )}

                {!this.state.selectedVideoFile &&
                  this.state.showVideo &&
                  DetailVideo && (
                    <>
                      <input
                        type="file"
                        key={this.state.videoInputKey || ""} // Required to clear file input value
                        id={`file-upload-video-${this.state.itemUniqueId}`}
                        name={"DetailVideo"}
                        className="infoitem-image-input"
                        placeholder={"Upload video"}
                        value={this.state.emptyVideoField.name} // Needs to be set programatically to empty
                        onChange={this.onVideoUploaderChange}
                        disabled
                      />
                      <label
                        htmlFor={`file-upload-video-${this.state.itemUniqueId}`}
                        className="custom-file-upload"
                      >
                        {
                          //  {this.state.showVideo &&
                          //    (this.state.selectedVideoFile ||
                          //     DetailVideo) && (
                        }
                        <button
                          className="infoitem-file-delete-button"
                          onClick={() => this.onFileDeleteButtonClick("video")}
                        >
                          <BinIcon className="infoitem-file-delete-icon" />
                        </button>
                        {
                          //  )}
                        }

                        <video
                          className="infoitem-preview-video"
                          src={DetailVideo}
                          type="video/mp4"
                        >
                          This browser does not support video. Try Chrome or
                          Safari instead.
                        </video>
                      </label>
                    </>
                  )}

                {((!this.state.selectedVideoFile && !DetailVideo) ||
                  !this.state.showVideo) && (
                  <>
                    <input
                      type="file"
                      key={this.state.videoInputKey || ""} // Required to clear file input value
                      id={`file-upload-video-${this.state.itemUniqueId}`}
                      name={"DetailVideo"}
                      className="infoitem-image-input"
                      placeholder={"Upload video"}
                      value={this.state.emptyVideoField.name} // Needs to be set programatically to empty
                      onChange={this.onVideoUploaderChange}
                    />
                    <label
                      htmlFor={`file-upload-video-${this.state.itemUniqueId}`}
                      className="custom-file-upload"
                    >
                      <button
                        className="infoitem-file-delete-button"
                        onClick={() => this.onFileDeleteButtonClick("video")}
                      >
                        <BinIcon className="infoitem-file-delete-icon" />
                      </button>
                      <div className="custom-file-upload-inner-container">
                        <VideoIcon className="custom-file-upload-icon" />

                        <div className="custom-file-upload-text">
                          Upload video
                        </div>
                      </div>
                    </label>
                  </>
                )}
              </div>
            </div>
          )}
          {
            // End video
          }
          {
            // Start pdf
          }
          {(this.state.detailDocument || contentType === "pdf") && (
            <div className="infoitem-attachments-banner-container">
              <div className="infoitem-preview-container">
                <label className="infoitem-form-label"> </label>
                <input
                  type="file"
                  key={this.state.documentInputKey || ""} // Required to clear file input value
                  id={`file-upload-document-${this.state.itemUniqueId}`}
                  name={"DetailDocument"}
                  className="infoitem-image-input"
                  placeholder={"Upload document"}
                  value={this.state.emptyDocumentField.name} // Needs to be set programatically to empty
                  onChange={this.onDocumentUploaderChange}
                />

                <label
                  htmlFor={`file-upload-document-${this.state.itemUniqueId}`}
                  className="custom-file-upload"
                >
                  {this.state.selectedDocumentFile && this.state.showDocument && (
                    <PDFPreview
                      //    DetailDocument={`data:image/jpeg;base64,${this.state.selectedDocumentFile}`}
                      //    DetailDocument={this.state.selectedDocumentFile}
                      DetailDocument={this.state.documentFilePreview}
                    />
                  )}
                  {!this.state.selectedDocumentFile &&
                    this.state.showDocument &&
                    DetailDocument && (
                      <PDFPreview DetailDocument={DetailDocument} />
                    )}
                  {
                    // {this.state.showDocument &&
                    //  (this.state.selectedDocumentFile ||
                    //   DetailDocument) && (
                  }
                  <button
                    className="infoitem-file-delete-button"
                    onClick={() => this.onFileDeleteButtonClick("document")}
                  >
                    <BinIcon className="infoitem-file-delete-icon" />
                  </button>
                  {
                    // )}
                  }

                  {((!this.state.selectedDocumentFile && !DetailDocument) ||
                    !this.state.showDocument) && (
                    <div className="custom-file-upload-inner-container">
                      <DocumentIcon className="custom-file-upload-icon" />

                      <div className="custom-file-upload-text">Upload PDF</div>
                    </div>
                  )}
                </label>
              </div>
            </div>
          )}
          {
            // End pdf
          }
          {
            // Start text
          }
          {(this.state.detailText || contentType === "text") && (
            <>
              <div className="infoitem-text-label-button-container">
                <div className="infoitem-texteditor-label">
                  Add a text description
                </div>
                <button
                  className="infoitem-text-delete-button"
                  onClick={() => this.onFileDeleteButtonClick("text")}
                >
                  <BinIcon className="infoitem-text-delete-icon" />
                </button>
              </div>

              <InfoItemMultiTextEditor
                htmlText={this.state.detailText}
                placeHolder={"Enter your text here"}
                onHtmlTextChange={this.onHtmlTextChange}
              />
            </>
          )}
          {
            // End text
          }
          {
            // Start button
          }
          {(this.state.detailButtonLabel ||
            this.state.detailButtonLink ||
            contentType === "button") && (
            <div className="infoitem-form-inner-container-textarea">
              <div className="infoitem-text-label-button-container">
                <div className="infoitem-texteditor-label">
                  Add a button link
                </div>
                <button
                  className="infoitem-text-delete-button"
                  onClick={() => this.onFileDeleteButtonClick("button")}
                >
                  <BinIcon className="infoitem-text-delete-icon" />
                </button>
              </div>

              <input
                className="infoitem-text-input"
                id={"ButtonLabel"}
                name={"detailButtonLabel"}
                placeholder={"Enter button text"}
                value={this.state.detailButtonLabel}
                onChange={this.onTextChange}
              />

              <input
                className="infoitem-text-input"
                id={"ButtonLink"}
                name={"detailButtonLink"}
                placeholder={"Enter button link"}
                value={this.state.detailButtonLink}
                onChange={this.onTextChange}
              />
            </div>
          )}
          {
            // End button
          }
        </div>

        {
          // End
        }
      </>
    );
  }
}
