import React from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import CrossIcon from "../icons/CrossIcon";
import InfoItemMultiTwo from "./InfoItemMultiTwo";
import CTAButtonIconLeft from "./CTAButtonIconLeft";
import CirclePlusIcon from "../icons/CirclePlusIcon";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItemMultiPopup.css";

class InfoItemMultiPopup extends React.Component {
  state = {
    contentEdited: false,
    saveChangesPopupVisible: false
  };

  componentDidMount = () => {
    this.setState({
      saveChangesPopupVisible: false
    });
  };

  // componentDidUpdate = (prevProps, prevState) => {
  // if (this.props.customContent !== prevProps.customContent) {
  //  this.setState({
  //    saveChangesPopupVisible: false
  //  });
  //  }
  // };

  setContentEdited = (payload) => {
    this.setState({
      contentEdited: payload
    });
  };

  // shouldComponentUpdate = () => {
  //if (!this.state.contentEdited) {
  // return true
  //}
  // }

  // controlPopupCloseFunction = (close) => {
  //  if (this.state.contentEdited) {
  //    close()
  //  }
  // else {
  //   close()
  //  }
  // }

  showSaveChangesPopup = () => {
    this.setState({
      saveChangesPopupVisible: true
    });
  };

  hideSaveChangesPopup = () => {
    this.setState({
      saveChangesPopupVisible: false
    });
  };

  render() {
    const {
      ItemData,
      ButtonText,
      SharedContentProfileId,
      PropertyId,
      SaveStatus,
      userId,
      propertyData,
      systemContent,
      customContent,
      sharedContent,
      isComponentShared,
      CategoryLabel,
      ItemType,
      sharedLocalAreaContentProfileData,
      customPlaceContent,
      ItemLabel
    } = this.props;

    // console.log("ItemType", ItemType)
    //  console.log("ItemData", ItemData);
    // console.log("SaveStatus", SaveStatus);

    const ItemUniqueId = ItemData ? ItemData._id : "new-item";

    return (
      <div>
        <Popup
          modal
          lockScroll={true}
          contentStyle={{
            border: "none",
            padding: "5px",
            margin: "auto",
            width: "auto",
            height: "auto",
            overflowY: "scroll",
            overflowX: "scroll"
          }}
          trigger={
            ItemType === "edit" ? (
              <button
                className="infoitemmulti-popup-modal-open-button-round"
              >
                <div className="infoitemmulti-popup-modal-open-button-round-text">
                  {ButtonText}
                </div>
              </button>
            ) : ItemType === "add" ? (
              <CTAButtonIconLeft
                // onClick={this.onAddItem}
                Icon={CirclePlusIcon}
                Heading={ButtonText}
                Style={"solid"} // "solid" or "inverted"
              />
            ) : null
          }
        >
          {(close) => (
            <>
              {SaveStatus === `${ItemUniqueId}-success` &&
                (close(),
                //  console.log("close run"),
                // console.log("SaveStatus", SaveStatus),
                this.props.setSaveStatus(`${ItemUniqueId}-idle`),
                this.setState({
                  saveChangesPopupVisible: false
                }))}

              <div className="infoitemmulti-popup-modal-box">
                {!this.state.saveChangesPopupVisible && (
                  <div className="infoitemmulti-popup-modal-close-button-wrapper">
                    <button
                      className="infoitemmulti-popup-modal-close-button"
                      onClick={
                        this.state.contentEdited
                          ? //  this.controlPopupCloseFunction(close)// : close
                            //this.state.contentEdited ?
                            //   this.showSaveChangesPopup(true) : null
                            this.showSaveChangesPopup
                          : close
                      }
                    >
                      <div className="infoitemmulti-popup-modal-close-button-icon-wrapper">
                        <div className="infoitemmulti-popup-modal-close-button-icon">
                          <CrossIcon />
                        </div>
                      </div>
                    </button>
                  </div>
                )}
            
                <div className="infoitemmulti-popup-modal-content-wrapper">
                  <InfoItemMultiTwo
                    //   key={ItemData ? ItemData._id : "new-item"}
                    ItemLabel={ItemLabel}
                    propertyData={propertyData}
                    propertyDetails={propertyData}
                    PropertyId={PropertyId}
                    systemContent={systemContent}
                    customContent={customContent}
                    sharedContent={sharedContent}
                    customPlaceContent={customPlaceContent}
                    SaveStatus={SaveStatus}
                    userId={userId}
                    DetailLabel={ItemData ? ItemData.DetailLabel : "custom"}
                    IsCustomLabel={ItemData ? ItemData.IsCustomLabel : true}
                    ItemUniqueId={ItemData ? ItemUniqueId : "new-item"}
                    InfoType={ItemData ? "system" : "custom"}
                    ContentType={"place"}
                    isComponentShared={isComponentShared}
                    SharedContentProfileId={SharedContentProfileId}
                    PassedCategoryLabel={CategoryLabel}
                    popUpClose={close}
                    sharedLocalAreaContentProfileData={
                      sharedLocalAreaContentProfileData
                    }
                    setContentEdited={this.setContentEdited}
                    contentEdited={this.state.contentEdited}
                    saveChangesPopupVisible={this.state.saveChangesPopupVisible}
                    showSaveChangesPopup={this.showSaveChangesPopup}
                    hideSaveChangesPopup={this.hideSaveChangesPopup}
                  />
                </div>
              </div>
            </>
          )}
        </Popup>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemMultiPopup);
