import React from "react";
//import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
//import ChevronRightIcon from "../icons/ChevronRightIcon";
import "../styles/ConversationListItem.css";
import "../styles/ButtonBanners.css";
import formatDateLabel from "../functions/formatDateLabel";
import { updateConversationStatus } from "../data/updateConversationStatus";

export default function ConversationListItem(props) {
  // Initiate State & Variables
  const dispatch = useDispatch();
  const { ConversationData, Target, PropertyId, From, Date } = props;
  const propertyData = useSelector(
    (state) => state.userReducer.user.linkedProperties,
    shallowEqual
  );
  const conversationId = ConversationData._id;
  const userId = ConversationData.LinkedUser;
  const propertyId = ConversationData.LinkedProperty;
  const status = ConversationData.Status;
  const thisProperty = propertyData.filter(
    (item) => item._id === propertyId
  )[0];
  const propertyManagers = useSelector(
    (state) => state.userReducer.user.linkedPropertyManagers,
    shallowEqual
  );

  const assignedPropertyManager = propertyManagers
    ? propertyManagers.filter(
        (item) => item._id === ConversationData.LinkedPropertyManager
      )[0]
    : {};

  const messageData = useSelector(
    (state) => state.userReducer.user.messageData,
    shallowEqual
  );

  const messageDataFiltered =
    messageData && ConversationData
      ? messageData.filter((item) => item.LinkedConversation === conversationId)
      : 0;

  const unreadMessageCount =
    messageData && ConversationData
      ? messageData.filter(
          (item) =>
            item.LinkedConversation === conversationId &&
            item.InboundMessage &&
            !item.ReadByRecipient
        )
      : 0;
  const conversationListItemClassName =
    unreadMessageCount.length > 0
      ? "conversation-list-item-unread"
      : "conversation-list-item-read";

  // Local Functions
  const handleClick = () => {
    props.history.push({
      pathname: "/" + Target,
      state: { propertyId: PropertyId, from: From }
    });
  };

  const onStatusChange = (dispatch, conversationMessageId, payload, userId) => {
    updateConversationStatus(
      dispatch,
      conversationMessageId,
      payload,
      userId,
      messageDataFiltered
    );
  };

  // const InnerWrapperStyle = isLoading
  //   ? "conversation-list-item-main-image-wrapper-loading"
  //   : "";
  // const ImageStyle = isLoading
  //   ? "conversation-list-item-main-image-loading conversation-list-item-main-image-loading-animate"
  //  : "conversation-list-item-main-image";

  const statusOptions = [
    {
      id: "pending",
      nameLabel: "Pending",
      colour: "orange"
    },
    {
      id: "in-progress",
      nameLabel: "In Progress",
      colour: "green"
    },
    {
      id: "resolved",
      nameLabel: "Resolved",
      colour: "green"
    },
    {
      id: "archived",
      nameLabel: "Archived",
      colour: "grey"
    }
  ];

  const formattedDate = formatDateLabel(Date);
  //console.log("ConversationData.Status", ConversationData.Status)
  if (ConversationData && thisProperty) {
    return (
      <>
        {
          // List Item open
        }
        <div className={conversationListItemClassName}>
          {
            // Status Container open
          }
          <div className="conversation-list-item-status-container">
            <select
              id={`select-status-${ConversationData._id}`}
              className="conversation-list-item-select"
              value={status}
              onChange={(e) =>
                onStatusChange(dispatch, conversationId, e.target.value, userId)
              }
            >
              {statusOptions.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.nameLabel}
                </option>
              ))}
            </select>
          </div>
          {
            // Status Container closed (div above)
          }

          {
            // Content Container open
          }
          <div
            className="conversation-list-item-content-container"
            onClick={handleClick}
          >
            {
              // Heading Container open
            }
            <div
              className="conversation-list-item-heading-container"
              onClick={handleClick}
            >
              <div className="conversation-list-item-heading">
                {`${ConversationData.Title} - ${thisProperty.PropertyTitle}`}
              </div>
              <div className="conversation-list-item-date">{formattedDate}</div>
            </div>
            {
              // Heading Container closed (div above)
            }

            {
              // Body Container open
            }

            <div className="conversation-list-item-body-container">
              {
                // Left Container open
              }
              <div className="conversation-list-item-left-container">
                <div className="conversation-list-item-subheading">{`From: ${ConversationData.CreatorFirstname} ${ConversationData.CreatorLastname}`}</div>
                <div className="conversation-list-item-subheading">{`Assigned to: ${assignedPropertyManager.PropertyMgrEmail}`}</div>
              </div>

              {
                // Left Container closed (div above)
              }

              {
                // Right Container open
              }

              <div className="conversation-list-item-right-container">
                <div
                  className="conversation-list-number-container"
                  onClick={handleClick}
                >
                  {unreadMessageCount.length > 0 && (
                    <div className="conversation-list-number-badge">
                      {unreadMessageCount.length}
                    </div>
                  )}
                </div>
              </div>

              {
                // Right Container closed (div above)
              }
            </div>
            {
              // Body Container closed (div above)
            }
          </div>
          {
            // Content Container closed
          }
        </div>
        {
          // List Item close (div above)
        }
      </>
    );
  } else {
    return <></>;
  }
}
