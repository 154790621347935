import React from "react";

import "../styles/BackButton.css";
//import PreviewButton from "./PreviewButton";
// import ChevronRightIcon from "../icons/ChevronRightIcon";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";

export default class BackButton extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleBack = () => {
    const { PreviousPagePath, propertyId } = this.props;
    // this.props.history.push(PreviousPagePath);

    this.props.history.push({
      pathname: PreviousPagePath,
      state: { propertyId: propertyId }
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <>
        <div className="back-button-container" onClick={this.handleBack}>
          <div className="back-button-chevron-icon-wrapper-left">
            <ChevronLeftIcon className="back-button-chevron-icon" />
          </div>
        </div>
      </>
    );
  }
}
