import React from "react";

import "../styles/Breadcrumb.css";
//import PreviewButton from "./PreviewButton";
// import ChevronRightIcon from "../icons/ChevronRightIcon";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";

export default class Breadcrumb extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleBack = () => {
    const { PreviousPagePath, propertyId } = this.props;
    // this.props.history.push(PreviousPagePath);

    this.props.history.push({
      pathname: PreviousPagePath,
      state: { propertyId: propertyId }
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { PreviousPageTitle } = this.props;
    return (
      <>
        <div className="breadcrumb-container">
          <div className="breadcrumb-chevron-icon-wrapper-left">
            <ChevronLeftIcon className="breadcrumb-chevron-icon" />
          </div>
          <div
            className="breadcrumb-previous-section"
            onClick={this.handleBack}
          >
            {PreviousPageTitle && (
              <>
                <div className="breadcrumb-link">{PreviousPageTitle}</div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
