import React from "react";
//import ReactGA from "react-ga4";
//import { googleAnalytics4Id } from "../constants.js";
import { connect } from "react-redux";
import { createCompany } from "../redux/actions/userActions";
import "../styles/InfoItem.css";

//ReactGA.initialize(googleAnalytics4Id);

class CreateCompanyModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {},
      redirect: false
    };
  }

  componentDidMount = () => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    var payloadB = { ...this.state.payload };
    payloadB[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA, ...payloadB }
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { ItemLabel, userId } = this.props;
    this.props.createCompany(this.state.payload, ItemLabel, userId);
    this.setState(() => ({
      redirect: true
    }));
  };

  onRedirect = () => {
    //  this.props.history.push(
    //  `/manager-profile/${this.state.payload.ManagerVanityID}`
    //  );

    this.props.history.push({
      pathname: "/company-profile"
      //  state: { ManagerVanityID: this.state.payload.ManagerVanityID }
    });
  };

  render() {
    const { SaveStatus, ItemLabel, InputLabelA, PayloadA, ValueA } = this.props;
    // console.log(this.state);
    if (this.state.redirect) {
      this.onRedirect();
    }

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-form-container">
            <form className="infoitem-form" onSubmit={this.onSubmit}>
              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label"> {InputLabelA} </label>
                <input
                  type="text"
                  name={PayloadA}
                  className="infoitem-text-input"
                  placeholder={`Enter ${InputLabelA}`}
                  defaultValue={ValueA}
                  value={this.state.payload.name}
                  onChange={this.onChange}
                  required
                />
              </div>

              <div className="infoitem-form-inner-container-left">
                <input
                  type="submit"
                  className="infoitem-save-button"
                  value={
                    SaveStatus === `${ItemLabel}-fetching`
                      ? "Saving..."
                      : "Continue"
                  }
                />
              </div>
            </form>
          </div>
        </div>

        <div className="infoitem-section-break-wrapper">
          <div className="infoitem-section-break"></div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createCompany: (managerInfo, ItemLabel, userId) =>
    dispatch(createCompany(managerInfo, ItemLabel, userId))
});

export default connect(null, mapDispatchToProps)(CreateCompanyModule);
