import React from "react"; //, { useEffect }
//import LazyLoad from "react-lazyload";
//import { useSelector, shallowEqual } from "react-redux";
//import InfoMenuImage from "../../components/InfoMenuImage";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import "../styles/FormFileListItem.css";
//import TranslateText from "../../components/TranslateText";
//import { forceCheck } from "react-lazyload";

export default class FormFileListItem extends React.Component {
  shouldComponentUpdate = (nextProps, nextState) => {
    // console.log(nextProps, nextState);
    //  console.log(this.props, this.state);
    if (nextProps.Heading !== this.props.Heading) {
      return true;
    } else return false;
  };

  render() {
    // Redirect to a page created from button id and property id upon button click
    // const handleClick = () => {};

    // const { ItemId, Heading, DetailImage, AltText } = props;
    const { Heading } = this.props;
    console.log("render", Heading);
    return (
      <div className="file-list-item-checkmark-container">
        <div className="file-list-item-checkmark-icon-container-checked">
          <CheckmarkIcon className="file-list-item-checkmark-icon-checked" />
        </div>
        <div className="file-list-item-checkmark-text-container">
          <div className="file-list-item-text">{Heading}</div>
        </div>
      </div>
    );
  }
}
