import React from "react";
import { connect } from "react-redux";
import { updateUser } from "../redux/actions/userActions";
import { updateCompany } from "../redux/actions/userActions";
import { updateManager } from "../redux/actions/userActions";
import { updateProperty } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import InfoItemCheckMark from "./InfoItemCheckMark";
import InfoItemPopup from "./InfoItemPopup";
import "../styles/InfoItem.css";
import "../styles/ImageRadioButtonBanner.css";

class InfoItemImageSelector extends React.Component {
  state = {
    payload: {},
    imageChecked:
      this.props.DataType === "managers"
        ? this.props.propertyDetails.LinkedPropertyMgr
        : ""
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA }
    }));
  };

  onEdit = (e) => {
    e.preventDefault();
    const { ItemLabel } = this.props;
    this.props.setSaveStatus(`${ItemLabel}-editing`);
  };

  onCancel = (e) => {
    e.preventDefault();
    const { ItemLabel } = this.props;
    this.props.setSaveStatus(`${ItemLabel}-idle`);
    //  var ele = document.querySelectorAll("input[type=radio]");
    //  for(var i=0;i<ele.length;i++){
    //    ele[i].checked = false;
    //}
  };

  onButtonClick = () => {
    this.props.history.push("/property-managers");
  };

  onSubmit = (e) => {
    const {
      ItemLabel,
      ItemType,
      userId,
      companyId,
      managerId,
      propertyId
    } = this.props;

    e.preventDefault();
    if (ItemType === "company") {
      this.props.updateCompany(this.state.payload, companyId, ItemLabel);
    }
    if (ItemType === "manager") {
      this.props.updateManager(this.state.payload, managerId, ItemLabel);
    }
    if (ItemType === "property") {
      this.props.updateProperty(this.state.payload, propertyId, ItemLabel);
    }
    if (ItemType === "user") {
      this.props.updateUser(this.state.payload, userId, ItemLabel);
    }
  };

  render() {
    const {
      SaveStatus,
      ItemList,
      DataType,
      ItemLabel,
      ItemHeading,
      ItemDescription,
      ValueA,
      IsChecked,
      IsRequired,
      TrueText,
      FalseText,
      PayloadA,
      DefaultImage,
      DefaultId,
      systemContent,
      companyData,
      managerData,
      userData,
      propertyData
    } = this.props;

    // console.log(ItemList)
    //  Extract placeholder details from SystemContent
    const propertyManagerPlaceholder = systemContent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "property-manager-placeholder";
      }
    );

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={IsChecked}
                IsRequired={IsRequired}
                IsCustomContent={ValueA ? true : false}
                ItemHeading={ItemHeading}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "hide"
                : "show"
            }
          >
            {ValueA && (
              <>
                <label className="infoitem-form-label"> {TrueText} </label>
                <div className="infoitem-image-container">
                  <img
                    className="infoitem-image-circle"
                    src={
                      DataType === "managers" && DefaultImage
                        ? DefaultImage
                        : propertyManagerPlaceholder[0].DetailImage
                    }
                    alt={ItemLabel}
                  />
                </div>
              </>
            )}
            {!ValueA && FalseText}
          </div>

          {(SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching`) && (
            <div className="infoitem-value">{ItemDescription}</div>
          )}

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "show"
                : "hide"
            }
          >
            <div className="infoitem-form">
              <div
                className="radio-image-banners-container"
                onChange={this.onChange}
              >
                {ItemList.map((item) => (
                  <div
                    key={`${ItemLabel}-${item._id}`}
                    className={"radio-image-banner-container"}
                  >
                    <label htmlFor={`${ItemLabel}-${item._id}`}>
                      <div className="radio-image-banner">
                        <div className="radio-image-banner-radio-and-heading-inner-container">
                          <div className="radio-input-container">
                            <input
                              className="radio-image-banner-input"
                              type="radio"
                              defaultChecked={item._id === DefaultId}
                              name={PayloadA}
                              id={`${ItemLabel}-${item._id}`}
                              value={
                                DataType === "themes"
                                  ? item.ThemeLabel
                                  : DataType === "managers"
                                  ? item._id
                                  : ""
                              }
                            />
                          </div>

                          <img
                            className="radio-image-banner-main-image"
                            src={
                              DataType === "themes"
                                ? item.CategoryImage
                                : DataType === "managers" &&
                                  item.PropertyMgrCoverImage
                                ? item.PropertyMgrCoverImage
                                : DataType === "managers" &&
                                  !item.PropertyMgrCoverImage
                                ? propertyManagerPlaceholder[0].DetailImage
                                : null
                            }
                            alt={"Property Manager"}
                          />

                          <div className="radio-image-banner-subheading">
                            {DataType === "themes"
                              ? item.CategoryTitle
                              : DataType === "managers" &&
                                item.PropertyMgrFirstname &&
                                item.PropertyMgrLastname
                              ? item.PropertyMgrFirstname +
                                " " +
                                item.PropertyMgrLastname
                              : DataType === "managers" &&
                                item.PropertyMgrFirstname &&
                                !item.PropertyMgrLastname
                              ? item.PropertyMgrFirstname
                              : DataType === "managers" &&
                                !item.PropertyMgrFirstname &&
                                !item.PropertyMgrLastname
                              ? propertyManagerPlaceholder[0].DetailTitle
                              : ""}
                          </div>
                        </div>

                        <div className="edit-button-container">
                          <InfoItemPopup
                            ButtonText={"Edit"}
                            ItemType={"edit"}
                            ItemData={item}
                            ItemLabel={ItemLabel}
                            SharedContentProfileId={"SharedContentProfileId"}
                            //  PropertyId={propertyId}
                            SaveStatus={SaveStatus}
                            propertyData={propertyData}
                            managerData={managerData}
                            companyData={companyData}
                            userData={userData}
                            //  userId={userId}
                            //  propertyData={propertyDetails}
                            // systemContent={systemContent}
                            // customContent={customContent}
                            // sharedContent={sharedContent}
                            isComponentShared={false}
                            CategoryLabel={"CategoryLabel"}
                            //  sharedLocalAreaContentProfileData={
                            //   sharedLocalAreaContentProfileData
                            //    }
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>

              <div className="infoitem-form-inner-container-left">
                <button
                  onClick={this.onSubmit}
                  className="infoitem-save-button"
                >
                  {SaveStatus === `${ItemLabel}-fetching`
                    ? "Saving..."
                    : "Save"}
                </button>

                {
                  //     <div
                  //    className="infoitem-inline-edit-button"
                  //    onClick={this.onButtonClick}
                  //  >
                  //    {"Edit property manager details >"}
                  //  </div>
                }
              </div>
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userInfo, userId, ItemLabel) =>
    dispatch(updateUser(userInfo, userId, ItemLabel)),
  updateManager: (managerInfo, managerId, ItemLabel) =>
    dispatch(updateManager(managerInfo, managerId, ItemLabel)),
  updateCompany: (companyInfo, companyId, ItemLabel) =>
    dispatch(updateCompany(companyInfo, companyId, ItemLabel)),
  updateProperty: (propertyInfo, propertyId, ItemLabel) =>
    dispatch(updateProperty(propertyInfo, propertyId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemImageSelector);
