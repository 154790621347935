import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "../styles/PDFPreview.css";

export default class PDFPreview extends Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }

  render() {
    return (
      <>
        <Document
          className="document-viewer-document"
          file={this.props.DetailDocument}
        >
          <div className="document-viewer-page-wrapper">
            <Page
              height={130}
              pageNumber={1}
              //  style={{ marginLeft: '0' }}
            />
          </div>
        </Document>
      </>
    );
  }
}
