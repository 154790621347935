import { combineReducers } from "redux";
import userReducer from "./userReducer";
import fetchReducer from "./fetchReducer";
import conversationsReducer from "./conversationsReducer";

const rootReducer = combineReducers({
  userReducer,
  fetchReducer,
  conversationsReducer
});

export default rootReducer;
