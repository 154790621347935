import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ReactLoading from "react-loading";
//import { useHistory } from "react-router-dom";
import "../styles/LoadingButton.css";
import { clickLogout } from "../redux/actions/userActions";
import "../styles/LogoutButton.css";

export const LogoutButton = (props) => {
  // const history = props.history;

  const dispatch = useDispatch();
  const { StaticText, history } = props;
  const [loggingOut, setLoggingOut] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    const { pusher } = props;
    if (pusher) {
      pusher.disconnect();
    }
    setLoggingOut(true);
    dispatch(clickLogout(history));
  };

  return (
    <>
      <button className="logout-button" onClick={handleClick}>
        {!loggingOut && <div>{StaticText}</div>}
        {loggingOut && (
          <>
            <div className="logout-button-spinner">
              <ReactLoading
                type={"spin"}
                color={"white"}
                height={"40%"}
                width={"40%"}
              />
            </div>
          </>
        )}
      </button>
    </>
  );
};
