import React from "react";
import { connect } from "react-redux";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItem.css";
import InfoItemMapAuto from "./InfoItemMapAuto";
import InfoItemMapManual from "./InfoItemMapManual";
import InfoItemCheckMark from "./InfoItemCheckMark";
import { googleMapsApiKey } from "../constants.js";
import Script from "react-load-script";
import MapPins from "../pins/MapPins.js";

class InfoItemMap extends React.Component {
  constructor(props) {
    super(props);
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
  }

  state = {
    auto: true,
    payload: {},
    inputKey: null,
    city: "",
    query: "",
    showMap: this.props.propertyDetails.GeoLocationFormattedAddress
      ? true
      : false,
    lat: this.props.propertyDetails.GeoLocationLat,
    lng: this.props.propertyDetails.GeoLocationLng
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.propertyDetails.GeoLocationLat !==
        this.props.propertyDetails.GeoLocationLat ||
      prevProps.propertyDetails.GeoLocationLng !==
        this.props.propertyDetails.GeoLocationLng
    ) {
      this.setState({
        lat: this.props.propertyDetails.GeoLocationLat,
        lng: this.props.propertyDetails.GeoLocationLng
      });
      this.createMap();
    }
  }

  switchChildComponent = () => {
    this.setState({
      auto: !this.state.auto
    });
  };

  handleScriptLoad = () => {
    if (this.state.showMap) {
      this.createMap();
    }
  };

  createMap = () => {
    this.googlemap = new window.google.maps.Map(
      document.getElementById("googlemap"),
      {
        zoom: 16,
        center: {
          lat: this.props.propertyDetails.GeoLocationLat,
          lng: this.props.propertyDetails.GeoLocationLng
        },
        disableDefaultUI: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        gestureHandling: "none",
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "on"
              }
            ]
          }
        ]
      }
    );

    this.marker = new window.google.maps.Marker({
      position: this.googlemap.getCenter(),
      map: this.googlemap,

      icon: {
        url: MapPins.home,
        scaledSize: new window.google.maps.Size(40, 54.5)
      },
      optimized: false,
      clickable: false,
      draggable: false
    });
  };

  onEdit = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-editing`);
  };

  onCancel = (e) => {
    e.preventDefault();
    const { ItemLabel } = this.props;
    this.props.setSaveStatus(`${ItemLabel}-idle`);
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      ItemHeading,
      ItemDescription,
      ValueA,
      IsChecked,
      IsRequired,
      propertyDetails,
      propertyId,
      IsNewCustomItem,
      FalseText
    } = this.props;
    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={IsChecked}
                IsRequired={IsRequired}
                IsCustomContent={ValueA ? true : false}
                ItemHeading={ItemHeading}
                IsNewCustomItem={IsNewCustomItem}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div className="infoitem-value">
            {SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching`
              ? `${ItemDescription}`
              : IsChecked ? ValueA : FalseText}
          </div>


          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "hide"
                : "show"
            }
          >
            <>
              <Script
                url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
                onLoad={this.handleScriptLoad}
              />
              {IsChecked && (
                <>
                  <div
                    id="googlemap"
                    style={{ width: "300px", height: "300px" }}
                  />
                  <div style={{ height: "15px" }} />
                </>
              )}
            </>
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "show"
                : "hide"
            }
          >
            <div className="infoitem-form-container">
              <div className="infoitem-form">
                {this.state.auto && (
                  <InfoItemMapAuto
                    propertyId={propertyId}
                    ItemLabel={ItemLabel}
                    SaveStatus={SaveStatus}
                    propertyDetails={propertyDetails}
                    InputLabelA={"Property address"}
                  />
                )}

                {!this.state.auto && (
                  <InfoItemMapManual
                    propertyId={propertyId}
                    ItemLabel={ItemLabel}
                    SaveStatus={SaveStatus}
                    propertyDetails={propertyDetails}
                    InputLabelA={"Property address"}
                  />
                )}

                <div className="infoitem-form-inner-container-full">
                  <div
                    className="infoitem-inline-edit-button"
                    onClick={this.switchChildComponent}
                  >
                    {this.state.auto && <>Switch to enter manually</>}
                    {!this.state.auto && <>Switch to automatic lookup</>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemMap);
