import React, { useState } from "react";
import { connect } from "react-redux";
import { updateCompany } from "../redux/actions/userActions";
import { updateProperty } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import { HexColorPicker, HexColorInput } from "react-colorful";
import InfoItemCheckMark from "./InfoItemCheckMark";
import "react-colorful/dist/index.css";
import "../styles/ColourPicker.css";
import "../styles/InfoItem.css";

function InfoItemThemeColours(props) {
  const {
    SaveStatus,
    ItemLabel,
    ItemHeading,
    ItemDescription,
    InputLabelA,
    ValueA,
    IsChecked,
    IsRequired,
    FalseText,
    InputLabelB,
    ValueB,
    IsNewCustomItem
  } = props;

  const [colorA, setColorA] = useState(ValueA);
  const [colorB, setColorB] = useState(ValueB);
  var companyInfo = { MgmtCoHexPrimary: colorA, MgmtCoHexSecondary: colorB };
  var companyLoadingInfo = { BackgroundHex: colorA, FontHex: colorB };
  var propertyInfo = {
    PropertyHexPrimary: colorA,
    PropertyHexSecondary: colorB
  };

  const onEdit = (e) => {
    e.preventDefault();
    props.setSaveStatus(`${ItemLabel}-editing`);
  };

  const onCancel = (e) => {
    e.preventDefault();
    props.setSaveStatus(`${ItemLabel}-idle`);
  };

  const onSubmit = (e) => {
    const { companyId, propertyId, ItemLabel, ItemType, userId } = props;
    e.preventDefault();

    if (ItemType === "company") {
      props.updateCompany(companyInfo, companyId, ItemLabel, userId);
    }
    if (ItemType === "company-loading") {
      props.updateCompany(companyLoadingInfo, companyId, ItemLabel, userId);
    }
    if (ItemType === "property") {
      props.updateProperty(propertyInfo, propertyId, ItemLabel);
    }
  };

  return (
    <>
      <div className="infoitem-container">
        <div className="infoitem-heading-and-button-container">
          <div className="infoitem-heading">
            <InfoItemCheckMark
              IsChecked={IsChecked}
              IsRequired={IsRequired}
              IsCustomContent={ValueA ? true : false}
              ItemHeading={ItemHeading}
              IsNewCustomItem={IsNewCustomItem}
            />
          </div>

          <button
            className="infoitem-edit-button"
            onClick={SaveStatus === `${ItemLabel}-editing` ? onCancel : onEdit}
          >
            {SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching`
              ? "Cancel"
              : "Edit"}
          </button>
        </div>

        <div
          className={
            SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching`
              ? "hide"
              : "show"
          }
        >
          {!ValueA && !ValueB && FalseText}

          {ValueA && ValueB && (
            <div className="colour-picker-preview-outer">
              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label"> {InputLabelA} </label>
                <div
                  className="colour-picker-preview"
                  style={{ background: `${ValueA}` }}
                ></div>
                <div className="colour-picker-preview-text"> {ValueA} </div>
              </div>

              <div className="infoitem-form-inner-container-right">
                <label className="infoitem-form-label"> {InputLabelB} </label>
                <div
                  className="colour-picker-preview"
                  style={{ background: `${ValueB}` }}
                ></div>
                <div className="colour-picker-preview-text"> {ValueB} </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={
            SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching`
              ? "show"
              : "hide"
          }
        >
          <div className="infoitem-value">{ItemDescription}</div>

          <div className="infoitem-form-container">
            <form className="infoitem-form" onSubmit={onSubmit}>
              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label"> {InputLabelA} </label>

                <div className="colour-picker-outer">
                  <HexColorPicker color={colorA} onChange={setColorA} />

                  <div className="colour-picker-input-and-hashtag-container">
                    <div> # </div>
                    <HexColorInput
                      className="colour-picker-text-input"
                      color={colorA}
                      onChange={setColorA}
                    />
                  </div>
                </div>
              </div>

              <div className="infoitem-form-inner-container-right">
                <label className="infoitem-form-label"> {InputLabelB} </label>

                <div className="colour-picker-outer">
                  <HexColorPicker color={colorB} onChange={setColorB} />

                  <div className="colour-picker-input-and-hashtag-container">
                    <div> # </div>
                    <HexColorInput
                      className="colour-picker-text-input"
                      color={colorB}
                      onChange={setColorB}
                    />
                  </div>
                </div>
              </div>

              <div className="infoitem-form-inner-container-left">
                <input
                  type="submit"
                  className="infoitem-save-button"
                  value={
                    SaveStatus === `${ItemLabel}-fetching`
                      ? "Saving..."
                      : "Save"
                  }
                />
              </div>
            </form>
          </div>
        </div>

        <div className="infoitem-section-break-wrapper">
          <div className="infoitem-section-break"></div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateCompany: (companyInfo, companyId, ItemLabel, userId) =>
    dispatch(updateCompany(companyInfo, companyId, ItemLabel, userId)),
  updateProperty: (propertyInfo, propertyId, ItemLabel) =>
    dispatch(updateProperty(propertyInfo, propertyId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemThemeColours);
