import React from "react";
import ToolTipIcon from "../icons/ToolTipIcon";
import "../styles/InfoItemCheckMark.css";

export default class ToolTip extends React.Component {
  render() {
    const { Content } = this.props;
    return (
      <div className="tooltip-outer-wrapper">
        <div className="tooltip-inner-wrapper">
          <div className="tooltip-icon-wrapper">
            <ToolTipIcon />
          </div>
          <div className="infoitem-checkmark-description-wrapper">
            <div className="tooltip-description">{Content}</div>
          </div>
        </div>
      </div>
    );
  }
}
