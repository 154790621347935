import React from "react";
import {
  googlePlacePhotoApiRootUrl,
  googlePlacesApiKey,
} from "../constants.js";
import "../styles/ListItemImage.css";

export default class ListItemImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      didLoad: false,
    };
  }

  onLoad = () => {
    this.setState({
      didLoad: true,
    });
  };

  //shouldComponentUpdate = () => false;

  shouldComponentUpdate(prevProps, prevState) {
    return (
      prevProps.DetailImage !== this.props.DetailImage ||
      prevProps.GooglePhotoRef !== this.props.GooglePhotoRef
    );
  }

  render() {
    const { CategoryImage, DetailImage, AltText, GooglePhotoRef } = this.props;
    // Set Filenames so "null"s can be filtered out
    //  console.log("render");
    const detailImageFilename = DetailImage
      ? DetailImage.substring(DetailImage.lastIndexOf("/") + 1)
      : "";
    const categoryImageFilename = CategoryImage
      ? CategoryImage.substring(CategoryImage.lastIndexOf("/") + 1)
      : "";

    // Set Image source
    var ImageSource = null;
    if (DetailImage && detailImageFilename !== "null") {
      ImageSource = DetailImage;
    }
    if (
      (!DetailImage || detailImageFilename === "null") &&
      CategoryImage &&
      categoryImageFilename !== "null"
    ) {
      ImageSource = CategoryImage;
    }

    const ImageType = ImageSource
      ? ImageSource.substring(ImageSource.lastIndexOf(".") + 1)
      : "";

    var OuterWrapperStyle = "menu-item-image-outer-wrapper-loading";
    if (this.state.didLoad && ImageType === "svg") {
      OuterWrapperStyle = "menu-item-image-icon-outer-wrapper";
    }
    if (this.state.didLoad && ImageType !== "svg") {
      OuterWrapperStyle = "menu-item-image-outer-wrapper";
    }

    var InnerWrapperStyle = "menu-item-image-inner-wrapper-loading";
    if (this.state.didLoad && ImageType === "svg") {
      InnerWrapperStyle = "menu-item-image-icon-inner-wrapper";
    }
    if (this.state.didLoad && ImageType !== "svg") {
      InnerWrapperStyle = "menu-item-image-inner-wrapper";
    }

    var ImageStyle = "menu-item-image-loading menu-item-image-loading-animate";
    if (this.state.didLoad && ImageType === "svg") {
      ImageStyle = "menu-item-image-icon";
    }
    if (this.state.didLoad && ImageType !== "svg") {
      ImageStyle = "menu-item-image";
    }

    if (ImageSource) {
      return (
        <div className={OuterWrapperStyle}>
          <div className={InnerWrapperStyle}>
            <img
              className={ImageStyle}
              src={ImageSource}
              alt={AltText}
              onLoad={this.onLoad}
            />
          </div>
        </div>
      );
    }
    if (!ImageSource && GooglePhotoRef) {
      return (
        <div className={OuterWrapperStyle}>
          <div className={InnerWrapperStyle}>
            <img
              className={ImageStyle}
              src={`${googlePlacePhotoApiRootUrl}maxwidth=960&photoreference=${GooglePhotoRef}&key=${googlePlacesApiKey}`}
              alt={AltText}
              onLoad={this.onLoad}
            />
          </div>
        </div>
      );
    }
    return (
      <>
        <div className="menu-item-image-outer-wrapper">
          <div className="menu-item-image-text-container">
            <div className="menu-item-image-text">Awaiting Image</div>
          </div>
        </div>
      </>
    );
  }
}
