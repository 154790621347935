import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import InfoItemImage from "./InfoItemImage";
import InfoItemOneBox from "./InfoItemOneBox";
import InfoItemMap from "./InfoItemMap";
import SetupManager from "./SetupManager";
import InfoItemThemeColours from "./InfoItemThemeColours";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/InfoItem.css";

//ReactGA.initialize(googleAnalyticsId);

export default class SetupBasics extends React.Component {
  // Scroll to top on page load & track page path
  componentDidMount() {
    window.scrollTo(0, 0);
 //   ReactGA.pageview(window.location.pathname + window.location.search);
  }
  componentDidUpdate = () => {
 //   ReactGA.pageview(window.location.pathname + window.location.search);
  };

  // Extract VanityPropertyId from current page's URL

  autoOpen = (SaveStatus, ItemLabel, PrevData, ThisData) => {
    if (SaveStatus === `${ItemLabel}-fetching`) {
      return SaveStatus;
    }
    if (
      (SaveStatus !== `${ItemLabel}-idle` ||
        SaveStatus !== `${ItemLabel}-fetching` ||
        SaveStatus !== `${ItemLabel}-success`) &&
      PrevData &&
      !ThisData
    ) {
      return `${ItemLabel}-editing`;
    } else {
      return SaveStatus;
    }
  };

  onClick = () => {
    const vanityPropertyId = window.location.pathname.split("/")[2];
    this.props.history.push(`/property/${vanityPropertyId}/setup-complete`);
  };

  render() {
    const { SaveStatus, propertyData, managerData, userData } = this.props;
    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];

    // Use Detail label to lookup custom content
    const propertyDataFiltered = propertyData.filter((Item) => {
      return Item.PropertyID === vanityPropertyId;
    });
    const propertyDetails = propertyDataFiltered[0];

    const managerId = propertyDetails.LinkedPropertyMgr;

    const managerDataFiltered = managerData.filter((Item) => {
      return Item._id === managerId;
    });
    const propertyManager = managerDataFiltered[0];

    var propertyManagerId = "";
    if (propertyManager && propertyManager._id) {
      propertyManagerId = propertyManager._id;
    }

    const SectionTitle = "Setup the Basics";

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="setupflow-heading">{SectionTitle}</div>

            <InfoItemOneBox
              SaveStatus={SaveStatus}
              propertyDetails={propertyDetails}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-full"}
              ItemLabel={"property-title"}
              ItemHeading={"Property Title"}
              ItemDescription={
                "This is the property name that will be displayed to guests"
              }
              InputLabelA={"Property name"}
              ValueA={propertyDetails.PropertyTitle}
              IsChecked={propertyDetails.PropertyTitle ? true : false}
              IsRequired={false}
              FalseText={"Not entered"}
              PayloadA={"PropertyTitle"}
            />

            <InfoItemImage
              SaveStatus={this.autoOpen(
                SaveStatus,
                "cover-image",
                propertyDetails.PropertyTitle,
                propertyDetails.PropertyCoverImage
              )}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              ItemLabel={"property-cover-image"}
              FileFolder={"property-content"}
              ItemId={propertyDetails._id} // Defines which id to insert into filename
              ItemHeading={"Cover Image"}
              ItemDescription={"For best results pick a landscape image"}
              InputLabelA={""}
              ValueA={propertyDetails.PropertyCoverImage}
              IsChecked={propertyDetails.PropertyCoverImage ? true : false}
              IsRequired={false}
              FalseText={"Not selected"}
              PayloadA={"PropertyCoverImage"} // Defines which database label to update
              IsNewCustomItem={true}
            />

            <InfoItemMap
              {...this.props}
              SaveStatus={this.autoOpen(
                SaveStatus,
                "property-location",
                propertyDetails.PropertyCoverImage,
                propertyDetails.GeoLocationFormattedAddress
              )}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              ItemLabel={"property-location"}
              ItemHeading={"Property Location"}
              ItemDescription={
                "The location entered here controls where your property appears on the map"
              }
              InputLabelA={"Property address"}
              ValueA={propertyDetails.GeoLocationFormattedAddress}
              IsChecked={
                propertyDetails.GeoLocationFormattedAddress ? true : false
              }
              IsRequired={false}
              FalseText={"Not selected"}
              PayloadA={"PropertyLocation"} // Defines which database label to update
              propertyDetails={propertyDetails}
              IsNewCustomItem={true}
            />

            <SetupManager
              {...this.props}
              SaveStatus={this.autoOpen(
                SaveStatus,
                "new-manager",
                propertyDetails.GeoLocationFormattedAddress,
                propertyManager
              )}
              managerData={managerData}
              //      postResponseData={postResponseData}
              ItemType={"manager"} // Defines which dispatch to invoke
              ItemLabel={"new-manager"}
              ItemHeading={"Host's Name"}
              ItemDescription={"Enter the host's name"}
              InputLabelA={"First name"}
              ValueA={
                propertyManager && propertyManager.PropertyMgrFirstname
                  ? propertyManager.PropertyMgrFirstname
                  : ""
              }
              PayloadA={"PropertyMgrFirstname"}
              InputLabelB={"Last name"}
              ValueB={
                propertyManager && propertyManager.PropertyMgrLastname
                  ? propertyManager.PropertyMgrLastname
                  : ""
              }
              PayloadB={"PropertyMgrLastname"}
              FalseText={"Not selected"}
              IsRequired={false}
              IsNewCustomItem={true}
            />

            <InfoItemImage
              SaveStatus={this.autoOpen(
                SaveStatus,
                "host-photo",
                propertyManager,
                propertyManager && propertyManager.PropertyMgrCoverImage
                  ? propertyManager.PropertyMgrCoverImage
                  : null
              )}
              userId={userData._id}
              managerId={propertyManagerId}
              ItemType={"default-manager"} // Defines which dispatch to invoke
              ItemHeading={"Host's Photo"}
              ItemLabel={"manager-profile-image"}
              FileFolder={"manager-content"}
              ItemId={propertyManagerId} // Defines which id to insert into filename
              ItemDescription={"This is the photo displayed to your guests"}
              InputLabelA={""}
              ValueA={
                propertyManager && propertyManager.PropertyMgrCoverImage
                  ? propertyManager.PropertyMgrCoverImage
                  : null
              }
              PayloadA={"PropertyMgrCoverImage"} // Defines which database label to update
              IsChecked={
                propertyManager && propertyManager.PropertyMgrCoverImage
                  ? true
                  : false
              }
              IsRequired={false}
              FalseText={"Not uploaded"}
              IsNewCustomItem={true}
            />

            <InfoItemOneBox
              SaveStatus={this.autoOpen(
                SaveStatus,
                "host-email-address",
                propertyManager && propertyManager.PropertyMgrCoverImage
                  ? propertyManager.PropertyMgrCoverImage
                  : null,
                propertyManager && propertyManager.PropertyMgrEmail
                  ? propertyManager.PropertyMgrEmail
                  : ""
              )}
              managerId={propertyManagerId}
              ItemType={"manager"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-full"}
              ItemLabel={"host-email-address"}
              ItemHeading={"Host's Email Address"}
              ItemDescription={
                "This is the email address that guests will use to contact you"
              }
              InputLabelA={"Email address"}
              ValueA={
                propertyManager && propertyManager.PropertyMgrEmail
                  ? propertyManager.PropertyMgrEmail
                  : ""
              }
              PayloadA={"PropertyMgrEmail"}
              IsChecked={
                propertyManager && propertyManager.PropertyMgrEmail
                  ? true
                  : false
              }
              IsRequired={false}
              FalseText={"Not entered"}
              IsNewCustomItem={true}
            />

            <InfoItemOneBox
              SaveStatus={this.autoOpen(
                SaveStatus,
                "host-tel-number",
                propertyManager && propertyManager.PropertyMgrEmail
                  ? propertyManager.PropertyMgrEmail
                  : "",
                propertyManager && propertyManager.PropertyMgrTel
                  ? propertyManager.PropertyMgrTel
                  : ""
              )}
              userId={userData._id}
              managerId={propertyManagerId}
              ItemType={"default-manager"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-left"}
              ItemLabel={"host-tel-number"}
              ItemHeading={"Host's Tel Number"}
              ItemDescription={
                "This is the telephone number that guests will use to contact you"
              }
              InputLabelA={"Tel number"}
              ValueA={
                propertyManager && propertyManager.PropertyMgrTel
                  ? propertyManager.PropertyMgrTel
                  : ""
              }
              PayloadA={"PropertyMgrTel"}
              IsChecked={
                propertyManager && propertyManager.PropertyMgrTel ? true : false
              }
              IsRequired={false}
              FalseText={"Not entered"}
              IsNewCustomItem={true}
            />

            <InfoItemThemeColours
              SaveStatus={this.autoOpen(
                SaveStatus,
                "theme-colours",
                propertyManager && propertyManager.PropertyMgrTel
                  ? propertyManager.PropertyMgrTel
                  : "",
                propertyDetails && propertyDetails.PropertyHexPrimary
                  ? propertyDetails.PropertyHexPrimary
                  : ""
              )}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              ItemLabel={"theme-colours"}
              ItemHeading={"Theme Colours"}
              ItemDescription={"Select colours or enter hex codes below"}
              InputLabelA={"Primary theme colour"}
              ValueA={propertyDetails.PropertyHexPrimary}
              IsChecked={propertyDetails.PropertyHexPrimary ? true : false}
              IsRequired={false}
              FalseText={"Not selected"}
              PayloadA={"PropertyHexPrimary"}
              InputLabelB={"Secondary theme colour"}
              ValueB={propertyDetails.PropertyHexSecondary}
              PayloadB={"PropertyHexSecondary"}
              IsNewCustomItem={true}
            />

            <div className="infoitem-container">
              <button className="create-new-button" onClick={this.onClick}>
                Complete setup
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
