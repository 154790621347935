import React from "react";
//import ReactGA from "react-ga";
//import FormattedDaT from "./FormattedDaT";
//import { googleAnalyticsId } from "../constants.js";
import { connect } from "react-redux";
import { getReferralData } from "../redux/actions/userActions";
import { appConfig } from "../data/appConfig";
import Loading from "../components/Loading";
import Breadcrumb from "../components/Breadcrumb";
import Table from "../components/Table";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/Referrals.css";

//ReactGA.initialize(googleAnalyticsId);

class ReferralsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    //  ReactGA.pageview(window.location.pathname + window.location.search);
    this.props.getReferralData();
  }

  render() {
    const { userData, referralData, ReferralDataLoaded, geoLocationData } =
      this.props;
    // console.log("referralData", referralData);
    // console.log("referralDataLoaded", ReferralDataLoaded);

    const currency = userData.DefaultCurrency
      ? userData.DefaultCurrency
      : geoLocationData.currency.toLowerCase();
    // console.log(currency);

    const currencySymbol = currency === "gbp" ? "£" : "$";

    if (!ReferralDataLoaded) {
      return <Loading />;
    }

    if (ReferralDataLoaded) {
      const customerReferralData = referralData.customerReferrals.map(
        (item) => {
          return {
            PropertyTitle: item.PropertyTitle,
            RewardAmount: `${currencySymbol}${item.RewardAmount}`,
            Status: item.RewardCredited
              ? "Confirmed, reward payment completed"
              : item.ActiveSubscriber && !item.RewardCredited
              ? "Subscribed. First month not completed yet"
              : !item.ActiveSubscriber &&
                !item.RewardCredited &&
                item.TrialEndDate > new Date()
              ? "In trial period, not subscribed yet"
              : !item.ActiveSubscriber &&
                !item.RewardCredited &&
                item.TrialEndDate < new Date()
              ? "Trial ended, not subscribed yet"
              : "",
          };
        }
      );

      const currentRewardValue =
        currency === "gbp"
          ? referralData.currentRewardValueGBP
          : currency === "usd"
          ? referralData.currentRewardValueUSD
          : currency === "aud"
          ? referralData.currentRewardValueAUD
          : 0;

      const accountBalanceCalc = -referralData.accountBalance / 100;
      const accountBalance = accountBalanceCalc.toFixed(2);

      return (
        <>
          <div className="canvas-with-header-and-footer">
            <div className="canvas-with-header-and-footer-inner-container">
              <Breadcrumb
                {...this.props}
                PreviousPageTitle={"Dashboard"}
                PreviousPagePath={"/"}
                CurrentSectionTitle={"Referrals"}
              />
              <h1> Referrals </h1>

              <div className="top-section-wrapper">
                <div className="top-section-detail-snippet">
                  <div className="section-subheading">
                    Refer a friend and earn{" "}
                    <span>
                      {currencySymbol}
                      {currentRewardValue}
                    </span>{" "}
                    account credit
                  </div>

                  <div className="sub-section-text">
                    1. Simply share your unique URL link below.
                  </div>

                  <div className="sub-section-text">
                    2. Anyone who signs up from your link will be automatically
                    tracked.
                  </div>

                  <div className="sub-section-text">
                    3. Shortly after they’ve completed their first paid month,
                    we will issue the credit to your account.
                  </div>

                  <div className="sub-section-text">
                    4. Your account credit will then be used towards your future
                    subscription payments!
                  </div>
                </div>

                <div className="top-section-callout-banner">
                  <div className="top-section-callout-banner-inner">
                    <div className="top-section-callout-banner-header">
                      Your account credit
                    </div>
                    <div className="top-section-callout-banner-value">
                      <span>{currencySymbol}</span>
                      {accountBalance}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mid-section-wrapper">
                <div className="url-callout-banner">
                  <div className="url-callout-banner-left">
                    <div className="url-callout-banner-header">
                      Share your unique URL:
                    </div>
                  </div>

                  <div className="url-callout-banner-value">
                    {`${appConfig.referralUrl}?ref=${userData.ReferrerId}`}
                  </div>
                </div>

                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>

                <div className="sub-section-heading">Your Referrals</div>

                <div className="stats-section-callout-banner-wrapper">
                  <div className="stats-section-callout-banner">
                    <div className="stats-section-callout-banner-inner">
                      <div className="stats-section-callout-banner-header">
                        Total Link<br></br>Clicks
                      </div>
                      <div className="stats-section-callout-banner-value">
                        {referralData.totalLinkClicks
                          ? referralData.totalLinkClicks
                          : 0}
                      </div>
                    </div>
                  </div>

                  <div className="stats-section-callout-banner-spacer"></div>

                  <div className="stats-section-callout-banner">
                    <div className="stats-section-callout-banner-inner">
                      <div className="stats-section-callout-banner-header">
                        Total Confirmed<br></br>Referrals
                      </div>
                      <div className="stats-section-callout-banner-value">
                        {referralData.totalReferrals}
                      </div>
                    </div>
                  </div>

                  <div className="stats-section-callout-banner-spacer"></div>

                  <div className="stats-section-callout-banner">
                    <div className="stats-section-callout-banner-inner">
                      <div className="stats-section-callout-banner-header">
                        Total Rewards<br></br>Earned
                      </div>
                      <div className="stats-section-callout-banner-value">
                        <span>{currencySymbol}</span>
                        {referralData.totalRewards
                          ? referralData.totalRewards
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>

                {customerReferralData.length > 0 && (
                  <Table
                    {...{
                      data: customerReferralData,
                      fields: {
                        PropertyTitle: "Property Name",
                        RewardAmount: "Reward Amount",
                        Status: "Status",
                      },
                    }}
                  />
                )}

                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>

                <div className="ts-and-cs-text">
                  <strong>Referral Promotion Terms and Conditions </strong>
                  <ol>
                    <li>
                      The referral credit amount will be issued for each new
                      user referred to us through your unique URL. Users with
                      multiple properties count as 1 referral. The referred
                      friend must hold a paid subscription for at least 35 days.
                    </li>
                    <li>
                      There is no limit to how many referrals you can make and
                      no limit to how much account credit you can earn. However,
                      we reserve the right to remove account credit if we deem
                      that the promotion has been abused.
                    </li>
                    <li>
                      Account credit remains active whilst your account is a
                      live, paid subscription. Your account credit balance can
                      only be used to reduce your future subscription payments
                      and is not redeemable as cash or any other form of payment
                      if you cancel your subscription.
                    </li>
                    <li>
                      We reserve the right to remove account credit if you
                      cancel your subscription.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getReferralData: () => dispatch(getReferralData()),
  };
};

export default connect(null, mapDispatchToProps)(ReferralsPage);
