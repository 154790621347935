import React from "react";
import "../styles/DisplayCanvas.css";
import "../styles/ButtonBanners.css";
import ButtonBanner from "./ButtonBanner";
import UserIcon from "../icons/UserIcon";
import BusinessIcon from "../icons/BusinessIcon";
import CopyIcon from "../icons/CopyIcon";
import LocationIcon from "../icons/LocationIcon";

export default class DashboardProfile extends React.Component {
  render() {
    const { SectionHeading, SharedContentProfileId } = this.props;
    return (
      <>
        <div className="button-banner-section-outer-container">
          <div className="button-banner-section-container">
            <h3> {SectionHeading} </h3>

            <div className="button-banners-container">
              <div className="button-banner-container-1">
                <ButtonBanner
                  Icon={UserIcon}
                  Heading={"Property Managers"}
                  SubHeading={"Provide contact details for your guests"}
                  Target={"property-managers"}
                  {...this.props}
                />
              </div>

              <div className="button-banner-container-2">
                <ButtonBanner
                  Icon={BusinessIcon}
                  Heading={"Company Profile"}
                  SubHeading={"Edit company details and branding"}
                  Target={"company-profile"}
                  {...this.props}
                />
              </div>

              {SharedContentProfileId && (
                <div className="button-banner-container-3">
                  <ButtonBanner
                    Icon={CopyIcon}
                    Heading={"Shared Property Content"}
                    SubHeading={
                      "Edit and share content across multiple properties"
                    }
                    Target={"shared-content/property"} //`shared-content/${SharedContentProfileId}`}
                    PropertyId={SharedContentProfileId}
                    {...this.props}
                  />
                </div>
              )}

              {
                // All Content banner start
              }

              {
                // All Content banner end
              }

              <div
                className={
                  SharedContentProfileId
                    ? "button-banner-container-4"
                    : "button-banner-container-3"
                }
              >
                <ButtonBanner
                  Icon={LocationIcon}
                  Heading={"Local Area Profiles"}
                  SubHeading={
                    "Edit and share local places across multiple properties"
                  }
                  Target={"local-area-profiles"}
                  PropertyId={SharedContentProfileId}
                  {...this.props}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
