import React from "react";
import Breadcrumb from "./Breadcrumb";
import CreateCompanyModule from "./CreateCompanyModule";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class CreateCompany extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { SaveStatus, userData, linkedPropertiesLoaded } = this.props;
    const SectionTitle = "Create a Company Profile";

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <Breadcrumb
              {...this.props}
              PreviousPageTitle={"Company Profile"}
              PreviousPagePath={"/company-profile"}
              CurrentSectionTitle={SectionTitle}
            />
            <h1>{SectionTitle}</h1>
            <div className="infopage-subheading">
              This should be the company name or brand name as you would like to
              display it to guests
            </div>

            <CreateCompanyModule
              {...this.props}
              SaveStatus={SaveStatus}
              SetupFlow={false}
              ItemType={"company"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-full"}
              ItemLabel={"New Company"}
              ItemHeading={"Company Title"}
              InputLabelA={"Company name"}
              ValueA={""}
              FalseText={"Not entered"}
              PayloadA={"MgmtCoBrandname"}
              userId={userData._id}
              linkedPropertiesLoaded={linkedPropertiesLoaded}
            />
          </div>
        </div>
      </>
    );
  }
}
