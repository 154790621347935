const defaultState = {
  fetchStatus: "idle",
  saveStatus: "idle",
  postResponseData: null
};

const fetchReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SAVE_STATUS":
      return {
        saveStatus: action.payload
      };
    case "FETCH_STATUS":
      return {
        fetchStatus: action.payload
      };
    case "SET_POST_RESPONSE":
      return {
        loggedIn: true,
        postResponseData: { ...action.payload }
      };
    default:
      return state;
  }
};

export default fetchReducer;
