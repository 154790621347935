import React from "react";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import "../styles/InfoItemCheckMark.css";

export default class SetupCheckMark extends React.Component {
  render() {
    const { ItemText } = this.props;
    return (
      <div className="infoitem-checkmark-outer-wrapper">
        <div className="infoitem-checkmark-wrapper">
          <div className="infoitem-checkmark-icon-wrapper-checked">
            <CheckmarkIcon className="infoitem-checkmark-icon-checked" />
          </div>
          <div className="infoitem-checkmark-description-wrapper">
            <div className="welcome-banner-point-text">{ItemText}</div>
          </div>
        </div>
      </div>
    );
  }
}
