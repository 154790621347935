import React from "react";

function LinkIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={612}
      height={612}
      viewBox="0 0 612 612"
      {...props}
    >
      <path d="M136.446 560.336c-15.611 15.611-40.904 15.611-56.515 0l-28.257-28.257c-15.61-15.611-15.61-40.904 0-56.515L241.65 285.587c-26.618-7.035-56.08-.455-76.947 20.413L23.416 447.287c-31.221 31.222-31.221 81.828 0 113.029l28.257 28.258c31.222 31.221 81.828 31.221 113.03 0L305.99 447.287c20.867-20.867 27.447-50.33 20.412-76.947L136.446 560.336zM588.584 51.683l-28.258-28.257c-31.221-31.221-81.827-31.221-113.029 0L306.01 164.713c-20.867 20.867-27.447 50.33-20.413 76.927L475.574 51.664c15.61-15.611 40.904-15.611 56.515 0l28.258 28.257c15.61 15.611 15.61 40.904 0 56.515L370.369 326.412c26.618 7.035 56.08.455 76.928-20.412l141.287-141.287c31.222-31.202 31.222-81.828 0-113.03zM369.579 242.747c-7.806-7.805-20.452-7.805-28.258 0l-98.9 98.901c-7.806 7.806-7.806 20.452 0 28.258 7.805 7.806 20.452 7.806 28.257 0l98.901-98.901c7.806-7.806 7.806-20.473 0-28.258z" />
    </svg>
  );
}

export default LinkIcon;
