import { dataApiRoot, apiSecretToken } from "../constants.js";
import {
  setConversationData,
  setMessageData,
  setLoadingStatus
} from "../redux/actions/userActions";

export const refreshConversations = async (dispatch, userId, buttonId) => {
  // console.log("running")
  if (!userId) {
    //   dispatch(
    //    setConversationData({
    //      conversationsLoading: false,
    //     conversationDataRefreshing: false
    //   })
    // );
    // dispatch(
    //   setMessageData({
    //     conversationMessagesLoading: false,
    //     conversationMessagesRefreshing: false
    //   })
    // );
  }

  if (userId) {
    // Set loading status start
    //  dispatch(
    //  setConversationData({
    //     conversationsLoading: false,
    //     conversationDataRefreshing: true
    //   })
    // );
    // dispatch(
    //   setMessageData({
    //     conversationMessagesLoading: false,
    //     conversationMessagesRefreshing: true
    //   })
    // );

    // End Set Loading Status

    // Fetch Conversations Start
    const fetchConversationsData = async function (cursor) {
      let apiUrl = `${dataApiRoot}conversations?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${userId}%22}]&sort_field=latestmessagedate&descending=true&cursor=${cursor}`;
      var apiResults = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`
        }
      }).then((resp) => {
        return resp.json();
      });
      return apiResults;
    };

    const fetchAllConversationsData = async function (cursor = 0) {
      const data = await fetchConversationsData(cursor);
      const { remaining, results } = data.response;
      //  console.log("Retreiving data from API for cursor position: " + cursor);
      if (remaining > 0) {
        cursor += results.length;
        return results.concat(await fetchAllConversationsData(cursor));
      } else {
        return results;
      }
    };

    (async () => {
      const allConversationsData = await fetchAllConversationsData();

      // console.log("allConversationsData", allConversationsData);
      dispatch(setConversationData(allConversationsData));
    })();
    // Fetch Conversations End

    // Fetch Messages Start
    const fetchConversationMessagesData = async function (cursor) {
      let apiUrl = `${dataApiRoot}conversationmessages?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${userId}%22}]&sort_field=createddate&descending=false&cursor=${cursor}`;
      var apiResults = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`
        }
      }).then((resp) => {
        return resp.json();
      });
      return apiResults;
    };

    const fetchAllConversationMessagesData = async function (cursor = 0) {
      const data = await fetchConversationMessagesData(cursor);
      const { remaining, results } = data.response;
      //  console.log("Retreiving data from API for cursor position: " + cursor);
      if (remaining > 0) {
        cursor += results.length;
        return results.concat(await fetchAllConversationMessagesData(cursor));
      } else {
        return results;
      }
    };

    (async () => {
      const allConversationMessagesData = await fetchAllConversationMessagesData();

      //  console.log("allConversationMessagesData", allConversationMessagesData);
      dispatch(setMessageData(allConversationMessagesData));
      dispatch(setLoadingStatus(`${buttonId}-loaded`));
    })();

    // Fetch Messages End
  }
};
