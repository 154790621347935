import React from "react";
import { connect } from "react-redux";
import { updateProperty } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import { workflowApiRoot, apiSecretToken } from "../constants.js";
import "../styles/InfoItem.css";

class InfoItemPasscode extends React.Component {
  state = {
    isEditing: this.props.IsEditing,
    payload: {}
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA }
    }));
  };

  onEdit = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-editing`);
  };

  onCancel = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-idle`);
  };

  onSubmit = (e) => {
    const { propertyId, ItemLabel } = this.props;
    e.preventDefault();

    let isNumbersOnly = /^\d+$/.test(this.state.payload.PassCode);

    if (this.state.payload.PassCode.length === 6 && isNumbersOnly) {
      this.props.updateProperty(this.state.payload, propertyId, ItemLabel);

      fetch(`${workflowApiRoot}update-session-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`
        },
        body: JSON.stringify({ propertyId: propertyId })
      });
    } else {
      this.setState({ passCodeError: true });
      setTimeout(() => this.setState({ passCodeError: false }), 3000);
    }
  };

  onRemove = (e) => {
    const { propertyId } = this.props;
    const ItemLabel = "remove-passcode";
    this.props.setSaveStatus(`${ItemLabel}-fetching`);
    e.preventDefault();
    this.props.updateProperty({ PassCode: "" }, propertyId, ItemLabel);

    fetch(`${workflowApiRoot}update-session-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiSecretToken}`
      },
      body: JSON.stringify({ propertyId: propertyId })
    });
  };

  render() {
    const { propertyDetails, SaveStatus, ItemLabel, PayloadA } = this.props;
    // console.log(SaveStatus);
    return (
      <>
        <div className="infoitem-container">
          <div className="infopage-link-box">
            <div
              className={
                SaveStatus === `${ItemLabel}-editing` ||
                SaveStatus === `${ItemLabel}-fetching`
                  ? "hide"
                  : "show"
              }
            >
              <div className="infopage-link-and-button-container">
                <div className="infopage-link" id="infopage-link">
                  {propertyDetails.PassCode
                    ? propertyDetails.PassCode
                    : "Not set"}
                </div>
              </div>
            </div>

            <div
              className={
                SaveStatus === `${ItemLabel}-editing` ||
                SaveStatus === `${ItemLabel}-fetching`
                  ? "show"
                  : "hide"
              }
            >
              <form
                className="passcode-input-form-container"
                onSubmit={this.onSubmit}
              >
                <div className="passcode-input-form-label">
                  Enter new passcode:
                </div>

                <div className="passcode-input-form">
                  <input
                    type="text"
                    name={PayloadA}
                    className="passcode-text-input"
                    placeholder="6 digits only"
                    value={this.state.payload.name}
                    onChange={this.onChange}
                    required
                  />
                </div>

                {this.state.passCodeError && (
                  <div className="passcode-text-error-message">
                    Passcode must be a 6 digit number
                  </div>
                )}

                <input
                  type="submit"
                  className="passcode-save-button"
                  value={
                    SaveStatus === `${ItemLabel}-fetching`
                      ? "Saving..."
                      : "Save"
                  }
                />

                <div
                  className="infopage-link-mini-button-inverted"
                  onClick={
                    SaveStatus === `${ItemLabel}-editing`
                      ? this.onCancel
                      : this.onEdit
                  }
                >
                  {SaveStatus === `${ItemLabel}-editing` ||
                  SaveStatus === `${ItemLabel}-fetching`
                    ? "Cancel"
                    : "Edit"}
                </div>
              </form>
            </div>

            <div
              className={
                SaveStatus === `${ItemLabel}-editing` ||
                SaveStatus === `${ItemLabel}-fetching`
                  ? "hide"
                  : "show"
              }
              style={{ textDecoration: "none" }}
            >
              <div className="infopage-link-button-nohover">
                <>
                  <div className="infopage-link-label">Passcode</div>
                  <div
                    className="infopage-link-mini-button"
                    onClick={
                      SaveStatus === `${ItemLabel}-editing`
                        ? this.onCancel
                        : this.onEdit
                    }
                  >
                    {propertyDetails.PassCode ? "Edit" : "Add"}
                  </div>

                  {propertyDetails.PassCode && (
                    <div
                      className="infopage-link-mini-button"
                      onClick={this.onRemove}
                    >
                      {SaveStatus === "remove-passcode-fetching" ||
                      SaveStatus === "remove-passcode-editing" ||
                      !SaveStatus
                        ? "Removing..."
                        : "Remove"}
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateProperty: (propertyInfo, propertyId, ItemLabel) =>
    dispatch(updateProperty(propertyInfo, propertyId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemPasscode);
