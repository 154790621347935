import React from "react";
import "../styles/DashboardSubscriptionInvoices.css";
import Table from "./Table";
import formatDateLabelNoDay from "../functions/formatDateLabelNoDay";

export default function DashboardSubscriptionInvoices(props) {
  // Declare State & Constants
  const { stripeData } = props;
  const invoicesData = stripeData.invoicesData;
  //console.log(invoicesData);
  const invoicesDataFiltered = invoicesData.filter(
    (item) => item._api_c2_amount_paid > 0
  );

  if (invoicesDataFiltered) {
    const invoicesDataProcessed = invoicesDataFiltered.map((item) => {
      return {
        CreatedDate: formatDateLabelNoDay(
          `${new Date(item._api_c2_period_end * 1000)}`
        ),
        PaidAmount:
          item._api_c2_currency === "usd"
            ? `$${item._api_c2_amount_paid / 100}`
            : item._api_c2_currency === "gbp"
            ? `£${item._api_c2_amount_paid / 100}`
            : item._api_c2_currency === "aud"
            ? `$${item._api_c2_amount_paid / 100}`
            : item._api_c2_currency === "eur"
            ? `€${item._api_c2_amount_paid / 100}`
            : `$${item._api_c2_amount_paid / 100}`,
        Currency: item._api_c2_currency.toUpperCase(),
        ViewLink: (
          <a
            href={item._api_c2_hosted_invoice_url}
            target="_blank"
            rel="noreferrer"
            className="invoice-link"
          >
            View
          </a>
        ),
        DownloadLink: (
          <a href={item._api_c2_invoice_pdf} className="invoice-link">
            Download
          </a>
        )
      };
    });

    return (
      <>
        <div className="infoitem-heading">Invoices & Receipts</div>

        {invoicesDataProcessed.length > 0 && (
          <Table
            {...{
              data: invoicesDataProcessed,
              fields: {
                CreatedDate: "Date",
                PaidAmount: "Amount",
                Currency: "Currency",
                ViewLink: "Invoice & Receipt",
                DownloadLink: "Invoice"
              }
            }}
          />
        )}

        {invoicesDataProcessed && invoicesDataProcessed.length === 0 && (
          <>No invoices yet</>
        )}
      </>
    );
  }
}
