export default function countPageViews(
  array,
  key1,
  value1,
  key2,
  value2,
  key3,
  value3
) {
  var count = array.reduce(function (n, item) {
    return (
      n +
      (item[key1] === value1 && item[key2] === value2 && item[key3] === value3)
    );
  }, 0);

  return count;
}

//console.log(count);
