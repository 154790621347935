import React from "react";

function CircularArrowsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={612.005}
      height={612.006}
      viewBox="0 0 612.005 612.006"
      {...props}
    >
      <path d="M305.934 516.253c-96.313 0-177.413-64.681-202.395-152.909h62.158c5.906-5.734 9.002-13.494 3.096-19.229l-71.791-72.517c-5.906-5.734-15.482-5.734-21.388 0L4.302 344.115c-8.468 9.939-2.81 13.494 3.096 19.229h56.978C90.313 472.865 188.366 554.48 305.934 554.48c89.433 0 167.626-47.325 211.454-118.104l-35.991-14.373c-37.692 56.768-102.162 94.25-175.463 94.25zm299.797-248.478h-54.34c-18.444-119.04-121.143-210.25-245.457-210.25-95.874 0-178.923 54.264-220.438 133.624l35.628 14.24c35.686-65.312 105.068-109.636 184.81-109.636 103.137 0 188.804 74.18 206.81 172.022h-65.293c-5.905 5.734-11.563 9.29-3.096 19.229l71.312 72.518c5.906 5.733 15.482 5.733 21.389 0l71.791-72.518c5.887-5.734 2.79-13.494-3.116-19.229z" />
    </svg>
  );
}

export default CircularArrowsIcon;
