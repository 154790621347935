import React from "react";
import { connect } from "react-redux";
import { updateUser } from "../redux/actions/userActions";
import { updateCompany } from "../redux/actions/userActions";
import { updateManager } from "../redux/actions/userActions";
import { updateProperty } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItem.css";
import { googleMapsApiKey, whatThreeWordsApiKey } from "../constants.js";
import Script from "react-load-script";

class InfoItemMapAuto extends React.Component {
  constructor(props) {
    super(props);
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
  }

  state = {
    payload: {},
    city: "",
    query: "",
    lat: this.props.propertyDetails.GeoLocationLat,
    lng: this.props.propertyDetails.GeoLocationLng,
    whatThreeWords: this.props.propertyDetails.WhatThreeWordsLocation
      ? this.props.propertyDetails.WhatThreeWordsLocation
      : ""
  };

  handleScriptLoad = () => {
    this.createMap();
  };

  createMap = () => {
    // Declare Options For Autocomplete
    const options = {
      types: ["address"]
    }; // To disable any eslint 'google not defined' errors

    // Initialize Google Autocomplete
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields([
      "address_components",
      "formatted_address",
      "geometry"
    ]);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  };

  handlePlaceSelect = async () => {
    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    //  console.log(address);

    function extractFromAdress(components, type) {
      for (var i = 0; i < components.length; i++)
        for (var j = 0; j < components[i].types.length; j++)
          if (components[i].types[j] === type) return components[i].long_name;
      return "";
    }

    var streetNumber = extractFromAdress(address, "street_number");
    var postCode = extractFromAdress(address, "postal_code");
    var street = extractFromAdress(address, "route");
    var town = extractFromAdress(address, "locality");
    var postalTown = extractFromAdress(address, "postal_town");
    var county = extractFromAdress(address, "administrative_area_level_2");
    var state = extractFromAdress(address, "administrative_area_level_1");
    var country = extractFromAdress(address, "country");
    var latitude = addressObject.geometry.location.lat();
    var longitude = addressObject.geometry.location.lng();

    const response = await fetch(
      `https://api.what3words.com/v3/convert-to-3wa?coordinates=${latitude}%2C${longitude}&key=${whatThreeWordsApiKey}`
    );
    const wtwData = await response.json();
    const whatThreeWords = wtwData.words;

    const Payload = {
      GeoLocationAddressLineOne: streetNumber + " " + street,
      GeoLocationAddressLineTwo: "",
      GeoLocationTownCity: postalTown ? postalTown : town,
      GeoLocationCounty: county,
      GeoLocationState: state,
      GeoLocationPostcode: postCode,
      GeoLocationCountry: country,
      GeoLocationFormattedAddress: addressObject.formatted_address,
      PropertyDisplayAddress: addressObject.formatted_address,
      GeoLocationLat: addressObject.geometry.location.lat(),
      GeoLocationLng: addressObject.geometry.location.lng(),
      WhatThreeWordsLocation: whatThreeWords
    };

    // Check if address is valid
    if (address) {
      this.setState({
        city: address[0].long_name,
        query: addressObject.formatted_address,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
        payload: Payload
      });
    }
  };

  onSubmit = (e) => {
    const { ItemLabel, propertyId } = this.props;

    e.preventDefault();
    this.props.updateProperty(this.state.payload, propertyId, ItemLabel);
  };

  render() {
    const { SaveStatus, ItemLabel, InputLabelA } = this.props;
    return (
      <>
        <div className="infoitem-form-inner-container-full">
          <label className="infoitem-form-label"> {InputLabelA} </label>
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
            onLoad={this.handleScriptLoad}
          />
          <input
            className="infoitem-text-input"
            id="autocomplete"
            placeholder="Enter address and select from list"
            defaultValue=""
            required
          />
        </div>

        {!this.state.query && (
          <div className="infoitem-form-inner-container-left">
            <button
              disabled
              onClick={this.onSubmit}
              className="infoitem-save-button"
            >
              {SaveStatus === `${ItemLabel}-fetching` ? "Saving..." : "Save"}
            </button>
          </div>
        )}

        {this.state.query && (
          <div className="infoitem-form-inner-container-left">
            <button onClick={this.onSubmit} className="infoitem-save-button">
              {SaveStatus === `${ItemLabel}-fetching` ? "Saving..." : "Save"}
            </button>
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userInfo, userId, ItemLabel) =>
    dispatch(updateUser(userInfo, userId, ItemLabel)),
  updateManager: (managerInfo, managerId, ItemLabel) =>
    dispatch(updateManager(managerInfo, managerId, ItemLabel)),
  updateCompany: (companyInfo, companyId, ItemLabel) =>
    dispatch(updateCompany(companyInfo, companyId, ItemLabel)),
  updateProperty: (propertyInfo, propertyId, ItemLabel) =>
    dispatch(updateProperty(propertyInfo, propertyId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemMapAuto);
