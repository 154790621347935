// Guidey Subscription Options

export const subscriptionOptions = [
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Standard Pricing",
    productId: "prod_QOuit23HGyOouW",
    type: "basic",
    cycle: "monthly",
    currency: "usd",
    currencySymbol: "$",
    id: "monthly-usd",
    value: "price_1PY6sfGMVnQW6tSnhTziSqzS",
    pricing: {
      tier1Unit: 7.99,
      tier2Unit: 4.99,
      tier3Unit: 3.99,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 7.99,
      tier2Unit: 4.99,
      tier3Unit: 3.99,
    },
  },
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Standard Pricing",
    productId: "prod_QaXKRoIQpotA5q",
    type: "basic",
    cycle: "annual",
    currency: "usd",
    currencySymbol: "$",
    id: "annual-usd",
    value: "price_1PjMFcGMVnQW6tSnYQSeIdGJ",
    pricing: {
      tier1Unit: 89.99,
      tier2Unit: 56.88,
      tier3Unit: 45.48,
    },
    specialOffer: "",
    saveMessage: "SAVE 6%",
    renewalPricing: {
      tier1Unit: 89.99,
      tier2Unit: 56.88,
      tier3Unit: 45.48,
    },
  },
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Standard Pricing",
    productId: "prod_QOuit23HGyOouW",
    type: "basic",
    cycle: "monthly",
    currency: "gbp",
    currencySymbol: "£",
    id: "monthly-gbp",
    value: "price_1PY6sfGMVnQW6tSneULNLF6O",
    pricing: {
      tier1Unit: 5.99,
      tier2Unit: 3.99,
      tier3Unit: 2.99,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 5.99,
      tier2Unit: 3.99,
      tier3Unit: 2.99,
    },
  },
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Standard Pricing",
    productId: "prod_QaXKRoIQpotA5q",
    type: "basic",
    cycle: "annual",
    currency: "gbp",
    currencySymbol: "£",
    id: "annual-gbp",
    value: "price_1PjMFcGMVnQW6tSnia1sTZwp",
    pricing: {
      tier1Unit: 67.99,
      tier2Unit: 45.48,
      tier3Unit: 34.08,
    },
    specialOffer: "",
    saveMessage: "SAVE 5%",
    renewalPricing: {
      tier1Unit: 67.99,
      tier2Unit: 45.48,
      tier3Unit: 34.08,
    },
  },
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Standard Pricing",
    productId: "prod_QOuit23HGyOouW",
    type: "basic",
    cycle: "monthly",
    currency: "eur",
    currencySymbol: "€",
    id: "monthly-eur",
    value: "price_1PY6sfGMVnQW6tSnUMvD77JG",
    pricing: {
      tier1Unit: 6.99,
      tier2Unit: 4.69,
      tier3Unit: 3.49,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 6.99,
      tier2Unit: 4.69,
      tier3Unit: 3.49,
    },
  },
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Standard Pricing",
    productId: "prod_QaXKRoIQpotA5q",
    type: "basic",
    cycle: "annual",
    currency: "eur",
    currencySymbol: "€",
    id: "annual-eur",
    value: "price_1PjMFcGMVnQW6tSnQIageSEb",
    pricing: {
      tier1Unit: 79.69,
      tier2Unit: 53.46,
      tier3Unit: 39.78,
    },
    specialOffer: "",
    saveMessage: "SAVE 5%",
    renewalPricing: {
      tier1Unit: 79.69,
      tier2Unit: 53.46,
      tier3Unit: 39.78,
    },
  },
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Standard Pricing",
    productId: "prod_QOuit23HGyOouW",
    type: "basic",
    cycle: "monthly",
    currency: "aud",
    currencySymbol: "A$",
    id: "monthly-aud",
    value: "price_1PY6sfGMVnQW6tSnCkN0COrT",
    pricing: {
      tier1Unit: 11.99,
      tier2Unit: 7.99,
      tier3Unit: 5.99,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 11.99,
      tier2Unit: 7.99,
      tier3Unit: 5.99,
    },
  },
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Standard Pricing",
    productId: "prod_QaXKRoIQpotA5q",
    type: "basic",
    cycle: "annual",
    currency: "aud",
    currencySymbol: "A$",
    id: "annual-aud",
    value: "price_1PjMFcGMVnQW6tSnivwC1QSL",
    pricing: {
      tier1Unit: 134.99,
      tier2Unit: 91.08,
      tier3Unit: 68.28,
    },
    specialOffer: "",
    saveMessage: "SAVE 6%",
    renewalPricing: {
      tier1Unit: 134.99,
      tier2Unit: 91.08,
      tier3Unit: 68.28,
    },
  },
];

export const subscriptionOffers = [
  {
    name: "Monthly Subscription",
    type: "basic",
    cycle: "monthly",
    currency: "usd",
    currencySymbol: "$",
    id: "monthly-usd",
    value: "price_1JjLhGGMVnQW6tSnbvugVHxE",
    pricing: {
      tier1Unit: 7.99,
      tier2Unit: 4.99,
      tier3Unit: 3.99,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 7.99,
      tier2Unit: 4.99,
      tier3Unit: 3.99,
    },
  },
  {
    name: "Annual Subscription",
    type: "basic",
    cycle: "annual",
    currency: "usd",
    currencySymbol: "$",
    id: "annual-usd",
    value: "price_1OFfdTGMVnQW6tSnCTF0ITJ8",
    pricing: {
      tier1Unit: 62.91,
      tier2Unit: 42.21,
      tier3Unit: 31.41,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 89.99,
      tier2Unit: 56.88,
      tier3Unit: 45.48,
    },
  },

  {
    name: "Annual Subscription",
    type: "basic",
    cycle: "annual",
    currency: "gbp",
    currencySymbol: "£",
    id: "annual-gbp",
    value: "price_1OuDC8GMVnQW6tSnNSxwXQmy",
    pricing: {
      tier1Unit: 16.8,
      tier2Unit: 16.8,
      tier3Unit: 16.8,
    },
    specialOffer: "save-25",
    saveMessage: "SPECIAL OFFER PRICING",
    renewalPricing: {
      tier1Unit: 16.8,
      tier2Unit: 16.8,
      tier3Unit: 16.8,
    },
  },
  {
    name: "Monthly Subscription",
    type: "basic",
    cycle: "monthly",
    currency: "eur",
    currencySymbol: "€",
    id: "monthly-eur",
    value: "price_1O0m87GMVnQW6tSnAXZG6TRa",
    pricing: {
      tier1Unit: 6.99,
      tier2Unit: 4.69,
      tier3Unit: 3.49,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 6.99,
      tier2Unit: 4.69,
      tier3Unit: 3.49,
    },
  },
  {
    name: "Annual Subscription",
    type: "basic",
    cycle: "annual",
    currency: "eur",
    currencySymbol: "€",
    id: "annual-eur",
    value: "price_1OFffNGMVnQW6tSns966FO9o",
    pricing: {
      tier1Unit: 62.91,
      tier2Unit: 42.21,
      tier3Unit: 31.41,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 79.69,
      tier2Unit: 53.46,
      tier3Unit: 39.78,
    },
  },
  {
    name: "Monthly Subscription",
    type: "basic",
    cycle: "monthly",
    currency: "aud",
    currencySymbol: "A$",
    id: "monthly-aud",
    value: "price_1JjLhGGMVnQW6tSnpwDX8wce",
    pricing: {
      tier1Unit: 11.99,
      tier2Unit: 7.99,
      tier3Unit: 5.99,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 11.99,
      tier2Unit: 7.99,
      tier3Unit: 5.99,
    },
  },
  {
    name: "Annual Subscription",
    type: "basic",
    cycle: "annual",
    currency: "aud",
    currencySymbol: "A$",
    id: "annual-aud",
    value: "price_1OFfeSGMVnQW6tSnHbrRrt5t",
    pricing: {
      tier1Unit: 107.91,
      tier2Unit: 71.91,
      tier3Unit: 53.91,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 134.99,
      tier2Unit: 91.08,
      tier3Unit: 68.28,
    },
  },
];
