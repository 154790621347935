export default function getAllUrlParams(url) {
  let params = {};
  let parser = new URL(url);
  let queryString = new URLSearchParams(parser.search);

  queryString.forEach((value, key) => {
    params[key] = value;
  });

  return params;
}
