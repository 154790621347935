export const appConfig = {
  siteName: "guideyapp.com",
  siteUrl: "https://guideyapp.com",
  email: "hello@guideyapp.com",
  title: "Guidey",
  brandName: "Guidey",
  brandMark: "Guidey™",
  registeredCompanyNumber: "13394346",
  foundingDate: "2021-05-13",
  foundingLocation: "London",
  foundingCountry: "England",
  registrationCountry: "England",
  address: {
    addressCountry: "UK",
    addressLocality: "London",
    addressRegion: "Greater London",
    postalCode: "WC2H 9JQ",
  },
  logoUrl: "https://guideyapp.com/guidey-logo.svg",
  logoWidth: "600",
  logoHeight: "190",
  description: "The #1 digital guidebook for short term rentals",
  shortDescription: "Award winning digital property guides",
  longDescription: "Award winning digital property guides",
  locale: "en",
  defaultLanguage: "en",
  defaultCurrency: "usd",
  supportedCurrencies: ["usd", "gbp", "eur", "aud"],
  instagramUrl: "https://www.instagram.com/airguideapp",
  linkedInUrl: "https://www.linkedin.com/company/airguideapp",
  facebookUrl: "https://www.facebook.com/airguideapp",
  ratingValue: "4.8",
  bestRating: "5",
  ratingCount: "58",
  googleAnalyticsId: "G-K0DS9985JZ",
  cdnUrl: "https://cdn.guideyapp.com",
  dashboardUrl: "https://my.guideyapp.com",
  guideUrl: "https://guidey.app",
  referralUrl: "https://guideyapp.com",
  liveChatWidget: "tidio",
  liveChatWidgetId: "geiuzaxejdaoswynvecseaxionsablmv",
 // liveChatWidgetId: "e7fdxwhdb6iagsbkcms4l6vuneyrsruc", - Temporary account id
};
