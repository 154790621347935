import React from "react";

function WalletIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={655.736}
      height={655.736}
      viewBox="0 0 655.736 655.736"
      {...props}
    >
      <path d="M593.068 166.135v-142.8c0-11.261-4.814-17.544-15.3-21.665-8.079-3.183-15.933-1.286-25.5 1.265l-530.4 163.2v448.8c0 22.521 18.258 40.801 40.8 40.801h530.4c22.542 0 40.8-18.279 40.8-40.801v-408c0-22.521-18.258-40.8-40.8-40.8zm-40.799-122.4v122.4h-397.8l397.8-122.4zm40.799 387.601v20.4h-81.601v-40.8h81.601v20.4zm0-61.201h-102c-11.261 0-20.4 9.141-20.4 20.4v81.6c0 11.262 9.14 20.4 20.4 20.4h102v102c0 11.262-9.14 20.4-20.4 20.4h-489.6c-11.261 0-20.4-9.139-20.4-20.4v-387.6h510c11.261 0 20.4 9.119 20.4 20.4v142.8z" />
    </svg>
  );
}

export default WalletIcon;
