import React from "react";
import { connect } from "react-redux";
// import { workflowApiRoot } from "../constants.js";
import { deleteUploadedFile } from "../redux/actions/userActions";
import { cdnRootUrl } from "../constants.js";
import { deleteContent } from "../redux/actions/userActions";
import { getCustomContent } from "../redux/actions/userActions";
import { getSharedContent } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import { setCustomContentLoaded } from "../redux/actions/userActions";
import LoadingButtonTwo from "./LoadingButtonTwo";
import "../styles/InfoItem.css";

class DeleteContentButton extends React.Component {
  state = {
    payload: {},
    cancelIsActive: false
  };

  onDeleteContent = () => {
    this.props.onStateChange(false);
    this.setState(() => ({
      cancelIsActive: true
    }));
    //  document
    //    .getElementById("delete-confirmation")
    //    .scrollIntoView({ behavior: "smooth" });
  };

  onClickNoCancel = () => {
    this.props.onStateChange(true);
    this.setState(() => ({
      cancelIsActive: false
    }));
  };

  onSubmit = (e) => {
    const {
      ContentId,
      propertyId,
      SharedContentProfileId,
      ItemLabel,
      userId,
      ItemData,
      popUpClose,
      subContent
    } = this.props;
    // const contentIdForBubble = { contentId: ContentId };
console.log("subContent in delete button", subContent)
    //console.log("this.props", this.props)
    e.preventDefault();

    const cdnRootString = `${cdnRootUrl}/`;
    const cdnImageStorageString =
      ItemData && ItemData.DetailImage
        ? `${ItemData.DetailImage.slice(
            ItemData.DetailImage.indexOf(cdnRootString) + cdnRootString.length
          )}`
        : null;

    const deleteImageFileString =
      ItemData && ItemData.DetailImage
        ? ItemData.DetailImage.split("/")[0] === "https:"
          ? cdnImageStorageString
          : `https:${ItemData.DetailImage}`
        : null;

    const cdnVideoStorageString =
      ItemData && ItemData.DetailVideo
        ? `${ItemData.DetailVideo.slice(
            ItemData.DetailVideo.indexOf(cdnRootString) + cdnRootString.length
          )}`
        : null;

    const deleteVideoFileString =
      ItemData && ItemData.DetailVideo
        ? ItemData.DetailVideo.split("/")[0] === "https:"
          ? cdnVideoStorageString
          : `https:${ItemData.DetailVideo}`
        : null;

    const cdnDocumentStorageString =
      ItemData && ItemData.DetailDocument
        ? `${ItemData.DetailDocument.slice(
            ItemData.DetailDocument.indexOf(cdnRootString) +
              cdnRootString.length
          )}`
        : null;

    const deleteDocumentFileString =
      ItemData && ItemData.DetailDocument
        ? ItemData.DetailDocument.split("/")[0] === "https:"
          ? cdnDocumentStorageString
          : `https:${ItemData.DetailDocument}`
        : null;

    if (deleteImageFileString) {
      this.props.deleteUploadedFile(deleteImageFileString);
    }
    if (deleteVideoFileString) {
      this.props.deleteUploadedFile(deleteVideoFileString);
    }
    if (deleteDocumentFileString) {
      this.props.deleteUploadedFile(deleteDocumentFileString);
    }
    this.props.deleteContent(
      ContentId,
      ItemLabel,
      propertyId,
      SharedContentProfileId,
      popUpClose,
      subContent,
      userId
    );
  };

  render() {
    const { SaveStatus, ItemUniqueId } = this.props;
    const ItemLabel = ItemUniqueId;

    return (
      <>
        {!this.state.cancelIsActive && (
          <button
            onClick={this.onDeleteContent}
            className="delete-button-inverted"
          >
            Delete item
          </button>
        )}

        <div className="infoitem-container" id="delete-confirmation">
          {this.state.cancelIsActive && (
            <>
              <div className="infoitem-form-container">
                <div style={{ height: "15px" }}> </div>
                <div className="infoitem-form-label">
                  Are you sure you want to delete this item? This action{" "}
                  <strong>cannot be undone </strong>and all item data will be
                  lost.
                </div>
                <div className="cancel-options-buttons-container">
                  <button
                    onClick={this.onClickNoCancel}
                    className="keep-button-inverted"
                  >
                    Keep item
                  </button>

                  <LoadingButtonTwo
                    ItemLabel={ItemLabel}
                    ClickFunction={this.onSubmit}
                    StaticText={"Delete item"}
                    LoadingText={"Deleting"}
                    Style={"delete"}
                    Loading={
                      SaveStatus === `${ItemLabel}-fetching` ? true : false
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteContent: (
    ContentId,
    ItemLabel,
    propertyId,
    SharedContentProfileId,
    deleteImageFileString,
    deleteVideoFileString,
    deleteDocumentFileString,
    subContent,
    userId
  ) =>
    dispatch(
      deleteContent(
        ContentId,
        ItemLabel,
        propertyId,
        SharedContentProfileId,
        deleteImageFileString,
        deleteVideoFileString,
        deleteDocumentFileString,
        subContent,
        userId
      )
    ),
  deleteUploadedFile: (fileUrl) => dispatch(deleteUploadedFile(fileUrl)),
  getCustomContent: (propertyId, ItemLabel) =>
    dispatch(getCustomContent(propertyId, ItemLabel)),
  getSharedContent: (SharedContentProfileId, ItemLabel) =>
    dispatch(getSharedContent(SharedContentProfileId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
  setCustomContentLoaded: (payload, propertyId) =>
    dispatch(setCustomContentLoaded(payload, propertyId))
});

export default connect(null, mapDispatchToProps)(DeleteContentButton);
