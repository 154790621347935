import React from "react";
import BellIcon from "../icons/BellIcon";
import ToolTipIcon from "../icons/ToolTipIcon";
import "../styles/NotificationBanner.css";

export default function NotificationBanner(props) {
  const {
    Target,
    Text,
    DisplayButton,
    ExternalLink,
    ButtonText,
    IconType,
    IconColour,
    TextColour,
    ButtonColour,
    BorderColour
  } = props;

  const onClickInternal = () => {
    props.history.push({
      pathname: Target
    });
  };

  const onClickExternal = () => {
    window.open(`${Target}`);
  };

  return (
    <>
      <div className="notification-banner-container">
        <div
          style={{
            borderLeftColor: `${BorderColour}`
          }}
          className={
            Target ? "notification-banner-clickable" : "notification-banner"
          }
          onClick={
            Target && !ExternalLink
              ? onClickInternal
              : Target && ExternalLink
              ? onClickExternal
              : null
          }
        >
          <div className="notification-icon-and-text-wrapper">
            <div className="notification-icon-wrapper">
              {IconType === "Bell" && (
                <BellIcon style={{ fill: `${IconColour}` }} />
              )}
              {IconType === "ToolTip" && (
                <ToolTipIcon style={{ fill: `${IconColour}` }} />
              )}
            </div>
            <div
              style={{ color: `${TextColour}` }}
              className="notification-banner-text"
            >
              {Text}
            </div>
          </div>

          {DisplayButton && (
            <div className="notification-banner-button-container">
              <button
                style={{ background: `${ButtonColour}` }}
                className="notification-banner-button"
              >
                {ButtonText}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
