import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import { liveRootUrl } from "../constants.js";
import InfoItemCheckMark from "./InfoItemCheckMark";
// import FormattedDaT from "./FormattedDaT";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/InfoItem.css";

//ReactGA.initialize(googleAnalyticsId);

export default class SetupComplete extends React.Component {
  // Scroll to top on page load & track page path
  componentDidMount() {
    window.scrollTo(0, 0);
   // ReactGA.pageview(window.location.pathname + window.location.search);
  }
  componentDidUpdate = () => {
  //  ReactGA.pageview(window.location.pathname + window.location.search);
  };

  onDashboardClick = () => {
    this.props.history.push("/");
  };

  render() {
    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];
    const { propertyData, systemContent } = this.props; // userData

    // Extract placeholder property details from SystemContent
    const propertyDetailsPlaceholderFiltered = systemContent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "property-details-placeholder";
      }
    );
    const propertyDetailsPlaceholder = propertyDetailsPlaceholderFiltered[0];

    // Use Detail label to lookup custom content
    const propertyDataFiltered = propertyData.filter((Item) => {
      return Item.PropertyID === vanityPropertyId;
    });
    const propertyDetails = propertyDataFiltered[0];

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="setupflow-heading">
              {propertyDetails.PropertyTitle
                ? propertyDetails.PropertyTitle
                : propertyDetailsPlaceholder.DetailTitle}{" "}
              is live!
            </div>
            <img
              className="infopage-image"
              src={
                propertyDetails.PropertyCoverImage
                  ? propertyDetails.PropertyCoverImage
                  : propertyDetailsPlaceholder.DetailImage
              }
              alt={
                propertyDetails.PropertyTitle
                  ? propertyDetails.PropertyTitle
                  : propertyDetailsPlaceholder.DetailTitle
              }
            />

            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={true}
                IsRequired={true}
                IsCustomContent={true}
                ItemHeading={
                  "Congratulations, your airguide™ digital guidebook is now live. Your FREE trial has been activated and will end in 30 days."
                }
                //   FormattedDaT={
                //        <FormattedDaT DateInput={userData.SubscriptionTrialEnd} />
                //      }
              />
            </div>

            <a
              href={`${liveRootUrl}${vanityPropertyId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="create-new-button">
                View your airguide™ digital guidebook
              </button>
            </a>

            <div className="infoitem-value">
              Here is your unique link, you can copy this and share it with your
              guests:
            </div>

            <div className="infoitem-value">
              <a
                href={`${liveRootUrl}${vanityPropertyId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>{`${liveRootUrl}${vanityPropertyId}`}</div>
              </a>
            </div>

            <div className="infoitem-value">
              You can edit the details and add more information, images, videos
              and documents through your personal dashboard.
            </div>
            <button
              className="create-new-button"
              onClick={this.onDashboardClick}
            >
              {" "}
              Go to dashboard{" "}
            </button>
          </div>
        </div>
      </>
    );
  }
}
