import React from "react";
import "../styles/DisplayCanvas.css";
import "../styles/ButtonBanners.css";
import ButtonBanner from "./ButtonBanner";
import GraphIcon from "../icons/GraphIcon";

export default class DashboardMarketing extends React.Component {
  render(props) {
    const { SectionHeading } = this.props;

    return (
      <>
        <div className="button-banner-section-outer-container">
          <div className="button-banner-section-container">
            <h3> {SectionHeading} </h3>

            <div className="button-banners-container">
              <div className="button-banner-container-1">
                <ButtonBanner
                  Icon={GraphIcon}
                  Heading={"Guide Analytics"}
                  SubHeading={"View your app performance"}
                  Target={"guide-analytics"}
                  Callout={""}
                  {...this.props}
                />
              </div>

              {
                // <div className="button-banner-container-2">
                //   <ButtonBanner
                //    Icon={UserIcon}
                //   Heading={"Customers"}
                //   SubHeading={"View and download customer data"}
                //   Target={"customers"}
                //  {...this.props}
                // />
                // </div>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}
