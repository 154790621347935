import React from "react";
//import { connect } from "react-redux";
//import { updateProperty } from "../redux/actions/userActions";
//import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItem.css";
import { googleMapsApiKey, whatThreeWordsApiKey } from "../constants.js";
import Script from "react-load-script";
import MapPins from "../pins/MapPins.js";

export default class InfoItemMultiMapManual extends React.Component {
  state = {
    lat: this.props.GeoLocationLat,
    lng: this.props.GeoLocationLng,
    dragMessage: "Drag marker to select exact location",
    showDragMessage: false,
    locationSet:
      this.props.ItemData &&
      this.props.ItemData.GeoLocationLat &&
      this.props.ItemData.GeoLocationLng
        ? true
        : false
  };

  handleScriptLoad = () => {
    this.createMap();
  };

  createMap = () => {
    const { CategoryLabel, ItemLabel } = this.props;
    // console.log("ItemLabel", ItemLabel);
    var mapPin = MapPins.default;

    if (CategoryLabel === "attraction") {
      mapPin = MapPins.tourist_attraction;
    }
    if (CategoryLabel === "parks") {
      mapPin = MapPins.park;
    }
    if (CategoryLabel === "golf") {
      mapPin = MapPins.golf_course;
    }
    if (CategoryLabel === "beaches") {
      mapPin = MapPins.beach;
    }
    if (CategoryLabel === "food-and-drink") {
      mapPin = MapPins.restaurant;
    }
    if (CategoryLabel === "shopping") {
      mapPin = MapPins.shop;
    }

    this.googlemap = new window.google.maps.Map(
      document.getElementById(`${ItemLabel}-map`),
      {
        zoom: 5,
        center: {
          lat: this.state.lat,
          lng: this.state.lng
        },
        disableDefaultUI: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: true,
        gestureHandling: "greedy",
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "on"
              }
            ]
          }
        ]
      }
    );

    this.marker = new window.google.maps.Marker({
      position: this.googlemap.getCenter(),
      map: this.googlemap,
      icon: {
        url: mapPin,
        scaledSize: new window.google.maps.Size(40, 54.5)
      },
      optimized: false,
      clickable: false,
      draggable: true
    });

    this.marker.addListener("dragstart", () => {
      this.setState({
        dragMessage: "Dragging...",
        showDragMessage: true,
        locationSet: true
      });
    });

    this.marker.addListener("dragend", (evt) => {
      const latitude = evt.latLng.lat();
      const longitude = evt.latLng.lng();

      //   this.props.onStateChange({
      //                  geoLocationLat: latitude,
      //                 geoLocationLng: longitude,
      //                  whatThreeWordsLocation: this.state.whatThreeWords
      //               });

      this.setState({
        //  lat: evt.latLng.lat(),
        //  lng: evt.latLng.lng(),
        dragMessage: "Drag marker to select exact location",
        showDragMessage: false
      });

      fetch(
        `https://api.what3words.com/v3/convert-to-3wa?coordinates=${latitude}%2C${longitude}&key=${whatThreeWordsApiKey}`
      )
        .then((response) => response.json())
        .then((data) => {
          //  console.log("w3w response data", data);
          if (data) {
            //  this.setState({
            //  whatThreeWords: data.words,
            //   nearestPlace: data.nearestPlace,
            //   GeoLocationCountry: data.country
            // });

            this.props.onStateChange({
              geoLocationLat: latitude,
              geoLocationLng: longitude,
              whatThreeWordsLocation: data.words,
              whatThreeWordsNearestPlace: data.nearestPlace
            });
          }
          if (!data) {
            // this.setState({
            //   whatThreeWords: "",
            //   nearestPlace: "",
            //   GeoLocationCountry: ""
            //  });
            this.props.onStateChange({
              geoLocationLat: latitude,
              geoLocationLng: longitude,
              whatThreeWordsLocation: "",
              whatThreeWordsNearestPlace: ""
            });
          }
        });
    });
  };

  render() {
    const { ItemLabel } = this.props;

    return (
      <>
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
          onLoad={this.handleScriptLoad}
        />

        <div className="infoitem-form-inner-container">
          {this.state.showDragMessage && (
            <label className="infoitem-form-label-map">
              Drop marker to select property location...
            </label>
          )}

          {!this.state.showDragMessage && !this.state.whatThreeWordsSearch && (
            <>
              {this.state.locationSet && (
                <label className="infoitem-form-label-map">
                  Marker Location (To edit, drag marker to exact location){" "}
                </label>
              )}
              {!this.state.locationSet && (
                <label className="infoitem-form-label">
                  {" "}
                  {
                    "Marker Location (To edit, drag marker to exact location)"
                  }{" "}
                  <span style={{ color: "var(--UIcolour5" }}>
                    {" "}
                    Please note: this place won't appear on the map until you
                    set its location
                  </span>
                </label>
              )}
            </>
          )}

          <>
            <div className="infoitem-form-inner-container-map">
              <div
                id={`${ItemLabel}-map`}
                style={{
                  width: "300px",
                  height: "300px",
                  marginTop: "8px",
                  marginBottom: "12px"
                }}
              />
            </div>
          </>
        </div>
      </>
    );
  }
}
