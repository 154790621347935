import React from "react";
import { connect } from "react-redux";
import { setSaveStatus } from "../redux/actions/userActions";
import InfoItemCheckMark from "./InfoItemCheckMark";
import ListItemImage from "./ListItemImage";
import "../styles/InfoItem.css";

class InfoItemStatic extends React.Component {
  render() {
    const {
      ItemHeading,
      ItemDescription,
      ValueA,
      IsChecked,
      IsRequired,
      ItemData
    } = this.props;

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={IsChecked}
                IsRequired={IsRequired}
                IsCustomContent={ValueA ? true : false}
                ItemHeading={ItemHeading}
              />
            </div>
          </div>

          {!IsChecked && (
            <div className="infoitem-preview-container">
              <label className="infoitem-form-label">{ItemDescription}</label>
            </div>
          )}

          <div className={"show"}>
            <div className="infoitem-form-container">
              {ItemData !== null &&
                ItemData.map((item) => (
                  <div key={item._id} className="infoitem-listitem-static">
                    <div className="infoitem-listitem-image-and-detail-container">
                      <ListItemImage DetailImage={item.PropertyCoverImage} />
                      <div className="infoitem-listitem-detail-container">
                        <div className="infoitem-listitem-heading">
                          {item.PropertyTitle}
                        </div>
                        <div className="infoitem-listitem-text">
                          {item.PropertyDisplayAddress}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemStatic);
