import React from "react";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import CrossIcon from "../icons/CrossIcon";
import CirclePlusIcon from "../icons/CirclePlusIcon";
// import NoEntryIcon from "../icons/NoEntryIcon";
import "../styles/InfoItemCheckMark.css";

export default class InfoItemCheckMark extends React.Component {
  render() {
    const {
      IsChecked,
      IsCustomContent,
      ItemHeading,
      IsRequired,
      IsNewCustomItem,
      FormattedDaT
    } = this.props;
    return (
      <div className="infoitem-checkmark-outer-wrapper">
        {IsChecked && IsCustomContent && (
          <div className="infoitem-checkmark-wrapper">
            <div className="infoitem-checkmark-icon-wrapper-checked">
              <CheckmarkIcon className="infoitem-checkmark-icon-checked" />
            </div>
            <div className="infoitem-checkmark-description-wrapper">
              <div className="report-checkitem-heading">
                {ItemHeading}
                {FormattedDaT}
              </div>
            </div>
          </div>
        )}

        {IsChecked && !IsCustomContent && (
          <div className="infoitem-checkmark-wrapper">
            <div className="infoitem-checkmark-icon-wrapper-semi-checked">
              <CheckmarkIcon className="infoitem-checkmark-icon-checked" />
            </div>
            <div className="infoitem-checkmark-description-wrapper">
              <div className="report-checkitem-heading">{ItemHeading}</div>
            </div>
          </div>
        )}

        {!IsChecked && IsRequired && (
          <div className="infoitem-checkmark-wrapper">
            <div className="infoitem-checkmark-icon-wrapper-unchecked-required">
              <CrossIcon className="infoitem-checkmark-icon-unchecked" />
            </div>

            <div className="infoitem-checkmark-description-wrapper">
              <div className="report-checkitem-heading">{ItemHeading}</div>
            </div>
          </div>
        )}
        {!IsChecked && !IsRequired && !IsNewCustomItem && (
          <div className="infoitem-checkmark-wrapper">
            <div className="infoitem-checkmark-icon-wrapper-unchecked">
              <CrossIcon className="infoitem-checkmark-icon-unchecked" />
            </div>

            <div className="infoitem-checkmark-description-wrapper">
              <div className="report-checkitem-heading">{ItemHeading}</div>
            </div>
          </div>
        )}

        {!IsChecked && !IsRequired && IsNewCustomItem && (
          <div className="infoitem-checkmark-wrapper">
            <div className="infoitem-checkmark-icon-wrapper-unchecked-no-margin">
              <CirclePlusIcon className="infoitem-checkmark-icon-unchecked-no-margin" />
            </div>

            <div className="infoitem-checkmark-description-wrapper">
              <div className="report-checkitem-heading">{ItemHeading}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
