export default function formatDatLabel(dateInput) {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var d = new Date(dateInput);
  var day = days[d.getDay()];

  var hr = d.getHours();
  if (hr < 1) {
    hr = "00";
  }
  var min = d.getMinutes();
  if (min < 10) {
    min = "0" + min;
  }
  var ampm = "am";
  if (hr === 12) {
    hr -= 0;
    ampm = "pm";
  }
  if (hr > 12) {
    hr -= 12;
    ampm = "pm";
  }

  var date = d.getDate();
  var month = months[d.getMonth()];
  var year = d.getFullYear();
  var formattedDaT =
    day +
    " " +
    // +
    date +
    " " +
    month +
    " " +
    year +
    ", " +
    hr +
    ":" +
    min +
    ampm;

  return formattedDaT;
}
