import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import { connect } from "react-redux";
import { createManager } from "../redux/actions/userActions";
import "../styles/InfoItem.css";

//ReactGA.initialize(googleAnalyticsId);

class CreateManagerModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: { ManagerVanityID: this.generateRandomNumber(8) },
      //    payload: {},
      //    managerVanityId: this.generateRandomNumber(8),
      redirect: false
    };
  }

  componentDidMount = () => {
   // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  generateRandomNumber = (length) => {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    var payloadB = { ...this.state.payload };
    payloadB[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA, ...payloadB }
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { ItemLabel, userId } = this.props;
    this.props.createManager(this.state.payload, ItemLabel, userId);
    this.setState(() => ({
      redirect: true
    }));
  };

  onRedirect = () => {
    //  this.props.history.push(
    //  `/manager-profile/${this.state.payload.ManagerVanityID}`
    //  );

    this.props.history.push({
      pathname: `/manager-profile/${this.state.payload.ManagerVanityID}`,
      state: { ManagerVanityID: this.state.payload.ManagerVanityID }
    });
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      InputLabelA,
      PayloadA,
      ValueA,
      InputLabelB,
      ValueB,
      PayloadB
    } = this.props;
    // console.log(this.state);
    if (this.state.redirect) {
      this.onRedirect();
    }

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-form-container">
            <form className="infoitem-form" onSubmit={this.onSubmit}>
              <div className="infoitem-form-inner-container-left">
                <label className="infoitem-form-label"> {InputLabelA} </label>
                <input
                  type="text"
                  name={PayloadA}
                  className="infoitem-text-input"
                  placeholder={`Enter ${InputLabelA}`}
                  defaultValue={ValueA}
                  value={this.state.payload.name}
                  onChange={this.onChange}
                  required
                />
              </div>

              <div className="infoitem-form-inner-container-right">
                <label className="infoitem-form-label"> {InputLabelB} </label>
                <input
                  type="text"
                  name={PayloadB}
                  className="infoitem-text-input"
                  placeholder={`Enter ${InputLabelB}`}
                  defaultValue={ValueB}
                  value={this.state.payload.name}
                  onChange={this.onChange}
                  required
                />
              </div>

              <input
                type="submit"
                className="infoitem-save-button"
                value={
                  SaveStatus === `${ItemLabel}-fetching`
                    ? "Saving..."
                    : "Continue"
                }
              />
            </form>
          </div>
        </div>

        <div className="infoitem-section-break-wrapper">
          <div className="infoitem-section-break"></div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createManager: (managerInfo, ItemLabel, userId) =>
    dispatch(createManager(managerInfo, ItemLabel, userId))
});

export default connect(null, mapDispatchToProps)(CreateManagerModule);
