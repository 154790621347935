import React from "react";
import "../styles/TableStyles.css";

const Table = ({ data, fields }) => {
  const renderHeading = ([key, value]) => (
    <th className="table__heading" role="columnheader" key={key}>
      {value}
    </th>
  );

  const renderHead = () => (
    <thead className="table__head">
      <tr className="table__row" role="row">
        {Object.entries(fields).map(renderHeading)}
      </tr>
    </thead>
  );

  const renderRow = (item) => (
    <tr className="table__row" role="row" key={item.dateLookup}>
      {Object.entries(fields).map(([key, value]) => (
        <td className="table__cell" role="cell" data-label={value} key={key}>
          {item[key]}
        </td>
      ))}
    </tr>
  );

  const renderBody = () => (
    <tbody className="table__body">{data.map(renderRow)}</tbody>
  );

  return (
    <table className="table" role="table">
      {renderHead()}
      {renderBody()}
    </table>
  );
};

export default Table;
