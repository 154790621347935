import React from "react";
import { connect } from "react-redux";
import { updateUser } from "../redux/actions/userActions";
import { updateCompany } from "../redux/actions/userActions";
import { updateManager } from "../redux/actions/userActions";
import { updateProperty } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import InfoItemCheckMark from "./InfoItemCheckMark";
import "../styles/InfoItem.css";

class InfoItemTickBox extends React.Component {
  state = {
    isEditing: this.props.IsEditing,
    payload: { [this.props.PayloadA]: this.props.IsChecked }
  };

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked;
    var payloadA = { ...this.state.payload };
    payloadA[target.name] = value;
    this.setState(() => ({
      payload: { ...payloadA }
    }));
  };

  onEdit = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-editing`);
  };

  onCancel = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-idle`);
  };

  onSubmit = (e) => {
    const {
      userId,
      companyId,
      managerId,
      propertyId,
      ItemLabel,
      ItemType
    } = this.props;

    e.preventDefault();
    if (ItemType === "company") {
      this.props.updateCompany(
        this.state.payload,
        companyId,
        ItemLabel,
        userId
      );
    }
    if (ItemType === "manager") {
      this.props.updateManager(
        this.state.payload,
        managerId,
        ItemLabel,
        userId
      );
    }
    if (ItemType === "property") {
      this.props.updateProperty(this.state.payload, propertyId, ItemLabel);
    }
    if (ItemType === "user") {
      this.props.updateUser(this.state.payload, userId, ItemLabel);
      console.log("didrun", this.state.payload, userId, ItemLabel);
    }
    if (ItemType === "default-manager") {
      this.props.updateManager(
        this.state.payload,
        managerId,
        ItemLabel,
        userId
      );
      this.props.updateUser(
        { UserTel: this.state.payload.PropertyMgrTel },
        userId,
        ItemLabel
      );
    }
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      ItemHeading,
      ItemDescriptionOne,
      ItemDescriptionTwo,
      ValueA,
      IsChecked,
      IsRequired,
      FalseText,
      PayloadA,
      IsNewCustomItem,
      TickBoxText
    } = this.props;

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={IsChecked}
                IsRequired={IsRequired}
                IsCustomContent={ValueA ? true : false}
                ItemHeading={ItemHeading}
                IsNewCustomItem={IsNewCustomItem}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div className="infoitem-value">
            {SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching`
              ? `${ItemDescriptionOne}`
              : ValueA
              ? ValueA
              : FalseText}
          </div>

          <div className="infoitem-value">
            {SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching`
              ? `${ItemDescriptionTwo}`
              : ""}
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "show"
                : "hide"
            }
          >
            <div className="infoitem-form-container">
              <form className="infoitem-form" onSubmit={this.onSubmit}>
                <div className="infoitem-form-inner-container-textarea">
                  <div className="form-checkbox-and-label-outer-wrapper">
                    <label className="form-checkbox-and-label-inner-wrapper">
                      <input
                        name={PayloadA}
                        type="checkbox"
                        value={this.state.payload[PayloadA]}
                        checked={this.state.payload[PayloadA]}
                        onChange={this.handleCheckboxChange}
                      />
                      {TickBoxText}
                      <span className="form-checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="infoitem-form-inner-container-left">
                  <input
                    type="submit"
                    className="infoitem-save-button"
                    value={
                      SaveStatus === `${ItemLabel}-fetching`
                        ? "Saving..."
                        : "Save"
                    }
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userInfo, userId, ItemLabel) =>
    dispatch(updateUser(userInfo, userId, ItemLabel)),
  updateCompany: (companyInfo, companyId, ItemLabel, userId) =>
    dispatch(updateCompany(companyInfo, companyId, ItemLabel, userId)),
  updateManager: (managerInfo, managerId, ItemLabel, userId) =>
    dispatch(updateManager(managerInfo, managerId, ItemLabel, userId)),
  updateProperty: (propertyInfo, propertyId, ItemLabel) =>
    dispatch(updateProperty(propertyInfo, propertyId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemTickBox);
