import {
  workflowApiRoot,
  apiSecretToken,
  pusherAppId,
  pusherApiKey,
  pusherSecretKey
} from "../constants.js";
import { uploadMessageFiles } from "../data/uploadMessageFiles";
import { refreshConversations } from "../data/refreshConversations";
import { pushConversationMessage } from "../data/pushConversationMessage";
import { setLoadingStatus } from "../redux/actions/userActions";

export const createConversationMessage = (
  dispatch,
  payload,
  buttonId,
  propertyId,
  commsChannelId
) => {
  //  console.log("Inbound message sent!");
  // console.log(payload);

  dispatch(setLoadingStatus(`${buttonId}-loading`));

  fetch(`${workflowApiRoot}send-outbound-message`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${apiSecretToken}`
    },
    body: JSON.stringify(payload)
  })
    .then((response) => response.json())

    .then((results) => {
      // console.log("Submission Response", results);

      if (results.status === "success") {
        //   console.log("success", results);
        const linkedUserId = results.response.linkedUserId;
        const messageId = results.response.conversationMessageId;
        if (payload.DetailFiles) {
          //  console.log(
          //   "payload.DetailFiles in createConversationMessage",
          //   payload.DetailFiles
          // );
          const detailFiles = payload.DetailFiles;

          uploadMessageFiles(
            linkedUserId,
            messageId,
            detailFiles,
            dispatch,
            buttonId
          );
        }
        if (!payload.DetailFiles) {
          refreshConversations(dispatch, linkedUserId, buttonId);
        }
        //  pushConversationMessage({
        //   propertyId: propertyId,
        //   commsChannelId: commsChannelId
        //  });

        pushConversationMessage({
          propertyId: propertyId,
          commsChannelId: commsChannelId,
          pushAppId: pusherAppId,
          pushApiKey: pusherApiKey,
          pushSecretKey: pusherSecretKey
        });
      }

      if (results.statusCode === 400) {
        //  console.log("error", results);
        dispatch(setLoadingStatus(`${buttonId}-loaded`));
        //  this.setState(() => ({
        //    submissionResponseStatus: "error",
        //   submissionResponseReason: results.reason
        //  }));
      }
    });
};
