import React from "react";
import { connect } from "react-redux";
import { autoLogin } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import { workflowApiRoot } from "../constants.js";
import InfoItemCheckMark from "./InfoItemCheckMark";
import "../styles/InfoItem.css";

class SetupManager extends React.Component {
  constructor(props) {
    super(props);
    const { propertyData } = this.props;

    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];

    // Use synthetic property id to lookup property details
    const propertyDataFiltered = propertyData.filter((Item) => {
      return Item.PropertyID === vanityPropertyId;
    });
    const propertyId = propertyDataFiltered[0]._id;

    this.state = {
      payload: {
        LinkedPropertyID: vanityPropertyId,
        LinkedProperty: propertyId,
        ManagerVanityID: this.generateRandomNumber(8)
      }
    };
  }

  generateRandomNumber = (length) => {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    var payloadB = { ...this.state.payload };
    payloadB[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA, ...payloadB }
    }));
  };

  onEdit = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-editing`);
  };

  onCancel = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-idle`);
  };

  onSubmit = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();

    this.props.setSaveStatus(`${ItemLabel}-fetching`);
    fetch(`${workflowApiRoot}create-default-manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`
      },
      body: JSON.stringify(this.state.payload)
    })
      .then((response) => response.json())
      .then((results) => {
        //   console.log(results);
        if (results.status !== "success") {
          this.props.setSaveStatus(`${ItemLabel}-error`);
        }
        if (results.status === "success") {
          this.props.autoLogin(ItemLabel);
        }
      });
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      InputLabelA,
      ValueA,
      PayloadA,
      InputLabelB,
      ValueB,
      PayloadB,
      //  postResponseData,
      IsRequired,
      ItemHeading,
      ItemDescription,
      FalseText,
      IsNewCustomItem
    } = this.props;

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={ValueA || ValueB ? true : false}
                IsRequired={IsRequired}
                IsCustomContent={ValueA ? true : false}
                ItemHeading={ItemHeading}
                IsNewCustomItem={IsNewCustomItem}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div className="infoitem-value">
            {SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching`
              ? `${ItemDescription}`
              : ValueA || ValueB
              ? ValueA + " " + ValueB
              : FalseText}
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "show"
                : "hide"
            }
          >
            <div className="infoitem-form-container">
              <form className="infoitem-form" onSubmit={this.onSubmit}>
                <div className="infoitem-form-inner-container-left">
                  <label className="infoitem-form-label"> {InputLabelA} </label>
                  <input
                    type="text"
                    name={PayloadA}
                    className="infoitem-text-input"
                    placeholder={`Enter ${InputLabelA}`}
                    defaultValue={ValueA}
                    value={this.state.payload.name}
                    onChange={this.onChange}
                    required
                  />
                </div>

                <div className="infoitem-form-inner-container-right">
                  <label className="infoitem-form-label"> {InputLabelB} </label>
                  <input
                    type="text"
                    name={PayloadB}
                    className="infoitem-text-input"
                    placeholder={`Enter ${InputLabelB}`}
                    defaultValue={ValueB}
                    value={this.state.payload.name}
                    onChange={this.onChange}
                    required
                  />
                </div>

                <input
                  type="submit"
                  className="infoitem-save-button"
                  value={
                    SaveStatus === `${ItemLabel}-fetching`
                      ? "Saving..."
                      : "Save"
                  }
                />
              </form>
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  autoLogin: (ItemLabel) => dispatch(autoLogin(ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(SetupManager);
