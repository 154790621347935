import React, { useState, useEffect, useRef, forwardRef } from "react";
import { connect } from "react-redux";
import { updateListRank, setSaveStatus } from "../redux/actions/userActions";
import InfoItemPlaceListItem from "./InfoItemPlaceListItem";
import ToolTip from "./ToolTip.js";
import { List, arrayMove } from "react-movable";
import "../styles/InfoItem.css";

function InfoItemPlacesAutoTwo(props) {
  const {
    ItemLabel,
    propertyId,
    SharedContentProfileId,
    updateListRank,
    SaveStatus,
    userId,
    propertyDetails,
    systemContent,
    customContent,
    sharedContent,
    isComponentShared,
    CategoryLabel,
    customPlaceContent,
    sharedLocalAreaContentProfileData
    //  resetContent,
    //  resetContentChange
  } = props;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(customPlaceContent);
  const [updateList, setUpdateList] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const saveStatusTrimmed = SaveStatus.slice(33);
  // const saveStatusLastSix = SaveStatus.substr(SaveStatus.length - 7);

  // Disable keydown event listener so component doesn't crash when text entered
  window.addEventListener("keydown", function(event) {
    event.stopImmediatePropagation();
    }, true);

  useEffect(() => {
    if (
      SaveStatus === "new-item-success" ||
      saveStatusTrimmed === "success" ||
      SaveStatus === `${ItemLabel}-success`
    ) {
      setRefreshList(true);
      //  console.log("Set Refresh List Ran");
    }
  }, [SaveStatus, ItemLabel, saveStatusTrimmed]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
      //  console.log("UsePrev Ran")
    });
    return ref.current;
  }

  const prevItems = usePrevious({ customPlaceContent });
  //console.log("items", items)
  useEffect(() => {
    if (prevItems !== customPlaceContent && refreshList) {
      setItems(customPlaceContent);
      //  console.log("Set Items ran");
      setRefreshList(false);
      setLoading(false);
    }
  }, [
    SaveStatus,
    updateList,
    prevItems,
    items,
    customPlaceContent,
    refreshList
  ]);

  useEffect(() => {
    if (prevItems !== items && updateList) {
      updateListRank("update", propertyId, SharedContentProfileId, items);
      //  console.log("Set List update ran");
      setUpdateList(false);
    }
  }, [
    ItemLabel,
    propertyId,
    SharedContentProfileId,
    updateListRank,
    updateList,
    prevItems,
    items
  ]);

  const onListUpdate = (items, oldIndex, newIndex) => {
    setItems(arrayMove(items, oldIndex, newIndex));
    setUpdateList(true);
  };

  if (items && !loading) {
    // window.scrollTo(0, 0);
    //console.log("render")
 
      return (
        <div
          id="ref1"
          style={{
            width: "100%",
            maxWidth: "960px",
            marginLeft: "0px",
            marginRight: "0px",
            backgroundColor: "#F7F7F7",
            padding: "0.5em"
          }}
        >
          <ToolTip Content={"Drag items to change the order"} />
          <List
            lockVertically
            values={items}
            onChange={({ oldIndex, newIndex }) =>
              onListUpdate(items, oldIndex, newIndex)
            }
            renderList={({ children, props, isDragged }) => (
              <ul
                {...props}
                style={{
                  padding: 0,
                  margin: 0,
                  cursor: isDragged ? "grabbing" : undefined
                }}
              >
                {children}
              </ul>
            )}
            renderItem={({ index, value, props, isDragged, isSelected }) => (
              <li
                {...props}
                key={index}
                style={{
                  ...props.style,
                  padding: "1em",
                  margin: "0.5em",
                  height: "auto",
                  minHeight: "125px",
                  listStyleType: "none",
                  cursor: isDragged ? "grabbing" : "grab",
                  border: "1px solid var(--UIcolour4)",
                  //  boxShadow: "3px 3px #AAA",
                  color: "#333",
                  borderRadius: "var(--UIborderRadius)",
                  fontFamily: "var(--UIfontType1)",
                  backgroundColor:
                    isDragged || isSelected
                      ? "var(--UIcolour6)"
                      : "var(--UIcolour0)"
                }}
              >
                <InfoItemPlaceListItem
                  //key={index}
                  Index={index}
                  ItemData={value}
                  ItemLabel={ItemLabel}
                  SharedContentProfileId={SharedContentProfileId}
                  propertyId={propertyId}
                  SaveStatus={SaveStatus}
                  userId={userId}
                  propertyDetails={propertyDetails}
                  systemContent={systemContent}
                  customContent={customContent}
                  //  customPlaceContent={customPlaceContent}
                  sharedContent={sharedContent}
                  isComponentShared={isComponentShared}
                  CategoryLabel={CategoryLabel}
                  sharedLocalAreaContentProfileData={
                    sharedLocalAreaContentProfileData
                  }
                />
              </li>
            )}
          />
        </div>
      );
  } else return <></>;
}

const mapDispatchToProps = (dispatch) => ({
  updateListRank: (ItemLabel, propertyId, sharedContentProfileId, data) =>
    dispatch(
      updateListRank(ItemLabel, propertyId, sharedContentProfileId, data)
    ),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemPlacesAutoTwo);
