import React from "react";

function BinIcon(props) {
  return (
    <svg width={753.23} height={753.23} viewBox="0 0 753.23 753.23" {...props}>
      <path d="M494.308 659.077c12.993 0 23.538-10.546 23.538-23.539V353.077c0-12.993-10.545-23.539-23.538-23.539s-23.538 10.545-23.538 23.539v282.461c0 12.993 10.544 23.539 23.538 23.539zm141.23-564.923h-141.23V47.077C494.308 21.067 473.24 0 447.23 0H306c-26.01 0-47.077 21.067-47.077 47.077v47.077h-141.23c-26.01 0-47.077 21.067-47.077 47.077v47.077c0 25.986 21.067 47.077 47.077 47.077v423.692c0 51.996 42.157 94.153 94.154 94.153h329.539c51.996 0 94.153-42.157 94.153-94.153V235.385c26.01 0 47.077-21.091 47.077-47.077V141.23c-.001-26.009-21.068-47.076-47.078-47.076zM306 70.615c0-12.993 10.545-23.539 23.538-23.539h94.154c12.993 0 23.538 10.545 23.538 23.539v23.539H306V70.615zm282.461 588.462c0 25.986-21.066 47.076-47.076 47.076H211.846c-26.01 0-47.077-21.09-47.077-47.076V235.385h423.692v423.692zM612 188.308H141.23c-12.993 0-23.538-10.545-23.538-23.539s10.545-23.539 23.538-23.539H612c12.993 0 23.538 10.545 23.538 23.539S624.993 188.308 612 188.308zM258.923 659.077c12.993 0 23.539-10.546 23.539-23.539V353.077c0-12.993-10.545-23.539-23.539-23.539s-23.539 10.545-23.539 23.539v282.461c0 12.993 10.546 23.539 23.539 23.539zm117.692 0c12.993 0 23.538-10.546 23.538-23.539V353.077c0-12.993-10.545-23.539-23.538-23.539s-23.539 10.545-23.539 23.539v282.461c.001 12.993 10.546 23.539 23.539 23.539z" />
    </svg>
  );
}

export default BinIcon;
