import React, {
  useEffect,
  useRef //, useState
} from "react"; //useLayoutEffect,
//import smoothscroll from "smoothscroll-polyfill";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { refreshConversations } from "../data/refreshConversations";
import Loading from "./Loading";
import ConversationMessageTwo from "./ConversationMessageTwo";
import ConversationResponse from "./ConversationResponse";
import BackButton from "./BackButton";
//import formatDateLabel from "../functions/formatDateLabel";
import "../styles/ConversationDetailsTwo.css";
import "../scripts/mutation_events.js";

//const latestMessage = defaultMessages.slice(-1);
//const previousMessages = defaultMessages.slice(0, -1);

function ConversationDetailsTwo(props) {
  // smoothscroll.polyfill();
  const messageEl = useRef(null);
  //const [messages, setMessages] = useState(previousMessages);

  // From Original

  // Declare conversationId
  var pathName = window.location.pathname;
  var pathParams = pathName.substring(pathName.lastIndexOf("/") + 1);
  const conversationId = pathParams;

  // Declare State and Variables
  const dispatch = useDispatch();
  const userData = useSelector(
    (state) => state.userReducer.user.userData,
    shallowEqual
  );
  const userId = userData._id;

  const conversationData = useSelector(
    (state) => state.userReducer.user.conversationData,
    shallowEqual
  );

  // console.log("conversationsArray in ConversationDetails", conversationsArray)
  const conversationsDataFiltered = conversationData
    ? conversationData.filter((item) => item._id === conversationId)[0]
    : null;

  const conversationMessages = useSelector(
    (state) => state.userReducer.user.messageData,
    shallowEqual
  );
  // console.log("messageData in ConversationDetails", conversationMessages);

  const messageDataFiltered = conversationMessages
    ? conversationMessages.filter(
        (item) => item.LinkedConversation === conversationId
      )
    : null;

  const propertyData = useSelector(
    (state) => state.userReducer.user.linkedProperties,
    shallowEqual
  );

  const linkedProperty = conversationsDataFiltered
    ? conversationsDataFiltered.LinkedProperty
    : "";

  const propertyDataFiltered =
    propertyData && linkedProperty
      ? propertyData.filter((item) => item._id === linkedProperty)
      : null;

  const propertyTitle = propertyDataFiltered
    ? propertyDataFiltered[0].PropertyTitle
    : "";

  // console.log("conversationsArray in ConversationDetails", conversationsArray)

  //console.log(
  //  "messageDataFiltered in ConversationDetails",
  //  messageDataFiltered
  // );

  // const latestMessageFiltered = messageDataFiltered
  //   ? messageDataFiltered.slice(-1)[0]
  //  : [];
  //console.log("latestMessageFiltered", latestMessageFiltered);
  // const previousMessagesFiltered = messageDataFiltered
  //  ? messageDataFiltered.slice(0, -1)
  //  : [];

  // const [messages, setMessages] = useState(messageDataFiltered);
  // const [loading, setLoading] = useState(true);
  //console.log("conversationsDataFiltered", conversationMessagesFiltered);
  // console.log("messages", messages);
  const conversationMessageCount = messageDataFiltered
    ? messageDataFiltered.length
    : null;

  const pageTitle = conversationsDataFiltered
    ? conversationsDataFiltered.Title
    : "";

  // const formattedDate = conversationsDataFiltered
  //  ? formatDateLabel(conversationsDataFiltered["Created Date"])
  //  : "";

  // const latestItemId = messageDataFiltered
  //  ? messageDataFiltered[messageDataFiltered.length - 1]._id
  //   : "";

  // const insertLatestMessage = () => {
  //   console.log("did run");
  //  return latestMessageFiltered;
  // };

  // Initial Mount
  useEffect(() => {
    var container = document.getElementById("message-container");
    if (container) {
      container.scrollTop = 0;
    }
    if (!conversationsDataFiltered || !conversationMessages) {
      refreshConversations(dispatch, userId);
    }
    //  if (conversationsDataFiltered && conversationMessages) {
    //      setMessages(messageDataFiltered);
    //  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initial Mount
  useEffect(() => {
    document.body.style.setProperty("--bodyOverflow", "hidden");
    return () => document.body.style.setProperty("--bodyOverflow", "visible");
  }, []);
  useEffect(() => {
    document.body.style.setProperty("--tidio-display", "none");
    return () => document.body.style.setProperty("--tidio-display", "block");
  }, []);

  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "auto" });
      });
    }
  }, []);

  // useEffect(() => {
  //    const generateDummyMessage = () => {
  //  setTimeout(() => {
  //    setMessages((prevMsg) => [...prevMsg, generateMessage()]);
  //  }, 50);

  //     setInterval(() => {
  //       setMessages((prevMsg) => [...prevMsg, generateMessage()]);
  //     }, 2000);
  //   };

  //   generateDummyMessage();
  // }, []);

  return (
    <div className="chat">
      <div className="chat-head">
        <BackButton
          {...props}
          PreviousPageTitle={"All Messages"}
          PreviousPagePath={"/messaging"}
          CurrentSectionTitle={pageTitle}
        />
        {`${pageTitle} - ${propertyTitle}`}
        <div style={{ width: "9.2px" }} />
      </div>

      <div id="message-container" className="messages" ref={messageEl}>
        {
          //   {messages.map((m, i) => (
          //    <div key={i} className={`msg${i % 2 !== 0 ? " dark" : ""}`}>
          //      {m}
          //    </div>
          //  ))}
        }

        {
          //  {messages.map((m, i) => (
          //   <div key={i} className={`msg${i % 2 !== 0 ? " dark" : ""}`}>
          //     {m.DetailText}
          //   </div>
          //  ))}
        }

        {messageDataFiltered &&
          //!loading &&
          messageDataFiltered.map((item, index) => (
            <div key={item._id}>
              <ConversationMessageTwo
                messageItemData={item}
                itemIsLatest={
                  index === conversationMessageCount - 1 ? true : false
                }
              />
            </div>
          ))}

        {!messageDataFiltered && (
          //|| loading
          <>
            <Loading
              LoadingText={"Loading messages..."}
              LoadingCanvas={"table-loading-canvas"}
            />
          </>
        )}
      </div>

      {conversationsDataFiltered && (
        <ConversationResponse
          conversationData={conversationsDataFiltered}
          conversationMessageCount={conversationMessageCount}
        />
      )}
    </div>
  );
}

export default ConversationDetailsTwo;
