import * as React from "react";
const GuideyLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Logo"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 1786.1 566.7",
    }}
    viewBox="0 0 1786.1 566.7"
    width={props.width}
    height={props.height}
    {...props}
  >
    <style>{".st0{fill:#1ec1a1}"}</style>
    <g id="TM">
      <path
        id="M"
        d="M381.6-188.9H375l-11.3 18.7-11.3-18.7h-6.6v37.3h6.1v-26.7l11.4 18.8h.7l11.4-18.8v26.6h6.1v-37.2z"
        className="st0"
        transform="translate(1404.496 269.998)"
      />
      <path
        id="T"
        d="M362.9-188.9h-27.2v5.9h10.6v31.5h6.1V-183h10.5v-5.9z"
        className="st0"
        transform="translate(1382.895 269.998)"
      />
    </g>
    <g id="Guidey">
      <path
        id="y"
        d="M572.6-384.1h-83.2l-62.4 183-62.4-183h-83.8L386.6-94C364-35.9 340.7-37.7 315-42l-12.8 75.3C402.5 47.4 434.9-12 467.4-95.2c18.3-45.9 105.2-288.9 105.2-288.9z"
        className="st0"
        transform="translate(1145.221 517.486)"
      />
      <path
        id="e"
        d="M490.3-207.2c1.8-9.2 2.4-19 2.4-29.4 0-85.1-52-151.8-136.5-151.8-85.1 0-146.3 66.7-146.3 151.8S274.3-84.2 359.3-84.2c53.9 0 97.3-26.9 121.8-67.9l-67.3-33c-11.6 16.5-30.6 26.3-54.5 26.3-33.7 0-56.3-19.6-65.5-48.3h196.5zm-134-107.1c34.3 0 52.6 20.8 59.4 50.2H293.2c7.4-29.4 28.8-50.2 63.1-50.2z"
        className="st0"
        transform="translate(914.81 517.486)"
      />
      <path
        id="d"
        d="M344.8-517.5v159.1c-20.8-18.4-47.1-30-78.9-30-85.1 0-140.1 66.7-140.1 151.8s55 152.4 140.1 152.4c31.8 0 58.1-11.6 78.9-30v25.1h81.4v-428.4h-81.4zM275-158.9c-44.1 0-69.8-33.7-69.8-77.7s25.7-77.7 69.8-77.7c44.1 0 69.8 33.7 69.8 77.7s-25.7 77.7-69.8 77.7z"
        className="st0"
        transform="translate(661.908 517.486)"
      />
      <path
        id="i"
        d="M148.8-425.1c25.1 0 45.3-20.2 45.3-45.3 0-25.7-20.2-45.9-45.3-45.9-25.7 0-45.9 20.2-45.9 45.9 0 25.1 20.2 45.3 45.9 45.3zm-41 336h81.4v-295h-81.4v295z"
        className="st0"
        transform="translate(556.8 517.486)"
      />
      <path
        id="u"
        d="M203.9-384.1v165.2c0 36.7-18.4 58.1-47.1 58.1-29.4 0-49-17.1-49-60v-163.4h-82v160.3C25.8-133.3 67.4-85 135.3-85c27.5 0 50.8-11.6 68.5-30v25.7h81.4v-295h-81.3z"
        className="st0"
        transform="translate(325.471 517.486)"
      />
      <path
        id="g"
        d="M149.7-384.1v25.7c-20.2-18.4-47.1-30-78.3-30-85.1 0-142.6 66.7-142.6 151.8S-13.7-84.2 71.4-84.2c31.2 0 57.5-11.6 78.3-30-.6 52-28.2 85.1-75.9 85.1-34.8 0-57.5-14.7-72.2-32.5l-61.2 42.8c22 31.8 73.4 67.9 135.2 67.9C183.9 49.1 231-29.8 231-132v-252.1h-81.3zM80-158.9c-44.1 0-69.8-33.7-69.8-77.7s25.7-77.7 69.8-77.7 69.8 33.7 69.8 77.7S124-158.9 80-158.9z"
        className="st0"
        transform="translate(71.192 517.486)"
      />
    </g>
  </svg>
);
export default GuideyLogo;
