import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import Breadcrumb from "./Breadcrumb";
import PreviewButton from "./PreviewButton";
import PropertyDetailsSection from "./PropertyDetailsSection";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

//ReactGA.initialize(googleAnalyticsId);

export default class PropertyDetailsCategoryAuto extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  checkIfChecked = (label, propertyId) => {
    const {
      isComponentShared,
      SharedPropertyContentProfileId,
      customContent,
      sharedContent,
    } = this.props;
    const selectedContent = isComponentShared ? sharedContent : customContent;

    var filter = false;
    if (!isComponentShared) {
      filter = selectedContent.filter((Item) => {
        return (
          Item.LinkedProperty.includes(propertyId) &&
          Item.ContentType === "details" &&
          Item.CategoryLabel === label &&
          (Item.DetailText ||
            Item.DetailTextDescribe ||
            Item.DetailTextLocate ||
            Item.DetailTextOperate ||
            Item.WebLink ||
            Item.DetailVideo ||
            Item.DetailImage ||
            Item.DetailDocument)
        );
      });
    }
    if (isComponentShared) {
      filter = selectedContent.filter((Item) => {
        return (
          Item.LinkedSharedContentProfile === SharedPropertyContentProfileId &&
          Item.ContentType === "details" &&
          Item.CategoryLabel === label &&
          (Item.DetailText ||
            Item.DetailTextDescribe ||
            Item.DetailTextLocate ||
            Item.DetailTextOperate ||
            Item.WebLink ||
            Item.DetailVideo ||
            Item.DetailImage ||
            Item.DetailDocument)
        );
      });
    }
    if (filter[0]) return true;
    if (!filter[0]) return false;
  };

  render() {
    const { SaveStatus, propertyData, systemCategories, isComponentShared } =
      this.props;

    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];
    const previousPagePathRoot = window.location.pathname.split("/")[1];
    const ParentCategoryLabel = window.location.pathname.split("/")[3];
    //console.log(ParentCategoryLabel)
    // Use Detail label to lookup custom content
    const propertyDataFiltered = propertyData.filter((Item) => {
      return Item.PropertyID === vanityPropertyId;
    });
    const propertyDetails = propertyDataFiltered[0];

    const PreviousPageTitle = isComponentShared
      ? "Shared Property Content"
      : propertyDetails && propertyDetails.PropertyTitle
      ? propertyDetails.PropertyTitle
      : "Unamed Place";

    const systemCategoriesFiltered = systemCategories.filter((item) => {
      return (
        item.ParentCategoryLabel === ParentCategoryLabel &&
        item.ThemeLabel === "default"
      );
    });

    const systemCategoriesFilteredAgain = systemCategories.filter((item) => {
      return item.CategoryLabel === ParentCategoryLabel;
    });

    const SectionTitle = systemCategoriesFilteredAgain[0].CategoryTitle;
    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="breadcrumb-header-and-preview-button-container">
              <div className="breadcrumb-and-header-container">
                <Breadcrumb
                  {...this.props}
                  PreviousPageTitle={PreviousPageTitle}
                  PreviousPagePath={`/${previousPagePathRoot}/${vanityPropertyId}`}
                  propertyId={propertyDetails ? propertyDetails._id : null}
                  CurrentSectionTitle={SectionTitle}
                />
                <h1>{SectionTitle}</h1>
              </div>

              {!isComponentShared && (
                <PreviewButton
                  Style={"solid"}
                  Heading={"Preview guide"}
                  PropertyVanityId={propertyDetails.PropertyID}
                />
              )}
            </div>

            {systemCategoriesFiltered.map((item) => (
              <div key={item._id}>
                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    item.CategoryLabel,
                    propertyDetails ? propertyDetails._id : null
                  )}
                  IsRequired={item.IsRequired}
                  ShowActionButton={false}
                  SectionTitle={item.CategoryTitle}
                  SectionText={item.CategoryShortDescription}
                  SaveStatus={SaveStatus}
                  vanityPropertyId={
                    propertyDetails ? propertyDetails.PropertyID : null
                  }
                  CategoryLabel={item.CategoryLabel}
                  Target={`/${previousPagePathRoot}/${vanityPropertyId}/${ParentCategoryLabel}/${item.CategoryLabel}`}
                  propertyId={propertyDetails ? propertyDetails._id : null}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}
