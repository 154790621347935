import React from "react";
import Breadcrumb from "./Breadcrumb";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/InfoItem.css";

export default class ItemDeleted extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    const { TypeTitle, PreviousPageTitle, PreviousPagePath } = this.props;
    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <Breadcrumb
              {...this.props}
              PreviousPageTitle={PreviousPageTitle}
              PreviousPagePath={PreviousPagePath}
              CurrentSectionTitle={`${TypeTitle} has been deleted`}
            />
            <h1>{`${TypeTitle} has been deleted`}</h1>
          </div>
        </div>
      </>
    );
  }
}
