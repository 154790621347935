import React from "react";
import { connect } from "react-redux";
import { setSaveStatus } from "../redux/actions/userActions";
import { workflowApiRoot, apiSecretToken } from "../constants.js";
import "../styles/LoginForm.css";
import logo from "../images/airguide-logo-header.png";
import GuideyLogo from "../icons/GuideyLogo";
import Loading from "./Loading";

class ForgotPassword extends React.Component {
  state = {
    email: "",
    password: "",
    loading: false,
  };

  componentDidMount = () => {
    const ItemLabel = "forgot-password";
    this.props.setSaveStatus(`${ItemLabel}-idle`);
  };

  onClickBack = () => {
    this.props.history.push("/");
  };

  handleOnChange = (e) => {
    e.persist();
    this.setState(() => ({
      [e.target.name]: e.target.value,
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const ItemLabel = "forgot-password";
    this.props.setSaveStatus(`${ItemLabel}-fetching`);
    fetch(`${workflowApiRoot}forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiSecretToken}`,
      },
      body: JSON.stringify(this.state),
    })
      .then((response) => response.json())
      .then((results) => {
        // console.log("results.response", results);
        if (!results.response.userFound) {
          this.props.setSaveStatus(`${ItemLabel}-error`);
        }
        if (results.response.userFound) {
          this.props.setSaveStatus(`${ItemLabel}-success`);
        }
      });
  };

  render() {
    const { FetchStatus, SaveStatus } = this.props;
    const ItemLabel = "forgot-password";

    if (FetchStatus === "loading-data") {
      return <Loading />;
    }

    return (
      <>
        <div className="homepage-canvas">
          <div className="submission-form-wrapper">
            <div>
              <GuideyLogo width={"180"} height={"auto"} />
            </div>

            {SaveStatus !== `${ItemLabel}-success` && (
              <div className="submission-form">
                <div className="homepage-heading">Forgot your password?</div>
                <div className="infoitem-form-label">
                  Enter your email address to request a password reset link
                </div>
                <div style={{ height: "15px" }}></div>

                <form onSubmit={this.onSubmit}>
                  <input
                    type="text"
                    name="email"
                    className="login-text-input"
                    placeholder="Email address"
                    value={this.state.email}
                    onChange={this.handleOnChange}
                  />
                  <input
                    type="submit"
                    className="login-button"
                    value={
                      SaveStatus === `${ItemLabel}-fetching`
                        ? "Submitting..."
                        : "Submit"
                    }
                  />
                </form>

                {SaveStatus === `${ItemLabel}-error` && (
                  <div className="infoitem-form-error-message">
                    Sorry, we could not find a user with this email address.
                    Please try again.
                  </div>
                )}

                <button
                  className="inline-edit-link-grey"
                  onClick={this.onClickBack}
                >
                  Back to log in
                </button>
              </div>
            )}

            {SaveStatus === `${ItemLabel}-success` && (
              <>
                <div className="homepage-heading">Success</div>
                <div className="infoitem-form-label">
                  Check your emails - we've sent you a link to reset your
                  password.
                </div>
                <button className="inline-edit-link" onClick={this.onClickBack}>
                  Back to log in
                </button>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
