import React from "react";
import { connect } from "react-redux";
import { updateUser } from "../redux/actions/userActions";
import { updateManager } from "../redux/actions/userActions";
import { updateCompany } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import InfoItemCheckMark from "./InfoItemCheckMark";
import "../styles/InfoItem.css";
// import CtaLoading from "./CtaLoading";

class InfoItemTwoBox extends React.Component {
  state = {
    isEditing: false,
    payload: {
      [this.props.PayloadA]: this.props.ValueA,
      [this.props.PayloadB]: this.props.ValueB
    }
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    var payloadB = { ...this.state.payload };
    payloadB[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA, ...payloadB }
    }));
  };

  onEdit = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-editing`);
  };

  onCancel = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-idle`);
  };

  onSubmit = (e) => {
    const { ItemLabel, ItemType, userId, companyId, managerId } = this.props;
    e.preventDefault();
    if (ItemType === "manager") {
      this.props.updateManager(
        this.state.payload,
        managerId,
        ItemLabel,
        userId
      );
    }
    if (ItemType === "company") {
      this.props.updateCompany(this.state.payload, companyId, ItemLabel);
    }
    if (ItemType === "user") {
      this.props.updateUser(this.state.payload, userId, ItemLabel);
    }
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      ItemHeading,
      ItemDescription,
      InputLabelA,
      ValueA,
      PayloadA,
      InputLabelB,
      ValueB,
      PayloadB,
      IsChecked,
      IsRequired,
      FalseText
    } = this.props;

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={IsChecked}
                IsRequired={IsRequired}
                IsCustomContent={ValueA ? true : false}
                ItemHeading={ItemHeading}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div className="infoitem-value">
            {SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching`
              ? `${ItemDescription}`
              : ValueA && !ValueB
              ? ValueA
              : ValueA && ValueB
              ? ValueA + " " + ValueB
              : FalseText}
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "show"
                : "hide"
            }
          >
            <div className="infoitem-form-container">
              <form className="infoitem-form" onSubmit={this.onSubmit}>
                <div className="infoitem-form-inner-container-left">
                  <label className="infoitem-form-label"> {InputLabelA} </label>
                  <input
                    type="text"
                    name={PayloadA}
                    className="infoitem-text-input"
                    placeholder={`Enter ${InputLabelA}`}
                    // defaultValue={ValueA}
                    value={this.state.payload[PayloadA]}
                    onChange={this.onChange}
                    required={IsRequired}
                  />
                </div>

                <div className="infoitem-form-inner-container-right">
                  <label className="infoitem-form-label"> {InputLabelB} </label>
                  <input
                    type="text"
                    name={PayloadB}
                    className="infoitem-text-input"
                    placeholder={`Enter ${InputLabelB}`}
                    // defaultValue={ValueB}
                    value={this.state.payload[PayloadB]}
                    onChange={this.onChange}
                    required={IsRequired}
                  />
                </div>

                <input
                  type="submit"
                  className="infoitem-save-button"
                  value={
                    SaveStatus === `${ItemLabel}-fetching`
                      ? "Saving..."
                      : "Save"
                  }
                />
              </form>
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userInfo, userId, ItemLabel) =>
    dispatch(updateUser(userInfo, userId, ItemLabel)),
  updateManager: (managerInfo, managerId, ItemLabel, userId) =>
    dispatch(updateManager(managerInfo, managerId, ItemLabel, userId)),
  updateCompany: (companyInfo, managerId, ItemLabel) =>
    dispatch(updateCompany(companyInfo, managerId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemTwoBox);
