import React from "react";

function LocationIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={792}
      height={792}
      viewBox="0 0 792 792"
      {...props}
    >
      <path d="M396 173.25c-68.335 0-123.75 55.415-123.75 123.75S327.665 420.75 396 420.75 519.75 365.335 519.75 297 464.335 173.25 396 173.25zm0 198c-41.011 0-74.25-33.239-74.25-74.25s33.239-74.25 74.25-74.25 74.25 33.239 74.25 74.25-33.239 74.25-74.25 74.25zM396 0C231.982 0 99 132.982 99 297c0 124.171 247.624 495.248 297 495 48.609.247 297-372.487 297-495C693 132.982 560.019 0 396 0zm0 717.75c-41.159.223-247.5-317.271-247.5-420.75 0-136.694 110.806-247.5 247.5-247.5S643.5 160.306 643.5 297c0 102.094-206.984 420.973-247.5 420.75z" />
    </svg>
  );
}

export default LocationIcon;
