import React from "react";
import Breadcrumb from "./Breadcrumb";
import CreateLocalProfileModule from "./CreateLocalProfileModule";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class CreateLocalAreaProfile extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { SaveStatus, userData, AttachProfilesLoaded } = this.props;
    const SectionTitle = "Create a New Local Area Profile";

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <Breadcrumb
              {...this.props}
              PreviousPageTitle={"Local Area Profiles"}
              PreviousPagePath={"/local-area-profiles"}
              CurrentSectionTitle={SectionTitle}
            />
            <h1>{SectionTitle}</h1>
            <div className="infopage-subheading">
              Local area profiles can be shared across multiple properties
            </div>

            <CreateLocalProfileModule
              {...this.props}
              //  propertyId={propertyId}
              UserId={userData._id}
              ItemLabel={"create-local-profile"}
              SaveStatus={SaveStatus}
              //      propertyDetails={propertyDetails}
              InputLabelA={"Property address"}
              AttachProfilesLoaded={AttachProfilesLoaded}
            />
          </div>
        </div>
      </>
    );
  }
}
