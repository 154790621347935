import "../styles/ConversationMessage.css";
import React, { useLayoutEffect, memo } from "react";
import { markMessageRead } from "../data/markMessageRead";
import { useDispatch } from "react-redux";
import ConversationMessageFile from "./ConversationMessageFile";
import formatDatLabel from "../functions/formatDatLabel";
//import { scrollIntoView } from "seamless-scroll-polyfill";
//import smoothscroll from "smoothscroll-polyfill";

export default memo(function ConversationMessageTwo(props) {
  const { messageItemData, itemIsLatest } = props;
  const conversationMessageId = messageItemData._id;
  const inboundMessage = messageItemData.InboundMessage;
  const messageClassname = inboundMessage
    ? "conversation-message-inbound"
    : "conversation-message-outbound";
  const outerClassname = inboundMessage
    ? "conversation-message-outer-inbound"
    : "conversation-message-outer-outbound";
  const dateClassname = inboundMessage
    ? "conversation-message-date-inbound"
    : "conversation-message-date-outbound";

  const readByRecipient = messageItemData.ReadByRecipient;
  const userId = messageItemData.LinkedUser;
  const formattedDate = formatDatLabel(messageItemData["Created Date"]);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (conversationMessageId) {
      if (inboundMessage && !readByRecipient) {
        markMessageRead(dispatch, conversationMessageId, userId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (messageItemData)
    return (
      <>
        <div className={outerClassname} id={`${conversationMessageId}`}>
          <div className={dateClassname}>{formattedDate}</div>
          <div className={messageClassname}>
            <div className="conversation-list-item-left-container">
              <div className="conversation-message-inner">
                <div className="conversation-message-header">
                  <div className="conversation-list-item-heading">
                    {`${messageItemData.ContactFirstname} ${messageItemData.ContactLastname}`}
                  </div>
                </div>

                <div className="conversation-message-body">
                  {messageItemData.DetailText}
                </div>

                {messageItemData.DetailFiles &&
                  messageItemData.DetailFiles.length > 0 && (
                    <>
                      <div className="conversation-message-file-list-container">
                        {messageItemData.DetailFiles.map((item) => (
                          <ConversationMessageFile
                            key={item}
                            fileData={item}
                            itemIsLatest={itemIsLatest}
                          />
                        ))}
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  else
    return (
      <>
        {
          // <div style={{ height: "100px", color: "blue" }}> </div>
        }
      </>
    );
});
