import React from "react";

function BadgeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 612 612"
      {...props}
    >
      <path d="M306 95.743c-73.896 0-133.8 59.904-133.8 133.8s59.904 133.8 133.8 133.8 133.8-59.904 133.8-133.8-59.904-133.8-133.8-133.8zm0 229.371c-52.774 0-95.571-42.797-95.571-95.571s42.797-95.571 95.571-95.571 95.571 42.797 95.571 95.571-42.797 95.571-95.571 95.571zm210.658 8.315c15.349-30.774 24.218-65.256 24.218-101.859C540.876 103.771 435.709.172 306 .172c-129.729 0-234.876 103.6-234.876 231.397 0 36.604 8.869 71.085 24.218 101.859L0 496.11s60.458 12.118 121.796 24.753c40.905 45.53 81.618 90.965 81.618 90.965l87.658-149.588c4.97.306 9.882.745 14.928.745 5.046 0 9.978-.439 14.928-.745l87.658 149.588s40.714-45.416 81.599-90.945C551.522 508.248 612 496.13 612 496.13l-95.342-162.701zM198.865 542.443s-29.359-27.83-56.96-54.667c-39.146-11.144-78.655-22.479-78.655-22.479l55.412-94.558c31.959 41.688 77.814 72.29 130.875 85.211l-50.672 86.493zM306 420.838c-105.473 0-190.951-85.67-190.951-191.352 0-105.683 85.498-191.334 190.951-191.334s190.951 85.67 190.951 191.334c0 105.682-85.498 191.352-190.951 191.352zm164.076 66.938c-27.601 26.817-56.96 54.667-56.96 54.667l-50.672-86.473c53.062-12.922 98.916-43.523 130.875-85.212l55.412 94.559c0-.019-39.508 11.316-78.655 22.459z" />
    </svg>
  );
}

export default BadgeIcon;
