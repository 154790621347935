import React from "react";
import { connect } from "react-redux";
import { updateProperty } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/InfoItem.css";
import { googleMapsApiKey, whatThreeWordsApiKey } from "../constants.js";
import Script from "react-load-script";
import MapPins from "../pins/MapPins.js";

class InfoItemMapManual extends React.Component {
  constructor(props) {
    super(props);
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
  }

  state = {
    GeoLocationAddressLineOne: "",
    GeoLocationAddressLineTwo: "",
    GeoLocationTownCity: "",
    GeoLocationCounty: "",
    GeoLocationPostcode: "",
    GeoLocationCountry: "",
    GeoLocationFormattedAddress: "",
    lat: this.props.propertyDetails.GeoLocationLat
      ? this.props.propertyDetails.GeoLocationLat
      : 52.689182,
    lng: this.props.propertyDetails.GeoLocationLng
      ? this.props.propertyDetails.GeoLocationLng
      : -1.640071,
    dragMessage: "Drag marker to select exact location",
    showDragMessage: false,
    whatThreeWords: this.props.propertyDetails.WhatThreeWordsLocation
      ? this.props.propertyDetails.WhatThreeWordsLocation
      : "",
    whatThreeWordsSearch: false,
    nearestPlace: ""
  };

  handleScriptLoad = () => {
    this.createMap();
  };

  switchChildComponent = () => {
    this.setState({
      whatThreeWordsSearch: !this.state.whatThreeWordsSearch
    });
  };

  createMap = () => {
    // const { propertyDetails } = this.props;
    this.googlemap = new window.google.maps.Map(
      document.getElementById("googlemap-manual"),
      {
        zoom: 5,
        center: {
          lat: this.state.lat,
          lng: this.state.lng
        },
        disableDefaultUI: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: true,
        gestureHandling: "greedy",
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "on"
              }
            ]
          }
        ]
      }
    );

    this.marker = new window.google.maps.Marker({
      position: this.googlemap.getCenter(),
      map: this.googlemap,
      icon: {
        url: MapPins.home,
        scaledSize: new window.google.maps.Size(40, 54.5)
      },
      optimized: false,
      clickable: false,
      draggable: true
    });

    this.marker.addListener("dragstart", () => {
      this.setState({
        dragMessage: "Dragging...",
        showDragMessage: true
      });
    });

    this.marker.addListener("dragend", (evt) => {
      const latitude = evt.latLng.lat();
      const longitude = evt.latLng.lng();

      this.setState({
        lat: evt.latLng.lat(),
        lng: evt.latLng.lng(),
        dragMessage: "Drag marker to select exact location",
        showDragMessage: false
      });

      fetch(
        `https://api.what3words.com/v3/convert-to-3wa?coordinates=${latitude}%2C${longitude}&key=${whatThreeWordsApiKey}`
      )
        .then((response) => response.json())
        .then((data) => {
          //  console.log("w3w response data", data);
          if (data) {
            this.setState({
              whatThreeWords: data.words,
              nearestPlace: data.nearestPlace,
              GeoLocationCountry: data.country
            });
          }
          if (!data) {
            this.setState({
              whatThreeWords: "",
              nearestPlace: "",
              GeoLocationCountry: ""
            });
          }
        });
    });
  };

  onChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { ItemLabel, propertyId } = this.props;

    const Payload = {
      GeoLocationAddressLineOne: this.state.nearestPlace,
      GeoLocationCountry: this.state.GeoLocationCountry,
      GeoLocationFormattedAddress: this.state.nearestPlace,
      PropertyDisplayAddress: this.state.nearestPlace,
      GeoLocationLat: this.state.lat,
      GeoLocationLng: this.state.lng,
      WhatThreeWordsLocation: this.state.whatThreeWords,
      GeoLocationAddressLineTwo: "",
      GeoLocationTownCity: "",
      GeoLocationCounty: "",
      GeoLocationState: "",
      GeoLocationPostcode: ""
    };
    this.props.updateProperty(Payload, propertyId, ItemLabel);
    //    console.log(Payload);
  };

  onSubmitWhatThreeWords = (e) => {
    e.preventDefault();
    const { ItemLabel, propertyId } = this.props;

    fetch(
      `https://api.what3words.com/v3/convert-to-coordinates?words=${this.state.whatThreeWords}&key=${whatThreeWordsApiKey}`
    )
      .then((response) => response.json())
      .then((data) => {
        //  console.log("w3w to coordinates response data", data);
        if (!data.error) {
          this.setState({
            whatThreeWords: data.words,
            nearestPlace: data.nearestPlace,
            GeoLocationCountry: data.country,
            lat: data.coordinates.lat,
            lng: data.coordinates.lng,
            whatThreeWordsError: false
          });

          const Payload = {
            GeoLocationAddressLineOne: data.nearestPlace,
            GeoLocationCountry: data.country,
            GeoLocationFormattedAddress: data.nearestPlace,
            PropertyDisplayAddress: data.nearestPlace,
            GeoLocationLat: data.coordinates.lat,
            GeoLocationLng: data.coordinates.lng,
            WhatThreeWordsLocation: data.words,
            ShowWhatThreeWords: true,
            GeoLocationAddressLineTwo: "",
            GeoLocationTownCity: "",
            GeoLocationCounty: "",
            GeoLocationState: "",
            GeoLocationPostcode: ""
          };
          this.props.updateProperty(Payload, propertyId, ItemLabel);
        }
        if (data.error) {
          this.setState({
            whatThreeWords: "",
            nearestPlace: "",
            GeoLocationCountry: "",
            whatThreeWordsError: true,
            whatThreeWordsErrorMessage: data.error.message
          });
        }
      });
  };

  render() {
    const { SaveStatus, ItemLabel } = this.props;

    return (
      <>
        <div className="infoitem-form-inner-container">
          <div className="infoitem-value-list">
            {this.state.showDragMessage && (
              <label className="infoitem-form-label-map">
                Drop marker to select property location...
              </label>
            )}

            {!this.state.showDragMessage && !this.state.whatThreeWordsSearch && (
              <label className="infoitem-form-label-map">
                Drag marker to exact location or
                <span
                  className="infoitem-span-edit-button"
                  onClick={this.switchChildComponent}
                >
                  click here
                </span>
                to enter what3words
              </label>
            )}

            {!this.state.showDragMessage && this.state.whatThreeWordsSearch && (
              <label className="infoitem-form-label-map">
                Enter what3words below or
                <span
                  className="infoitem-span-edit-button"
                  onClick={this.switchChildComponent}
                >
                  click here
                </span>
                to drag marker to exact location
              </label>
            )}
          </div>

          {!this.state.whatThreeWordsSearch && (
            <>
              <div className="infoitem-form-inner-container-map">
                <Script
                  url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
                  onLoad={this.handleScriptLoad}
                />
                <div
                  id="googlemap-manual"
                  style={{ width: "300px", height: "300px" }}
                />
              </div>

              <div className="infoitem-form-inner-container">
                <div className="infoitem-value-list">
                  {this.state.nearestPlace && (
                    <>
                      <div className="infoitem-value-list-heading">
                        Location selected
                      </div>
                      <div className="infoitem-value-list-item">
                        {`Nearest place: ${this.state.nearestPlace}`}
                      </div>
                    </>
                  )}
                  {this.state.nearestPlace && (
                    <div className="infoitem-value-list-item">
                      {`what3words Location: ///${this.state.whatThreeWords}`}
                    </div>
                  )}
                </div>
              </div>

              {this.state.lat && this.state.lng && (
                <div className="infoitem-form-inner-container-left">
                  <button
                    onClick={this.onSubmit}
                    className="infoitem-save-button"
                  >
                    {SaveStatus === `${ItemLabel}-fetching`
                      ? "Saving..."
                      : "Save"}
                  </button>
                </div>
              )}

              {!this.state.lat && !this.state.lng && (
                <div className="infoitem-form-inner-container-left">
                  <button
                    disabled
                    onClick={this.onSubmit}
                    className="infoitem-save-button"
                  >
                    {SaveStatus === `${ItemLabel}-fetching`
                      ? "Saving..."
                      : "Save"}
                  </button>
                </div>
              )}
            </>
          )}

          {this.state.whatThreeWordsSearch && (
            <>
              <form
                style={{ marginTop: "0px" }}
                className="infoitem-form"
                onSubmit={this.onSubmitWhatThreeWords}
              >
                <div className="infoitem-form-inner-container-full">
                  <input
                    type="text"
                    name="whatThreeWords"
                    className="infoitem-text-input"
                    placeholder="Enter what3words here e.g. hill.frost.grow"
                    value={this.state.whatThreeWords}
                    onChange={this.onChange}
                    required
                  />
                </div>
                <div className="infoitem-form-inner-container-left">
                  <input
                    type="submit"
                    className="infoitem-save-button"
                    value={
                      SaveStatus === `${ItemLabel}-fetching`
                        ? "Saving..."
                        : "Save"
                    }
                  />
                </div>
              </form>

              {this.state.whatThreeWordsError && (
                <div className="infoitem-form-error-message-left-align">
                  Words not found. Please try again.
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateProperty: (propertyInfo, propertyId, ItemLabel) =>
    dispatch(updateProperty(propertyInfo, propertyId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemMapManual);
