import React from "react";
import Breadcrumb from "./Breadcrumb";
import PreviewButton from "./PreviewButton";
import SharedContentSelector from "./SharedContentSelector";
import InfoItemMulti from "./InfoItemMulti";
import CTAButtonIconLeft from "./CTAButtonIconLeft";
import CirclePlusIcon from "../icons/CirclePlusIcon";
import { connect } from "react-redux";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

class PropertyDetailsInfoAuto extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  onAddItem = () => {
    this.props.setSaveStatus("new-item-editing");
  };

  checkIfChecked = (propertyId, itemId, data) => {
    var filter = data.filter((item) => {
      return item._id === itemId && item.LinkedProperty.includes(propertyId);
    });
    if (filter[0]) return true;
    if (!filter[0]) return false;
  };

  render() {
    // Props from URL
    const vanityPropertyId = window.location.pathname.split("/")[2];
    // const CategoryLabel = window.location.pathname.split("/")[4];
    const PagePath = window.location.pathname.split("/")[1];

    var CategoryLabel = "";
    if (window.location.pathname.split("/").length === 5) {
      CategoryLabel = window.location.pathname.split("/")[4];
    }
    if (window.location.pathname.split("/").length === 4) {
      CategoryLabel = window.location.pathname.split("/")[3];
    }
  
    var sectionLabel = "";
    if (window.location.pathname.split("/").length === 5) {
    sectionLabel = window.location.pathname.split("/")[3];
    }
    if (window.location.pathname.split("/").length === 4) {
    sectionLabel = window.location.pathname.split("/")[2];
    }
    
    // Props from parent
    const {
      SaveStatus,
      userData,
      propertyData,
      PropertyId,
      customContent,
      systemContent,
      systemCategories,
      sharedContent,
      pathRoot,
      SharedContentProfileId,
      AttachContentLoaded,
      isComponentShared
    } = this.props;
    //  console.log("customContent", customContent);
    //  console.log("sharedContent", sharedContent);
    const propertyDataFiltered = propertyData.filter((Item) => {
      return Item.PropertyID === vanityPropertyId;
    });

    const propertyDetails = propertyDataFiltered[0];

    const propertyId = PropertyId ? PropertyId : null;

    // Variables from Props
    const systemContentFiltered = systemContent.filter((Item) => {
      return Item.CategoryLabel === CategoryLabel;
    });

    const systemCategoriesFiltered = systemCategories.filter((Item) => {
      return Item.CategoryLabel === CategoryLabel;
    });

    const CurrentPageTitle = systemCategoriesFiltered[0].CategoryTitle;

    // const ParentPageLabel = systemCategoriesFiltered[0].ParentCategoryLabel;

    var ParentPageLabel = "";
    if (window.location.pathname.split("/").length === 5) {
      ParentPageLabel = systemCategoriesFiltered[0].ParentCategoryLabel;
    }
    if (window.location.pathname.split("/").length === 4) {
      ParentPageLabel = systemCategoriesFiltered[0].CategoryLabel;
    }

    const systemCategoriesFilteredParent = systemCategories.filter((Item) => {
      return Item.CategoryLabel === ParentPageLabel;
    });
    const ParentPageTitle = systemCategoriesFilteredParent[0].CategoryTitle;

    // Filter custom content by vanity property id
    const customContentFilterOne = isComponentShared
      ? null
      : customContent.map((element) => {
          return {
            ...element,
            LinkedProperty: element.LinkedProperty.filter(
              (subElement) => subElement === propertyId
            )
          };
        });
    //  console.log(customContentFilterOne);
    const customContentFilterTwo = isComponentShared
      ? sharedContent
      : customContentFilterOne.filter((Item) => {
          return (
            Item.LinkedProperty[0] === propertyId &&
            !Item.LinkedSharedContentProfile
          );
        });

    const customContentFiltered = customContentFilterTwo.filter((Item) => {
      return Item.CategoryLabel === CategoryLabel;
    });

    //   console.log("systemContentFiltered", systemContentFiltered);
    //   console.log("customContentFiltered", customContentFiltered);

    var uniqueCustomContent = null;
    if (customContentFiltered !== null) {
      uniqueCustomContent = customContentFiltered.filter((item) => {
        return item.IsCustomLabel === true;
      });
    }

    //  console.log("uniqueCustomContent", uniqueCustomContent);

    var PreviousPageTitle = "";
    if (window.location.pathname.split("/").length === 5 && ParentPageTitle) {
      PreviousPageTitle = ParentPageTitle;
    }

    var PreviousPagePath = "";
    if (window.location.pathname.split("/").length === 5 && ParentPageTitle) {
      PreviousPagePath =
        "/" + PagePath + "/" + vanityPropertyId + "/" + ParentPageLabel;
    }
    if (window.location.pathname.split("/").length === 4) {
      PreviousPagePath = "/" + PagePath + "/" + vanityPropertyId;
    }
    //  console.log(ParentPageTitle);

    const sharedContentFiltered =
      sharedContent && sharedContent[0]
        ? sharedContent.filter((item) => {
            return item.CategoryLabel === CategoryLabel;
          })
        : null;
    
    const sharedContentSubContent =
      sharedContent && sharedContent[0]
        ? sharedContent.filter((item) => {
          return item.ContentType === "sub-details";
        })
        : null;

      // console.log("sharedContentSubContent", sharedContentSubContent)

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="breadcrumb-header-and-preview-button-container">
              <div className="breadcrumb-and-header-container">
                <Breadcrumb
                  {...this.props}
                  PreviousPageTitle={PreviousPageTitle}
                  PreviousPagePath={PreviousPagePath}
                  CurrentSectionTitle={CurrentPageTitle}
                  propertyId={propertyId}
                />
                <h1>{CurrentPageTitle}</h1>
              </div>
              {!isComponentShared && (
                <PreviewButton
                  Style={"solid"}
                  Heading={"Preview guide"}
                  PropertyVanityId={propertyDetails.PropertyID}
                />
              )}
            </div>

            {pathRoot !== "shared-content" &&
              sharedContentFiltered &&
              sharedContentFiltered[0] && (
                <>
                  <div className="infoitem-container-box">
                    <div className="infoitem-container-box-heading">
                      Shared Content
                    </div>
                    {sharedContentFiltered.map((item) => (
                      <SharedContentSelector
                        key={item._id}
                        ItemUniqueId={`shared-content-${item._id}`}
                        ContentId={item._id}
                        PropertyId={propertyDetails._id}
                        SharedContentProfileId={SharedContentProfileId}
                        SaveStatus={SaveStatus}
                        IsChecked={this.checkIfChecked(
                          propertyDetails._id,
                          item._id,
                          sharedContentFiltered
                        )}
                        ItemHeading={item.DetailTitle}
                        AttachContentLoaded={AttachContentLoaded}
                        ItemData={item}
                        subContent={sharedContentSubContent ? sharedContentSubContent.filter((subItem) => {
                          return subItem.ParentContent === item._id;
                          }) : null}
                      />
                    ))}
                  </div>
                </>
              )}

            {systemContentFiltered.map((item) => (
              <InfoItemMulti
                key={item._id}
                propertyData={propertyData}
                PropertyId={PropertyId}
                systemContent={systemContent}
                customContent={customContent}
                sharedContent={sharedContent}
                SaveStatus={SaveStatus}
                userId={userData._id}
                DetailLabel={item.DetailLabel}
                IsCustomLabel={item.IsCustomLabel}
                ItemUniqueId={item._id}
                InfoType={"system"}
                isComponentShared={isComponentShared}
                SharedContentProfileId={SharedContentProfileId}
              />
            ))}

            {uniqueCustomContent !== null &&
              uniqueCustomContent.map((item) => (
                <InfoItemMulti
                  key={item._id}
                  propertyData={propertyData}
                  PropertyId={PropertyId}
                  systemContent={systemContent}
                  customContent={customContent}
                  sharedContent={sharedContent}
                  SaveStatus={SaveStatus}
                  userId={userData._id}
                  DetailLabel={item.DetailLabel}
                  IsCustomLabel={item.IsCustomLabel}
                  ItemUniqueId={item._id}
                  InfoType={"custom"}
                  isComponentShared={isComponentShared}
                  SharedContentProfileId={SharedContentProfileId}
                />
              ))}

            {SaveStatus !== "new-item-editing" &&
              SaveStatus !== "new-item-fetching" && 
              sectionLabel !== "arrival" && (
                <>
                  <CTAButtonIconLeft
                    onClick={this.onAddItem}
                    Icon={CirclePlusIcon}
                    Heading={"Add custom item"}
                    Style={"solid"} // "solid" or "inverted"
                  />
                </>
              )}

            {(SaveStatus === "new-item-editing" ||
              SaveStatus === "new-item-fetching") && (
              <InfoItemMulti
                propertyData={propertyData}
                PropertyId={PropertyId}
                systemContent={systemContent}
                customContent={customContent}
                sharedContent={sharedContent}
                SaveStatus={SaveStatus}
                userId={userData._id}
                DetailLabel={"custom"}
                PassedCategoryLabel={CategoryLabel}
                IsCustomLabel={true}
                ItemUniqueId={"new-item"}
                isComponentShared={isComponentShared}
                SharedContentProfileId={SharedContentProfileId}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(PropertyDetailsInfoAuto);
