import React from "react";

function GraphIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 753.23 753.23"
      {...props}
    >
      <path d="M635.538 376.615h-47.077c-26.01 0-47.076 21.067-47.076 47.077v282.461c0 26.011 21.066 47.077 47.076 47.077h47.077c26.01 0 47.077-21.066 47.077-47.077V423.692c0-26.009-21.067-47.077-47.077-47.077zm0 306c0 12.993-10.545 23.538-23.538 23.538s-23.539-10.545-23.539-23.538V447.23c0-12.993 10.546-23.538 23.539-23.538s23.538 10.545 23.538 23.538v235.385zM400.153 0h-47.077C327.067 0 306 21.067 306 47.077v659.077c0 26.011 21.067 47.077 47.077 47.077h47.077c26.011 0 47.077-21.066 47.077-47.077V47.077C447.23 21.067 426.164 0 400.153 0zm0 682.615c0 12.993-10.545 23.538-23.538 23.538s-23.539-10.545-23.539-23.538v-612c0-12.993 10.545-23.539 23.539-23.539s23.538 10.545 23.538 23.539v612zM164.769 211.846h-47.077c-26.01 0-47.077 21.09-47.077 47.077v447.23c0 26.011 21.067 47.077 47.077 47.077h47.077c26.01 0 47.077-21.066 47.077-47.077v-447.23c0-26.01-21.067-47.077-47.077-47.077zm0 470.769c0 12.993-10.545 23.538-23.539 23.538s-23.538-10.545-23.538-23.538V282.461c0-12.993 10.545-23.539 23.538-23.539s23.539 10.545 23.539 23.539v400.154z" />
    </svg>
  );
}

export default GraphIcon;
