import React from "react";
import { connect } from "react-redux";
import PaperClipIcon from "../icons/PaperClipIcon";
//import PageLoading from "../../components/PageLoading";
//import SetupCheckMark from "./SetupCheckMark";
import { conversationFileUploads } from "../redux/actions/userActions";
import { conversationFileUploadsLoading } from "../redux/actions/userActions";
import "../styles/ConversationResponseFileUploader.css";
//conversationFileUploads

class ConversationResponseFileUploader extends React.Component {
  // shouldComponentUpdate = (nextProps, nextState) => {
  //  console.log(nextProps, nextState);
  //  console.log(this.props, this.state);
  // if ((nextProps.uploadingState !== this.props.uploadingState) && (this.props.uploadingState === false)) {
  //   return true;
  // } else return false;
  //};

  state = {
    // selectedFile: this.props.selectedPropertyCoverImage,
    payload: [],
    inputKey: null
    //  formState: this.props.existingFormState[this.props.dataName]
  };

  onChange = (e) => {
    //   e.persist();
    // const { liftUploadingState } = this.props;
    // this.props.liftUploadingState(true);
    this.props.conversationFileUploadsLoading(true);
    //   var payloadA = { ...this.state.payload };
    //  payloadA[e.target.name] = e.target.files[0];

    let imageFile = e.target.files[0];
    const reader = new FileReader();

    // reader.readAsBinaryString(file); Bubble API
    reader.readAsArrayBuffer(imageFile);
    this.setState({
      fileName: imageFile.name
    });
    //  console.log("imageFile", imageFile);
    this.onClick();
    reader.onload = this.handleReaderLoaded.bind(this);
  };

  handleReaderLoaded = (readerEvt) => {
    //  const { PayloadA } = this.props;
    // let binaryString = readerEvt.target.result;
    let imageReaderFile = readerEvt.target.result;
    //  console.log("imageReaderFile", imageReaderFile);
    var imageBlob = new Blob([imageReaderFile], { type: "image/jpeg" });
    var imageUrlCreator = window.URL || window.webkitURL;
    var imageUrl = imageUrlCreator.createObjectURL(imageBlob);

    this.setState({
      selectedFile: imageReaderFile,
      payload: {
        fileName: this.state.fileName,
        fileData: imageReaderFile
      },
      imageFilePreview: imageUrl
      //  }
    });
    //   console.log(this.state);
    const payload = [
      {
        fileName: this.state.fileName,
        fileData: imageReaderFile
      }
    ];
    this.props.conversationFileUploads(payload);
    //console.log("running", payload)
    this.setState({});
  };
  onClick = () => {
    let randomString = Math.random().toString(36);
    this.setState({
      selectedFile: null,
      inputKey: randomString
    });
  };

  render() {
    const {
      ItemLabel,
      dataName,
      SelectedTheme,
      //   containerClassName,
      //  dataLabel,
      uploadingState
    } = this.props;
    // console.log("FormFileUploader is rerendering");
    //  console.log("formState", this.state.formState);
    return (
      <>
        <div className={"conversation-response-file-icon-container"}>
          <input
            type="file"
            key={this.state.inputKey || ""} // Required to clear file input value
            id={`file-upload-image-${ItemLabel}`}
            name={dataName}
            className="conversation-response-imageuploader-image-input"
            value={this.state.payload.name}
            onChange={this.onChange}
            required
          />

          <label
            htmlFor={`file-upload-image-${ItemLabel}`}
            className={
              SelectedTheme === "custom"
                ? "conversation-response-file-upload-selected"
                : "conversation-response-file-upload"
            }
          >
            {SelectedTheme === "custom" && (
              <img
                className="conversation-response-imageuploader-image"
                src={
                  //this.props.selectedPropertyCoverImage
                  //   ? //this.props.selectedPropertyCoverImage
                  this.state.imageFilePreview
                  //   : this.state.imageFilePreview
                  // `data:image/jpeg;base64,${this.props.selectedPropertyCoverImage.contents}`
                  // : `data:image/jpeg;base64,${this.state.selectedFile}`
                }
                alt={ItemLabel}
              />
            )}

            <div className="conversion-response-file-icon-container">
              {!uploadingState && (
                <PaperClipIcon className="conversation-response-imageuploader-custom-file-upload-icon" />
              )}
              {uploadingState && "..."}
            </div>
          </label>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  conversationFileUploads: (payload) =>
    dispatch(conversationFileUploads(payload)),
  conversationFileUploadsLoading: (payload) =>
    dispatch(conversationFileUploadsLoading(payload))
});

export default connect(
  null,
  mapDispatchToProps
)(ConversationResponseFileUploader);
