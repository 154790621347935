import React from "react";
import ReactGA from "react-ga4";
import "../styles/DisplayCanvas.css";
import "../styles/DashboardShared.css";
import "../styles/ButtonBanners.css";
import NotificationBanner from "./NotificationBanner";
import DashboardProfile from "./DashboardProfile";
import DashboardAccount from "./DashboardAccount";
import DashboardProperties from "./DashboardProperties";
import DashboardMarketing from "./DashboardMarketing";
import Countdown from "react-countdown";

export default class DashboardHome extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { userEmailId } = this.props;
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: `${userEmailId} - Dashboard Home`,
    });
  };

  onSetupSubscriptionClick = () => {
    this.props.history.push("/subscription");
  };

  render() {
    const { userData, propertyData, systemContent, SharedContentProfileId } =
      this.props;

    // var date = new Date(); // Now
    //  date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
    // console.log(date);

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (!completed) {
        return (
          <>
            <span>
              Your free trial ends in{" "}
              <strong>
                {days} days {hours} hours {minutes} minutes, and {seconds}{" "}
                seconds{" "}
              </strong>
            </span>
          </>
        );
      }
      if (completed) {
        return (
          <>
            <span>
              Your free trial has ended and{" "}
              <strong>your guides are now inactive.</strong> You can reactivate
              your guides instantly by setting up your subscription.
            </span>
          </>
        );
      }
    };

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <h1> Dashboard </h1>

            <h2> Welcome, {userData.UserFirstname}</h2>

            {!userData.StripeSubscriptionID && (
              <div>
                <NotificationBanner
                  Text={
                    <Countdown
                      date={userData.SubscriptionTrialEnd}
                      renderer={renderer}
                    />
                  }
                  DisplayButton={true}
                  ButtonText={"Set up subscription"}
                  Target={"/subscription"}
                  IconType={"Bell"}
                  IconColour={"#ed495a"}
                  TextColour={"#ed495a"}
                  ButtonColour={"#1ec1a1"}
                  BorderColour={"#ed495a"}
                  {...this.props}
                />
              </div>
            )}

            {userData.StripeSubscriptionID && userData.CardOnFileFailed && (
              <div>
                <NotificationBanner
                  Text={
                    "Your latest payment failed. Please add a new card now to avoid your account being cancelled."
                  }
                  DisplayButton={true}
                  ButtonText={"Add a new card"}
                  Target={"/subscription"}
                  IconType={"Bell"}
                  IconColour={"#ed495a"}
                  TextColour={"#ed495a"}
                  ButtonColour={"#1ec1a1"}
                  BorderColour={"#ed495a"}
                  {...this.props}
                />
              </div>
            )}

            {
              //userData.StripeSubscriptionID && (
              // <NotificationBanner
              //   Text={"You can now earn account credit when you refer a friend"}
              //   DisplayButton={true}
              //  ButtonText={"Learn more"}
              //  Target={"/referrals"}
              //  IconType={"Bell"}
              //  IconColour={"#1e8fc1"}
              //  TextColour={"#1e8fc1"}
              //  ButtonColour={"#1e8fc1"}
              //  BorderColour={"#1e8fc1"}
              //  {...this.props}
              // />
              // )
            }

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <DashboardProperties
              userData={userData}
              propertyData={propertyData}
              systemContent={systemContent}
              PreviousPageTitle={"Dashboard"}
              {...this.props}
            />

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <DashboardProfile
              SectionHeading={"Profiles & Shared Content"}
              MasterAccount={userData.UserMasterAccount}
              SharedContentProfileId={SharedContentProfileId}
              {...this.props}
            />

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <DashboardAccount
              SectionHeading={"Account"}
              MasterAccount={userData.UserMasterAccount}
              {...this.props}
            />

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <DashboardMarketing
              SectionHeading={"Data & Marketing"}
              {...this.props}
            />
          </div>
        </div>
      </>
    );
  }
}

//<DashboardCheckoutForm userData={userData} />
