import { dataApiRoot } from "../constants.js";

export const updateMessageStatus = (messageDataFiltered, payload) => {
  // console.log("uploadMessageFiles", linkedUserId, messageId, detailFiles);

  Promise.all(
    messageDataFiltered.map(
      (message) =>
        fetch(`${dataApiRoot}conversationmessages/${message._id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`
          },
          body: JSON.stringify({ LinkedConversationStatus: payload })
        }).then()
      //  .then(checkStatus)
      // .then(parseJSON)
      //  .catch(logError)
    )
  ).then((data) => {
    // console.log("data", data);
  });
};
