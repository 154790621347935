import { workflowApiRoot, apiSecretToken, cdnRootUrl } from "../constants.js";
import { refreshConversations } from "../data/refreshConversations";

export const addFilesToMessage = (
  linkedUserId,
  messageId,
  detailFiles,
  dispatch,
  buttonId
) => {
  const detailFileList = detailFiles.map(
    (file) =>
      `${cdnRootUrl}/user/${linkedUserId}/message-content/${messageId}/${file.fileName}`
  );
  // console.log("detailFileList in addFilesToMessage", detailFileList)
  fetch(`${workflowApiRoot}upload-message-file-list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${apiSecretToken}`
    },
    body: JSON.stringify({
      messageId: messageId,
      detailFiles: detailFileList
    })
  }).then((data) => {
    // console.log("addFilesToMessage response data", data);
    if (data.status === 200) {
      refreshConversations(dispatch, linkedUserId, buttonId);
    }
    if (data.status !== 200) {
      console.log(
        `data > addFilesToMessage > upload-message-file-list api error: ${data.status}`
      );
    }
  });
};
