import React, { useState } from "react";
import { connect } from "react-redux";
import { setSaveStatus } from "../redux/actions/userActions";
import { HexColorPicker, HexColorInput } from "react-colorful";
import LoadingButton from "./LoadingButton";
import "react-colorful/dist/index.css";
import "../styles/ColourPicker.css";
import "../styles/InfoItem.css";

function InfoItemThemeColoursChild(props) {
  const {
    SaveStatus,
    ItemLabel,
    InputLabelA,
    ValueA,
    InputLabelB,
    ValueB,
    propertyId
  } = props;

  const [colorA, setColorA] = useState(ValueA);
  const [colorB, setColorB] = useState(ValueB);
  var propertyInfo = {
    PropertyHexPrimary: colorA,
    PropertyHexSecondary: colorB,
    CompanyProfile: false
  };

  return (
    <>
      <div className="infoitem-container">
        <div className="infoitem-form-container">
          <form className="infoitem-form">
            <div className="infoitem-form-inner-container-left">
              <label className="infoitem-form-label"> {InputLabelA} </label>

              <div className="colour-picker-outer">
                <HexColorPicker color={colorA} onChange={setColorA} />

                <div className="colour-picker-input-and-hashtag-container">
                  <div> # </div>
                  <HexColorInput
                    className="colour-picker-text-input"
                    color={colorA}
                    onChange={setColorA}
                  />
                </div>
              </div>
            </div>

            <div className="infoitem-form-inner-container-right">
              <label className="infoitem-form-label"> {InputLabelB} </label>

              <div className="colour-picker-outer">
                <HexColorPicker color={colorB} onChange={setColorB} />

                <div className="colour-picker-input-and-hashtag-container">
                  <div> # </div>
                  <HexColorInput
                    className="colour-picker-text-input"
                    color={colorB}
                    onChange={setColorB}
                  />
                </div>
              </div>
            </div>

            <div className="infoitem-form-inner-container-left">
              <LoadingButton
                ItemLabel={ItemLabel}
                StaticText={"Save"}
                LoadingText={"Saving"}
                Loading={SaveStatus === `${ItemLabel}-fetching` ? true : false}
                Payload={propertyInfo}
                ItemType={"property"}
                propertyId={propertyId}
              />
            </div>
          </form>
        </div>
      </div>

      <div className="infoitem-section-break-wrapper">
        <div className="infoitem-section-break"></div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload))
});

export default connect(null, mapDispatchToProps)(InfoItemThemeColoursChild);
