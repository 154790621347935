import React from "react";

function DownloadIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={612}
      height={612}
      viewBox="0 0 612 612"
      {...props}
    >
      <path d="M403.939 314.894l-78.814 78.813V191.25c0-10.557-8.568-19.125-19.125-19.125s-19.125 8.568-19.125 19.125v202.457l-78.814-78.813c-7.478-7.479-19.584-7.479-27.043 0-7.478 7.478-7.478 19.564 0 27.042l108.19 108.19c4.59 4.59 10.863 6.005 16.812 4.973 5.929 1.052 12.221-.383 16.811-4.973l108.19-108.19c7.478-7.478 7.478-19.584 0-27.042-7.479-7.459-19.604-7.479-27.082 0zM535.5 0h-459C34.253 0 0 34.253 0 76.5v459C0 577.747 34.253 612 76.5 612h459c42.247 0 76.5-34.253 76.5-76.5v-459C612 34.253 577.747 0 535.5 0zm38.25 535.5c0 21.114-17.136 38.25-38.25 38.25h-459c-21.133 0-38.25-17.136-38.25-38.25v-459c0-21.114 17.117-38.25 38.25-38.25h459c21.133 0 38.25 17.117 38.25 38.25v459z" />
    </svg>
  );
}

export default DownloadIcon;
